
<template>
  <div class="container">
    <!-- Card Table -->
    <div class="card">
      <h5 class="card-header"> الحسابات   </h5>
        <button type="button" 
                class="btn btn-primary btn-new btn-xs" 
                data-toggle="modal" 
                data-target="#newModal" 
                style="float: left;"
                >اضافة
        </button>
        <div class="table-responsive text-nowrap">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>الاســــــــــم</th>
                <th>البريد الالكتروني</th>
                <th>الهاتف</th>
                <th>الوظيفة </th>
                <th>القسم</th>
                <th>الرقم الوظيفى</th>
                <th>الجنسيه</th>
                <th>تاريخ تعيين الموظف</th>
                <th>الاجازات المستحقه</th>
                <th>عهدة الموظف</th>
                <th>الحساب</th>
                <th>تحكم</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :key="item">
                <td> {{ index + 1  }}  </td>
                <td>
                  <router-link @click="downloadFile('id', 'type')" :to="'/process.muazaf/' + item.id">
                    {{ item.name }} 
                  </router-link>
                </td>
                <td> {{ item.email }}  </td>
                <td> {{ item.phone }}  </td>
                <td> {{ item.work }}  </td>
                <td> {{ item.category }}  </td>
                <td> {{ item.job_number }}  </td>
                <td> {{ item.nationality }}  </td>


                <td> {{ item.date_employment }}  </td>
                <td> {{ item.vacations_count }}  </td>  
                <!--<td> {{ item.remaining_vacations }}</td>-->
                <td> {{ item.employee_custody }}  </td>

                <td> 
                  <span v-for="acount in loopAcountType" :key="acount">
                    <span v-if="acount.id == item.acount_type">{{ acount.name }}</span>
                  </span>   
                </td>
                <td class="action">
                  <a class="btn-action" @click="onDelete(item.id)"> 
                    <i class="far fa-trash-alt"></i> 
                  </a>
                  <a class="btn-action"
                     data-toggle="modal" 
                     data-target="#permissionsModal" 
                     @click="getPermissions(item.id)">
                     <i class="far fa-clipboard-list-check"></i>
                  </a> 
                  <!--
                  <a class="btn-action" :href="'https://furncn.com/back-end/public/user_file/'+item.file">
                    <i class="fas fa-arrow-alt-to-bottom"></i>
                  </a>
                  -->
                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#EditModal" 
                      @click="openEditModel(item.id, item.name, item.email, item.work, item.category, item.phone, item.job_number, item.nationality, item.age, item.profession_residence, item.actual_profession, item.date_starting_work, item.direct_manager, item.work_tasks, item.employee_custody, item.password, item.acount_type, item.date_employment, item.vacations_count)"> 
                      <i class="fas fa-pencil-alt"></i> 
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
    <!-- Card Table -->



    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content" enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">

              
              <div class="row">
                  <div class="col-sm-6">
                    <label class="col-form-label" for="basic-default-name"> الاســــــــــم </label>
                    <input type="text" class="form-control" id="basic-default-name" v-model="name">
                  </div> 

                  <div class="col-sm-6">
                    <label class="col-form-label" for="basic-default-name"> البريد الالكتروني  </label>
                    <input type="email" name="email" class="form-control" id="basic-default-name" v-model="email">
                  </div>

                  <div class="col-sm-6">
                    <label class="col-form-label" for="basic-default-name"> رقم الهاتف </label>
                    <input type="number" class="form-control" id="basic-default-name" v-model="phone">
                  </div>

                  <div class="col-sm-6">
                    <label class="col-form-label" for="basic-default-name"> الوظيفة </label>
                    <input type="text" class="form-control" id="basic-default-name" v-model="work">
                  </div>

                  <div class="col-sm-6">
                    <label class="col-form-label" for="basic-default-name"> القسم </label>
                    <input type="text" class="form-control" id="basic-default-name" v-model="category">
                  </div>

                  <div class="col-sm-6">
                    <label class="col-form-label" for="basic-default-name"> الرقم الوظيفى  </label>
                    <input type="text" class="form-control" id="basic-default-name" v-model="job_number">
                  </div> 

                  <div class="col-sm-6">
                    <label class="col-form-label" for="basic-default-name">  الجنسيه   </label>
                    <input type="text" class="form-control" id="basic-default-name" v-model="nationality">
                  </div>



                  <div class="col-sm-6">
                    <label class="col-form-label" for="basic-default-name">عهدة الموظف </label>
                    <input type="text" class="form-control" id="basic-default-name" v-model="employee_custody">
                  </div>

                  <div class="col-sm-6">
                    <label class="col-form-label" for="basic-default-name">تاريخ تعيين الموظف</label>
                    <input type="date" class="form-control" id="basic-default-name" v-model="date_employment">
                  </div>

                  <div class="col-sm-6">
                    <label class="col-form-label" for="basic-default-name">الاجازات المستحقه</label>
                    <input type="number" class="form-control" id="basic-default-name" v-model="vacations_count">
                  </div>
<!--
                  <div class="col-sm-6">
                    <label class="col-form-label" for="basic-default-name">نوع الحساب</label>
                    <select class="form-control form-select" v-model="acount_type">
                      <option value="general_director">مدير</option>
                      <option value="suberviser">مشرف</option>
                      <option value="hr">موارد بشرية</option>
                      <option value="muazaf">موظف</option>
                    </select>
                  </div>
-->
                  <br>

              </div>

            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->



    <!-- Create Row -->
    <div class="modal fade" id="EditModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content" enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> تحديث البيانات </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>


          <div class="modal-body">
            <div class="card-body">

              
              <div class="row">
                  <div class="col-sm-6">
                    <label class="col-form-label" for="basic-default-name"> الاســــــــــم </label>
                    <input type="text" class="form-control" id="basic-default-name" v-model="edit_name">
                  </div> 

                  <div class="col-sm-6">
                    <label class="col-form-label" for="basic-default-name"> البريد الالكتروني  </label>
                    <input type="email" class="form-control" id="basic-default-name" v-model="edit_email">
                  </div>

                  <div class="col-sm-6">
                    <label class="col-form-label" for="basic-default-name"> رقم الهاتف </label>
                    <input type="number" class="form-control" id="basic-default-name" v-model="edit_phone">
                  </div>

                  <div class="col-sm-6">
                    <label class="col-form-label" for="basic-default-name"> الوظيفة </label>
                    <input type="text" class="form-control" id="basic-default-name" v-model="edit_work">
                  </div>

                  <div class="col-sm-6">
                    <label class="col-form-label" for="basic-default-name"> القسم </label>
                    <input type="text" class="form-control" id="basic-default-name" v-model="edit_category">
                  </div>

                  <div class="col-sm-6">
                    <label class="col-form-label" for="basic-default-name"> الرقم الوظيفى  </label>
                    <input type="text" class="form-control" id="basic-default-name" v-model="edit_job_number">
                  </div> 

                  <div class="col-sm-6">
                    <label class="col-form-label" for="basic-default-name">  الجنسيه   </label>
                    <input type="text" class="form-control" id="basic-default-name" v-model="edit_nationality">
                  </div>


                  <div class="col-sm-6">
                    <label class="col-form-label" for="basic-default-name"> المهنه الفعليه  </label>
                    <input type="text" class="form-control" id="basic-default-name" v-model="edit_actual_profession">
                  </div>




                  <div class="col-sm-6">
                    <label class="col-form-label" for="basic-default-name">عهدة الموظف </label>
                    <input type="text" class="form-control" id="basic-default-name" v-model="edit_employee_custody">
                  </div>

                  <div class="col-sm-6">
                    <label class="col-form-label" for="basic-default-name">تاريخ تعيين الموظف</label>
                    <input type="date" class="form-control" id="basic-default-name" v-model="edit_date_employment">
                  </div>

                  <div class="col-sm-6">
                    <label class="col-form-label" for="basic-default-name">الاجازات المستحقه</label>
                    <input type="number" class="form-control" id="basic-default-name" v-model="edit_vacations_count">
                  </div>
                <!--
                  <div class="col-sm-6">
                    <label class="col-form-label" for="basic-default-name">نوع الحساب</label>
                    <select class="form-control form-select" v-model="edit_acount_type">
                      <option value="">الحساب</option>
                      <option v-for="item in loopAcountType" :key="item" :value="item.id">{{ item.name }}</option>
                    </select>
                  </div>
-->
                  <br>



              </div>



            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->




    
    <!-- Upload File -->
    <div class="modal fade" id="permissionsModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="updatePermissions()" method="POST" class="modal-content"  enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> الصلاحيــــــات </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body row" style="padding: 0px 30px;">

              <input type="hidden" v-model="permissions.acount_id">

              <div class="form-check form-check-primary mt-3 col-md-6">
                <input class="form-check-input" type="checkbox" v-if="permissions.employee == 1" id="employee" checked="">
                <input class="form-check-input" type="checkbox" v-else id="employee" >
                <label class="form-check-label" for="employee">ملف الموظفين</label>
              </div>
              <div class="form-check form-check-primary mt-3 col-md-6">
                <input class="form-check-input" type="checkbox" v-if="permissions.potential_customers == 1"   id="potential_customers" checked="">
                <input class="form-check-input" type="checkbox" v-else    id="potential_customers">
                <label class="form-check-label" for="potential_customers">العملاء المحتمالين</label>
              </div>
              <div class="form-check form-check-primary mt-3 col-md-6">
                <input class="form-check-input" type="checkbox" v-if="permissions.absence == 1" name="absence"   id="absence" checked>
                <input class="form-check-input" type="checkbox" v-else name="absence"   id="absence" >
                <label class="form-check-label" for="orders">الحضور والغياب</label>
              </div>

              <div class="form-check form-check-primary mt-3 col-md-6">
                <input class="form-check-input" type="checkbox" v-if="permissions.absence_filter == 1"    id="absence_filter" checked>
                <input class="form-check-input" type="checkbox" v-else    id="absence_filter">
                <label class="form-check-label" for="absence_filter">فلتر الحضور والغياب</label>
              </div>
              <div class="form-check form-check-primary mt-3 col-md-6">
                <input class="form-check-input" type="checkbox" v-if="permissions.absence_without_warning == 1"    id="absence_without_warning" checked>
                <input class="form-check-input" type="checkbox" v-else    id="absence_without_warning" >
                <label class="form-check-label" for="absence_without_warning">الغياب دون سابق إنذار</label>
              </div>
              <div class="form-check form-check-primary mt-3 col-md-6">
                <input class="form-check-input" type="checkbox" v-if="permissions.employee_late == 1"    id="employee_late" checked>
                <input class="form-check-input" type="checkbox" v-else    id="employee_late">
                <label class="form-check-label" for="employee_late">تاخير الموظفين</label>
              </div>
      
              <div class="form-check form-check-primary mt-3 col-md-6">
                <input class="form-check-input" type="checkbox" v-if="permissions.punishment == 1"    id="punishment" checked>
                <input class="form-check-input" type="checkbox" v-else    id="punishment">
                <label class="form-check-label" for="punishment">الجزاءات</label>
              </div>
              <div class="form-check form-check-primary mt-3 col-md-6">
                <input class="form-check-input" type="checkbox" v-if="permissions.companies == 1"   id="companies" checked>
                <input class="form-check-input" type="checkbox" v-else    id="companies" >
                <label class="form-check-label" for="companies">حسابات الشركات</label>
              </div>   
              <div class="form-check form-check-primary mt-3 col-md-6">
                <input class="form-check-input" type="checkbox" v-if="permissions.certificate_type == 1"    id="certificate_type" checked>
                <input class="form-check-input" type="checkbox" v-else    id="certificate_type">
                <label class="form-check-label" for="certificate_type">انواع الشهادات</label>
              </div>
              <div class="form-check form-check-primary mt-3 col-md-6">
                <input class="form-check-input" type="checkbox" v-if="permissions.certificate == 1"    id="certificate" checked>
                <input class="form-check-input" type="checkbox" v-else    id="certificate" >
                <label class="form-check-label" for="certificate">الشهادات</label>
              </div>    
              <div class="form-check form-check-primary mt-3 col-md-6">
                <input class="form-check-input" type="checkbox" v-if="permissions.checklist == 1"    id="checklist" checked>
                <input class="form-check-input" type="checkbox" v-else    id="checklist">
                <label class="form-check-label" for="checklist">قائمة التفتيش</label>
              </div>                
              <div class="form-check form-check-primary mt-3 col-md-6">
                <input class="form-check-input" type="checkbox" v-if="permissions.services_form == 1" value="1" id="services_form"  checked>
                <input class="form-check-input" type="checkbox" v-else  id="services_form" value="1">
                <label class="form-check-label" for="services_form">استمارة خدمات</label>
              </div>  
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Upload File -->

  </div> <!-- container -->   
</template>


<script>

import axios from 'axios'
import { useRoute } from 'vue-router'
const route = useRoute();
 export default {
  name: "Member",

  data() {
    return {

      loopData: [],
      loopCity: [],
      loopAcountType: [],
      loopDirectManager:[],

      // Create Acount
      name : "",
      email : "",
      work : "",
      category : "",
      phone : "",
      job_number : "",
      nationality : "",
      age : "",
      profession_residence : "",
      actual_profession : "",
      date_starting_work : "",
      direct_manager : "",
      work_tasks  : "",
      employee_custody  : "",
      password: "",
      acount_type: "",
      date_employment: "",
      vacations_count: "",

      

      // Edit Acount
      edit_name : "",
      edit_email : "",
      edit_work : "",
      edit_category : "",
      edit_phone : "",
      edit_job_number : "",
      edit_nationality : "",
      edit_age : "",
      edit_profession_residence : "",
      edit_actual_profession : "",
      edit_date_starting_work : "",
      edit_direct_manager : "",
      edit_work_tasks  : "",
      edit_employee_custody  : "",
      edit_password: "",
      edit_date_employment: "",
      vacations_count: "",
      edit_acount_type: "",
      uploadFilePicture : {
        picture: null,
        imageUrl: null,
        getPicture: null
      }, 
      acount: this.$route.params.type,

      permissions : {
        acount_id : "",
        employee : "",
        //file_time : "",
        services_form : "",
        checklist : "",
        certificate : "",
        certificate_type : "",
        companies : "",
        punishment : "",
        potential_customers : "",
        employee_late : "",
        absence_without_warning : "",
        absence_filter : "",
        absence : "",
        members : "",
      }

    }
  },
 
  mounted () {
      window.document.title = "الموظفين";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/login';
      }
      this.getData();
  }, // mounted

  watch: {
  '$route' () {
    axios.get(`member`)
        .then(res => {
          this.loopData = res.data.data
        })
  }
},


  methods : {
    getData(){
      axios.get(`member`)
        .then(res => {
          this.loopData = res.data.data
          this.loopDirectManager = res.data.direct_manager
          this.loopAcountType = res.data.acount_type
        })
                
    },

    uploadPicture(e){
      this.uploadFilePicture.picture = e.target.files[0];
      this.uploadFilePicture.imageUrl = URL.createObjectURL(e.target.files[0])
      this.uploadFilePicture.getPicture = e.target.files[0].name;
    },


    store  () {
      const formData = new FormData();
      formData.append("name", this.name);
      formData.append("email", this.email);
      formData.append("work", this.work);
      formData.append("category", this.category);
      formData.append("phone", this.phone);
      formData.append("job_number", this.job_number);
      formData.append("nationality", this.nationality);
      formData.append("age", this.age);
      formData.append("profession_residence", this.profession_residence);
      formData.append("actual_profession", this.actual_profession);
      formData.append("date_starting_work", this.date_starting_work);
      formData.append("direct_manager", this.direct_manager);
      formData.append("work_tasks", this.work_tasks);
      formData.append("employee_custody", this.employee_custody);
      formData.append("acount_type", this.acount_type);
      formData.append("date_employment", this.date_employment);
      formData.append("vacations_count", this.vacations_count);
 
      formData.append("picture", this.uploadFilePicture.picture);
      
      axios.post(`store.acount`, formData)
      .then(res => {
          this.getData();
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'تم التسجيل بنجاح',
            text: "تم انشاء الحساب بنجاح :)",
            showConfirmButton: false,
            timer: 1500
          })
      }).catch(error => {
        console.log(error);
      });
      
    },

    // Get Data For Edit
    openEditModel (id, name, email, work, category, phone, job_number, nationality, age, profession_residence, actual_profession, date_starting_work, direct_manager, work_tasks, employee_custody, password, acount_type, date_employment, vacations_count) {
      this.edit_id                    = id
      this.edit_name                  = name
      this.edit_email                 = email
      this.edit_work                  = work
      this.edit_category              = category
      this.edit_phone                 = phone
      this.edit_job_number            = job_number
      this.edit_nationality           = nationality
      this.edit_age                   = age
      this.edit_profession_residence  = profession_residence
      this.edit_actual_profession     = actual_profession
      this.edit_date_starting_work    = date_starting_work
      this.edit_direct_manager        = direct_manager
      this.edit_work_tasks            = work_tasks
      this.edit_employee_custody      = employee_custody
      this.edit_password              = password
      this.edit_acount_type           = acount_type

      this.edit_date_employment       = date_employment
      this.edit_vacations_count       = vacations_count
    }, // openEditModel




    update () {
      const formData = new FormData();
      formData.append("id", this.edit_id);
      formData.append("name", this.edit_name);
      formData.append("email", this.edit_email);
      formData.append("work", this.edit_work);
      formData.append("category", this.edit_category);
      formData.append("phone", this.edit_phone);
      formData.append("job_number", this.edit_job_number);
      formData.append("nationality", this.edit_nationality);
      formData.append("age", this.edit_age);
      formData.append("profession_residence", this.edit_profession_residence);
      formData.append("actual_profession", this.edit_actual_profession);
      formData.append("date_starting_work", this.edit_date_starting_work);
      formData.append("direct_manager", this.edit_direct_manager);
      formData.append("work_tasks", this.edit_work_tasks);
      formData.append("employee_custody", this.edit_employee_custody);
      formData.append("acount_type", this.edit_acount_type);
      formData.append("date_employment", this.edit_date_employment);
      formData.append("vacations_count", this.edit_vacations_count);
      formData.append("picture", this.uploadFilePicture.picture);
      axios.post(`update.acount`, formData).then(res => {
        this.getData();
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: ' تحديث البيانات ',
            text: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 2000
          })
      });
    },

    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`destroy`, {
          id   : id, 
          table: "users", 
        }).then(res => {
          this.getData();
        });
        Swal.fire(
          'حــــذف !',
          'تم حذف البيانات بنجاح',
          'success'
        )
      }
    })
    }, // onDelete





    getPermissions(id) {
      axios.get(`/get.permissions/${id}`).then(res => {
          this.permissions.acount_id            = res.data.acount_id
          this.permissions.employee             = res.data.employee
          this.permissions.potential_customers  = res.data.potential_customers
          this.permissions.absence              = res.data.absence
          this.permissions.absence_filter       = res.data.absence_filter
          this.permissions.services_form        = res.data.services_form
          this.permissions.checklist            = res.data.checklist
          this.permissions.certificate          = res.data.certificate
          this.permissions.certificate_type     = res.data.certificate_type
          this.permissions.companies            = res.data.companies
          this.permissions.punishment           = res.data.punishment
          this.permissions.employee_late        = res.data.employee_late
          this.permissions.absence_without_warning = res.data.absence_without_warning

      })
    },

    updatePermissions() {

      axios.post(`/update.permissions`, {
        acount_id                       : this.permissions.acount_id,
        employee                        : (document.getElementById('employee').checked)?(1):(0),
        services_form                   : (document.getElementById('services_form').checked)?(1):(0),
        checklist                       : (document.getElementById('checklist').checked)?(1):(0),
        potential_customers             : (document.getElementById('potential_customers').checked)?(1):(0),
        absence                         : (document.getElementById('absence').checked)?(1):(0),
        absence_filter                  : (document.getElementById('absence_filter').checked)?(1):(0),
        absence_without_warning         : (document.getElementById('absence_without_warning').checked)?(1):(0),
        employee_late                   : (document.getElementById('employee_late').checked)?(1):(0),
        punishment                      : (document.getElementById('punishment').checked)?(1):(0),
        certificate                     : (document.getElementById('certificate').checked)?(1):(0),
        certificate_type                : (document.getElementById('certificate_type').checked)?(1):(0),
        companies                       : (document.getElementById('companies').checked)?(1):(0),
      }).then(res => {
        this.getData();
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'تم تحديث الصلاحيــــــات بنجاح',
            showConfirmButton: false,
            timer: 2000
          })
      });
    }, // updatePermissions



 }
}
</script>
   



