
<template>

  <div class="container">
    <br><br>
    <!-- Card Table -->
    <div class="card">
      
<br>
      <div class="row">
        <div class="col-md-6">
          <h5 class="card-header">طلبات التدريب</h5>
        </div>
        <div class="head-label col-md-6">
              <div class="form-floating form-floating-outline mb-4">
                <input @keyup="onSearch()" class="form-control" type="text" placeholder="Materio" id="html5-text-input">
                <label for="html5-text-input">اخبرنا عن ماذا تبحث</label>
              </div>
        </div>
      </div>

        <div class="table-responsive text-nowrap">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>الاسم</th>
                <th>البريد الالكتروني</th>
                <th>رقم الجوال</th>
                <th>العمر</th>
                <th>الدورات</th>
                <th>تحكم</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :key="item">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.name }}  </td>
                <td> {{ item.email }}  </td>
                <td> {{ item.mobile }}  </td>
                <td> {{ item.age }}  </td>
                <td> {{ item.course }}  </td>
                <td>
                  <a class="btn-action" @click="onDelete(item.id)"> 
                    <i class="far fa-trash-alt"></i> 
                  </a>
                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#newModal" 
                      @click="openEditModel(item.name, item.job, item.email, item.age, item.sex, item.mobile, item.qualification, item.nationality, item.administrative_iso, item.knowledge_management, item.members, item.ad, item.he_knew, item.course, item.audit_field)"> 
                      <i class="far fa-info-circle"></i> 
                  </a>
                </td>
              </tr>
            </tbody>

          </table>
        </div>
    </div>
    <!-- Card Table -->







    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content"  enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">


              <div class="row">

                <div class="col-md-6">
                  <strong>الاسم</strong> <br>
                  <span>{{ name }}</span> <br> <br>
                </div>
                <div class="col-md-6">
                  <strong>البريد الالكتروني</strong> <br>
                  <span>{{ email }}</span> <br> <br>
                </div>
                <div class="col-md-6">
                  <strong>العمر</strong> <br>
                  <span>{{ age }}</span> <br> <br>
                </div>
                <div class="col-md-6">
                  <strong>الدورات</strong> <br>
                  <span>{{ course }}</span> <br> <br>
                </div>
                <div class="col-md-6">
                  <strong>الجنس</strong> <br>
                  <span>{{ sex }}</span> <br> <br>
                </div>
                <div class="col-md-6">
                  <strong> رقم الجوال</strong> <br>
                  <span>{{ mobile }}</span> <br> <br>
                </div>
                <div class="col-md-6">
                  <strong>المؤهل العلمي</strong> <br>
                  <span>{{ qualification }}</span> <br> <br>
                </div>
                <div class="col-md-6">
                  <strong>الوظيفة الحالي</strong> <br>
                  <span>{{ job }}</span> <br> <br>
                </div>
                <div class="col-md-6">
                  <strong>الجنسية</strong> <br>
                  <span>{{ nationality }}</span> <br> <br>
                </div>
                <div class="col-md-6">
                  <strong>مدى الإلمام بأسس أنظمة الأيزو الإدارية</strong> <br>
                  <span>{{ administrative_iso }}</span> <br> <br>
                </div>
                <div class="col-md-6">
                  <strong>مدى الخبرة في مجالات إدارة المعرفة</strong> <br>
                  <span>{{ knowledge_management }}</span> <br> <br>
                </div>
                <div class="col-md-6">
                  <strong>مدى الخبرة في مجال التدقيق </strong> <br>
                  <span>{{ audit_field }}</span> <br> <br>
                </div>
                <div class="col-md-6">
                  <strong>هل أنت من أعضاء ومنسوبي</strong> <br>
                  <span>{{ members }}</span> <br> <br>
                </div>
                <div class="col-md-6">
                  <strong>كيف وصلك الإعلان </strong> <br>
                  <span>{{ ad }}</span> <br> <br>
                </div>
                <div class="col-md-6">
                  <strong>عرف إدارة المعرفة</strong> <br>
                  <span>{{ he_knew }}</span> <br> <br>
                </div>
              </div>


            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Create Row -->





  </div> <!-- container -->   
</template>











<style  scoped>
.form-check {
    display: inline-block;
    min-height: 1.434375rem;
    padding-left: 1.7em;
 
}

.modal-body input {
    padding: 5px;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 20px;
}
</style>


<script>
import axios from 'axios'
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://furncn.com/back-end/api";
}
 export default {
  name: "foods-s",

  data() {
    return {
      // Validation
      formValdatorCreatePlanss : [],
      loopData: [],
      loopEmployee: [],
      loopScores:[],
      acount_type : localStorage.getItem('acount_type'),
      user_id     : localStorage.getItem('user_id'),


      name : '', 
      email : '', 
      job: '',
      age : '', 
      sex : '', 
      mobile : '', 
      qualification : '',  
      nationality : '', 
      administrative_iso : '', 
      knowledge_management : '', 
      members : '', 
      ad : '', 
      he_knew : '', 
      course : '', 
      audit_field : '', 
      
    }
  },

  mounted () {
      window.document.title = "التامين الطبـــــــــى";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
  }, // mounted

  methods : {


    acceptRow(id, muazaf, column) {
      axios.post(`${urlSite}/update.apprpve.statement`, {
        id: id,
        table : 'insurance',
        column: column,
        muazaf: muazaf,
        action : event.target.value
      }).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });
    },

    getData(){
      axios.get(`training.form`)
        .then(res => {
          this.loopData = res.data
        })
    
    },




    

    // Get Data For Edit
    openEditModel (name, job, email, age, sex, mobile, qualification, nationality, administrative_iso, knowledge_management, members, ad, he_knew, course, audit_field) {
      this.name = name,
      this.job = job,
      this.email = email
      this.age = age,
      this.sex = sex,
      this.mobile = mobile,
      this.qualification = qualification,
      this.nationality = nationality
      this.administrative_iso = administrative_iso,
      this.knowledge_management = knowledge_management
      this.members = members
      this.ad = ad
      this.he_knew = he_knew
      this.course = course 
      this.audit_field = audit_field
    }, // openEditModel


    onSearch () {
      axios.get(`training.form?search=${event.target.value}`)
      .then(res => { this.loopData = res.data })
    }, // End onSearch


    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`${urlSite}/destroy`, {
          id   : id, 
          table: "insurance", 
        }).then(res => {
          this.getData();
        });
        Swal.fire(
          'حــــذف !',
          'تم حذف البيانات بنجاح',
          'success'
        )
      }
    })

    }, // onDelete







 }
}
</script>
   