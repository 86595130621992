
<template>
  <div class="container">

    <form class="card card-filter" @submit.prevent="" @submit="filters()">
      <div class="row filter">
        <div class="col-md-2">
          <div class="form-floating form-floating-outline mb-4">
              <input class="form-control" type="date" placeholder="Materio" v-model="filter.start" id="html5-text-input">
              <label for="html5-text-input">بداية</label>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-floating form-floating-outline mb-4">
              <input class="form-control" type="date" placeholder="Materio" v-model="filter.end" id="html5-text-input">
              <label for="html5-text-input">نهايـــــة</label>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-floating form-floating-outline">
            <select  class="form-control" v-model="filter.muazaf">
                <option value=""></option>
                <option v-for="item in loopEmployee" :key="item" :value="item.id">{{ item.name }}</option>
            </select>
            <label for="html5-text-input">الموظف</label>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-floating form-floating-outline mb-4">
              <input class="form-control" type="text" placeholder="Materio" v-model="filter.search" id="html5-text-input">
              <label for="html5-text-input">اخبرنا عن ماذا تبحث</label>
          </div>
        </div>
        <div class="col-md-2">
          <button class="dt-button create-new btn btn-primary" 
                        style="width: 100%;"
                        type="submit">
                        <span>
                          <i class="far fa-search"></i>
                          <span class="d-none d-sm-inline-block"> بحــــــث </span>
                        </span>
                </button>
        </div>
      </div>
    </form>
  <br>

    
    <div class="card">
      <div class="card-datatable table-responsive pt-0">
        <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
          <div class="card-header flex-column flex-md-row">

          <div class="dt-action-buttons row text-end pt-3 pt-md-0">
            <div class="head-label col-md-6">
              <h5 style="text-align: right;" class="card-title mb-0">تاخير الموظفين</h5>
            </div>
            <div class="dt-buttons  col-md-6"> 
              <button class="dt-button create-new btn btn-primary" 
                      tabindex="0" 
                      data-toggle="modal" 
                      data-target="#newModal" 
                      aria-controls="DataTables_Table_0" 
                      type="button">
                      <span>
                        <i class="mdi mdi-plus me-sm-1"></i> 
                        <span class="d-none d-sm-inline-block">اضافة</span>
                      </span>
              </button>
            </div>
          </div>
        </div>
        <table class="datatables-basic table table-bordered dataTable no-footer dtr-column table-hover">
          <thead class="table-light">
              <tr>
                <th>#</th>
                <th>رقم المستند</th>
                <th>الموظف</th>
                <th>سبب التأخیر</th>
                <th>وقت التاخير</th>
                <th>الإجـــــــراء</th>
                <th>ملاحظــــات</th>
                <th>التوقيت</th>
                <th>تحكم</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :key="item.name">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.id   }}  </td>
                <td> 
                  <span v-for="employee in loopEmployee" :key="employee">
                    <span v-if="employee.id == item.muazaf_id">{{ employee.name }}</span>
                  </span>   
                </td>
                <td> {{ item.reason }}  </td>
                <td> {{ item.late_time }}  </td>
                <td> {{ item.action }} </td>
                <td> {{ item.note }}  </td>
                <td> {{ item.time }} </td>
                <td>              

                  <a class="btn-action" @click="onDelete(item.id)"> 
                    <i class="far fa-trash-alt"></i> 
                  </a>
                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#EditModal"	
                      @click="openEditModel(item.id, item.muazaf_id, item.late_time, item.reason, item.action, item.note, item.muazaf, item.general_director)">
                      <i class="fas fa-pencil-alt"></i> 
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          </div>
        </div>
    </div>



    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content"  enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">

              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">الموظف</label>
                  <div class="col-sm-9">
                    <select v-model="muazaf_id" class="form-control">
                      <option v-for="item in loopEmployee" :key="item" :value="item.id">{{ item.name }}</option>
                    </select>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">عدد ساعات التاخير</label>
                  <div class="col-sm-9">
                    <input type="text" v-model="late_time" class="form-control" placeholder="">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> سبب التأخیر </label>
                  <div class="col-sm-9">
                    <textarea v-model="reason" class="form-control" cols="2" rows="2" placeholder="سبب التأخیر"></textarea>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> الإجــــــراء </label>
                  <div class="col-sm-9">
                    <textarea v-model="action" class="form-control" cols="2" rows="2" placeholder="الإجراء المتخذ من قبل الإدارة العلیا"></textarea>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> ملاحظــــات </label>
                  <div class="col-sm-9">
                    <textarea v-model="note" class="form-control" cols="2" rows="2" placeholder="ملاحظــــات"></textarea>
                  </div>
              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Create Row -->



    <!-- Create Row -->
    <div class="modal fade" id="EditModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content" enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> تحديث البيانات </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">

              <input type="hidden"  v-model="edit_id" >

              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">الموظف</label>
                  <div class="col-sm-9">
                    <select v-model="edit_muazaf_id" class="form-control">
                      <option v-for="item in loopEmployee" :key="item" :value="item.id">{{ item.name }}</option>
                    </select>
                  </div>
              </div>
              <div class="row mb-3">
                <label class="col-sm-3 col-form-label" for="basic-default-name">عدد ساعات التاخير</label>
                  <div class="col-sm-9">
                    <input type="day" v-model="edit_late_time" class="form-control" placeholder="">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> سبب التأخیر </label>
                  <div class="col-sm-9">
                    <textarea v-model="edit_reason" class="form-control" cols="2" rows="2" placeholder="سبب التأخیر"></textarea>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> الإجــــــراء </label>
                  <div class="col-sm-9">
                    <textarea v-model="edit_action" class="form-control" cols="2" rows="2" placeholder="الإجراء المتخذ من قبل الإدارة العلیا"></textarea>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> ملاحظــــات </label>
                  <div class="col-sm-9">
                    <textarea v-model="edit_note" class="form-control" cols="2" rows="2" placeholder="ملاحظــــات"></textarea>
                  </div>
              </div>


            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Create Row -->

 

  </div> <!-- container -->   
</template>

<style>
.filter i {
    padding: 8px;
}


.card-filter {
    padding: 24px 24px 0px 24px;
}
</style>

<script>
import axios from 'axios'

 export default {
  name: "certificate",

  data() {
    return {
      //Get Data
      loopData                 : [],
      // Add Data

      muazaf_id	          : "",
      late_time	          : "",
      time	              : "",
      reason	            : "",
      action	            : "",
      muazaf              : "",
      general_director    : "",
      note                : "",

      // Add Data
      edit_id                 : "",
      edit_muazaf_id	        : "",
      edit_late_time	        : "",
      edit_time	              : "",
      edit_reason	            : "",
      edit_action	            : "",
      edit_muazaf             : "",
      edit_general_director   : "",
      edit_note               : "",

      filter : {
        muazaf         : "",
        start          : "",
        end            : "",
        search         : "",
      }, 


      // localStorage
      acount_type   : localStorage.getItem('acount_type'),
      user_id       : localStorage.getItem('user_id'),

    }
  },

  mounted () {
      window.document.title = "تاخير الموظفين";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
  }, // mounted

  methods : {

    getData(){
      axios.get(`employee.late`).then(res => { 
        this.loopData = res.data.data
        this.loopEmployee = res.data.employee
       })
    },


    filters () {
      axios.get(`employee.late`
      + "?muazaf=" + this.filter.muazaf 
      + "&start="  + this.filter.start
      + "&end="    + this.filter.end
      + "&search=" + this.filter.search
      ) .then(res => { this.loopData = res.data.data; })
    }, // End filter


    store  () {
      axios.post(`store.employee.late`, {
        muazaf              : this.muazaf_id,
        late_time           : this.late_time,
        reason              : this.reason,
        action              : this.action,
        note                : this.note,
      }).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });
    },

    

    // Get Data For Edit
                           

    openEditModel (id, muazaf_id, late_time, reason, action, note, muazaf, general_director) {
        this.edit_id      = id
        this.edit_muazaf_id  = muazaf_id
        this.edit_late_time = late_time
        this.edit_reason  = reason
        this.edit_action  = action
        this.edit_note    = note
        this.edit_muazaf  = muazaf
        this.edit_general_director = general_director
    }, // openEditModel
    
    update  () {
      alert(this.edit_muazaf_id)
      axios.post(`update.employee.late`, {
        id                  : this.edit_id,
        muazaf_id           : this.edit_muazaf_id,
        late_time           : this.edit_late_time,
        reason              : this.edit_reason,
        action              : this.edit_action,
        note                : this.edit_note,
        muazaf              : this.edit_muazaf,
        general_director    : this.edit_general_director,
      }).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });
    },

    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post('destroy', {
          id   : id, 
          table: "employee_late", 
        }).then(res => {
          this.getData();
        });
        Swal.fire(
          'حــــذف !',
          'تم حذف البيانات بنجاح',
          'success'
        )
      }
    })

    }, // onDelete







 }
}
</script>
   