<template>
    <div>
        <div class="card">
              <div class="card-body">
                <h4 class="mb-4">المهمـــــــات</h4>
                <ul class="timeline card-timeline mb-0">
                  <li v-for="item in loopTasks" :key="item" class="timeline-item timeline-item-transparent">
                    <span class="timeline-point timeline-point-danger"></span>
                    <div class="timeline-event">
                      <div class="timeline-header mb-1">
                        <h6 class="mb-3">{{ item.title }}</h6>
                        <!--
                        <small> <i class="mdi mdi-calendar-blank-outline"></i> {{ item.time }} </small>
                        -->
                      </div>
                      <div class="timeline-header mb-1">
                        <small> <i class="mdi mdi-calendar-blank-outline me-1 mdi-20px"></i> بداية <strong>{{ item.start }}</strong> </small>
                        <small> <i class="mdi mdi-calendar-blank-outline me-1 mdi-20px"></i> نهاية <strong>{{ item.end }}</strong> </small>
                      </div>
                      <p class="mb-2">{{ item.description }}</p>
                      <div class="d-flex timeline-header mb-1">
                        <a href="javascript:void(0)"  class="FlexBox me-3">
                          <img src="../../assets/img/avatars/5.png" alt="PDF image" width="50" class="me-2">
                          <div>
                            <span class="fw-semibold">{{ item.username }}</span> <br>
                            <span class="">{{ item.acount_type }}</span>
                          </div>
                        </a>
                        <div class="timeline-header mb-1">
                          <a  class="btn-action badge bg-label-primary" 
                              style="background: none !important"
                              data-toggle="modal" 
                              data-target="#updateTask" 
                              @click="updateTaskModel(item.id, item.title, item.description, item.start, item.end, employee)"> 
                              <i class="fas fa-pencil-alt"></i> 
                          </a>
                          <a  class="btn-action badge bg-label-danger" 
                              @click="onDelete(item.id)"
                              style="background: none !important"> 
                              <i class="far fa-trash-alt"></i> 
                          </a>
                        </div>
                      </div>

                      <br>

                    </div>
                  </li>

                </ul>
              </div>
            </div>
            <br>
            <div class="card">
              <form class="card-body"  @submit.prevent="" @submit="storeTask()" method="POST"  enctype="multipart/form-data">
                <small class="card-text text-uppercase badge bg-label-success"> اضافة مهمــــــة جديدة</small>
                <br><br>
                <div class="form-floating form-floating-outline">
                    <select class="form-control" v-model="employee">
                      <option v-for="item in loopEmployee" :key="item" :value="item.id">{{ item.name }}</option>
                    </select>
                    <label for="floatingInput">الموظـــف</label>
                </div>
                <br>
                <div class="form-floating form-floating-outline">
                    <input type="text"  v-model="title_task" class="form-control" id="floatingInput" placeholder="عنوان المهمة" required aria-describedby="floatingInputHelp">
                    <label for="floatingInput">العنــــــــوان</label>
                </div>
                <br>
                <div class="form-floating form-floating-outline mb-4">
                    <textarea v-model="description_task" class="form-control h-px-100" id="exampleFormControlTextarea1" required placeholder="وصف المهمــــة"></textarea>
                    <label for="exampleFormControlTextarea1">وصف المهمــــة</label>
                </div>
                <div class="row">
                  <div class="form-floating form-floating-outline col-md-6">
                      <input type="date" v-model="start_task" class="form-control" id="floatingInput" placeholder="عنوان المهمة" required aria-describedby="floatingInputHelp">
                      <label for="floatingInput">تاريخ المهمة</label>
                  </div>
                  <div class="form-floating form-floating-outline col-md-6">
                      <input type="date" v-model="end_task" class="form-control" id="floatingInput" placeholder="عنوان المهمة" required aria-describedby="floatingInputHelp">
                      <label for="floatingInput">انتهاء المهمة</label>
                  </div>
                </div>
                <br>
                <div v-if="TaskMessageSuccess == true">
                  <div class="bs-toast toast fade show" style="width: auto !important;" role="alert" aria-live="assertive" aria-atomic="true">
                    <div class="toast-header">
                      <i class="mdi mdi-check-circle text-success me-2"></i>
                      <div class="me-auto fw-medium">رسالة تاكيد</div>
                      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                    </div>
                    <div class="toast-body">تم اضافة المهمة بنجاح</div>
                  </div> <br>
                </div> <!--end Task Message-->
                <button type="submit" class="btn rounded-pill btn-primary waves-effect waves-light" style="width: 100%;">
                  <span class="tf-icons mdi mdi-checkbox-marked-circle-outline me-1"></span>
                  اضافة مهمــــــة 
                </button>
              </form>
            </div>

        <!-- تحديث البيانات -->
        <div class="modal fade" id="updateTask" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <form @submit.prevent="" @submit="updateTask()" method="POST" class="modal-content" enctype="multipart/form-data">
            <div class="modal-header">
                <h5 class="modal-title" id="editModalLabel"> تحديث البيانات </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="card-body">
                <input type="hidden"  v-model="edit_task_id" >
                <div class="form-floating form-floating-outline">
                        <input type="text" v-model="edit_title_task" class="form-control" id="floatingInput" placeholder="عنوان المهمة" aria-describedby="floatingInputHelp">
                        <label for="floatingInput">العنــــــــوان</label>
                    </div>
                    <br>
                    <div class="form-floating form-floating-outline mb-4">
                        <textarea v-model="edit_description_task" class="form-control h-px-100" id="exampleFormControlTextarea1" placeholder="وصف المهمــــة"></textarea>
                        <label for="exampleFormControlTextarea1">وصف المهمــــة</label>
                    </div>
                    <div class="row">
                    <div class="form-floating form-floating-outline col-md-6">
                        <input type="date" v-model="edit_start_task" class="form-control" id="floatingInput" placeholder="عنوان المهمة" aria-describedby="floatingInputHelp">
                        <label for="floatingInput">تاريخ المهمة</label>
                    </div>
                    <div class="form-floating form-floating-outline col-md-6">
                        <input type="date" v-model="edit_end_task" class="form-control" id="floatingInput" placeholder="عنوان المهمة" aria-describedby="floatingInputHelp">
                        <label for="floatingInput">انتهاء المهمة</label>
                    </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
                <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
            </div>
            </form>
        </div>
        </div>
        <!-- تحديث البيانات -->
    </div>
</template>


<script>



import axios from 'axios'

export default {
    data () {
        return {
            TaskMessageSuccess : false,
            updateTaskMessageSuccess : false,

            loopTasks : [],
            loopEmployee : [],

            title_task  : '',
            description_task : '',
            start_task  : '',
            end_task    : '',
            employee    : '',

            edit_task_id          : '',
            edit_title_task       : '',
            edit_description_task : '',
            edit_start_task       : '',
            edit_end_task         : '',
            edit_employee         : '',


        }
    },

    mounted () {
        window.document.title = " الطلبات ";
        this.getData();
    }, // mounted

    
  methods : {

async getData(){
  const response = await axios.get(`services.form.details/${this.$route.params.id}`)
    .then(res => {
      this.loopTasks      = res.data.tasks;
      this.loopEmployee   = res.data.employee
    })      
},









storeTask  () {
  axios.post(`store.tasks`, {
    title        : this.title_task,
    description  : this.description_task,
    start        : this.start_task,
    end          : this.end_task,
    employee     : this.employee,
    type         : 'services_form',
    order        : this.$route.params.id,
  }).then(res => {
      this.TaskMessageSuccess = true,
      this.getData();
  }); 
},

updateTaskModel(id, title, description, start, end, employee) {
  this.edit_task_id          = id
  this.edit_title_task       = title
  this.edit_description_task = description
  this.edit_start_task       = start
  this.edit_end_task         = end
  this.employee              = employee
},

updateTask  () {
  axios.post(`update.tasks`, {
    id           : this.edit_task_id,
    title        : this.edit_title_task,
    description  : this.edit_description_task,
    start        : this.edit_start_task,
    end          : this.edit_end_task,
    employee     : this.employee,
  }).then(res => {
      this.updateTaskMessageSuccess = true,
      this.getData();
  }); 
},











onDelete(id) {
  Swal.fire({
  title: 'رسالة تحذيرية',
  text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: 'قم بالحذف'
}).then((result) => {
  if (result.isConfirmed) {
    axios.post("/destroy", {
      id   : id, 
      table: "tasks", 
    }).then(res => {
      this.getData();
    });
    Swal.fire(
      'حــــذف !',
      'تم حذف البيانات بنجاح',
      'success'
    )
  }
})

}, // onDelete





}

} // export default

</script>
