
<template>
 
  <div class="container">
    <div class="card">
      <div class="card-datatable table-responsive pt-0">
        <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
          <div class="card-header flex-column flex-md-row">

          <div class="dt-action-buttons row text-end pt-3 pt-md-0">
            <div class="head-label">
              <h5 style="text-align: right;" class="card-title mb-0">  فلتر الحضور والغياب  </h5>
              <br>
            </div>
            
            <form class="row justify-content-center" @submit.prevent="" @submit="filter()">
              <div class="col-md-6 col-12 mb-4">
                <div class="form-floating form-floating-outline">
                  <input type="date" v-model="start" class="form-control flatpickr-input active" placeholder="YYYY-MM-DD to YYYY-MM-DD" id="flatpickr-range" >
                  <label for="flatpickr-range">البـــــدء</label>
                </div>
              </div>
              <div class="col-md-6 col-12 mb-4">
                  <div class="form-floating form-floating-outline">
                    <input type="date" v-model="end" class="form-control flatpickr-input active" placeholder="YYYY-MM-DD to YYYY-MM-DD" id="flatpickr-range" >
                    <label for="flatpickr-range">الانتهـــــاء</label>
                  </div>
              </div>
              <div class="col-md-4 col-12 mb-4">
                <div class="form-floating form-floating-outline">
                  <label for="">  </label>
                  <select v-model="user" class="form-control">
                    <option> اختار موظف </option>
                    <option :value="use.id" v-for="use in loopEmployee" :key="use">{{ use.name }}</option>
                  </select>
                  <label for="flatpickr-range">الموظفين</label>
                </div>
              </div>
              <div class="col-md-4 col-12 mb-4">
                  <div class="form-floating form-floating-outline">
                    <select v-model="status" class="form-control flatpickr-input active">
                      <option value="0"></option>
                      <option value="plus">حضور</option>
                      <option value="mins">غياب</option>
                    </select>
                    <label for="flatpickr-range">الحالة</label>
                  </div>
              </div>

              <div class="col-md-12 col-12 mb-4">
                <button style="width: 100%;" class="dt-button create-new btn btn-primary" tabindex="0"  type="submit">
                  <span >
                    <i class="mdi mdi-plus me-sm-1"></i>
                    <span class="d-none d-sm-inline-block">بحـــــــث</span>
                  </span>
                </button>
              </div>
            </form> <!-- @submit.prevent="" @submit="filter()" -->

          </div>
        </div>


          <table class="datatables-basic table table-bordered dataTable no-footer dtr-column" id="DataTables_Table_0" aria-describedby="DataTables_Table_0_info">
              <thead class="table-light">
                <tr>
                  <th>#</th>
                  <th>الموظف</th>
                  <th>التوقيت</th>
                  <th>الحالة</th>
                  <th>الانصراف</th>
                  <!--
                  <th>الوقت الاضافي</th>
                  -->
                </tr>
              </thead>
              <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :key="item.name">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.username  }}  </td>
                <td>{{ item.attendance_time }}</td>
                <td> 
                  <span v-if="item.status == 'plus'">حضور</span>
                  <span v-if="item.status == 'vacation'">اجازة</span>
                  <span v-if="item.status == 'mins'">غياب</span>
                </td>

                <td>{{ item.time_departure }}</td>

                <!--<td>{{ item.overtime }} ساعة </td> -->

              </tr>
            </tbody>
            </table>
          </div>
        </div>
      </div>


  


  </div> <!-- container -->   
</template>


<style  scoped>
.card {
  padding: 25px;
}

.card thead {
  border-width: var(--bs-border-width) 0;
}

.card .action {
  width: 115px;
}
</style>



<script>
import axios from 'axios'


 export default {
  name: "foods-s",

  data() {
    return {
      // Validation
      formValdatorCreatePlanss : [],
      loopData: [],
      loopEmployee: [],
      loopManager:[],

      acount_type : localStorage.getItem('acount_type'),
      author      : localStorage.getItem("user_id"),

      start	  : "",
      end	    : "",
      user	  : "",
      status	: "",
      manager : "",




      
    }
  },

  mounted () {
      window.document.title = "فلتر الحضور والغياب";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
  }, // mounted

  methods : {

    getData(){
      axios.get(`/absence.filter`)
        .then(res => {
          this.loopData         = []
          this.loopEmployee     = res.data.employee
          this.loopManager      = res.data.manager
        })
    },



    getEmployee() {
      axios.get(`/get.employee.by.manager/${event.target.value}`).then(res => {
        this.loopEmployee     = res.data.data
      });
    },

    filter () {
      axios.get(`/absence.filter`
      + "?start="     + this.start 
      + "&end="       + this.end
      + "&user="      + this.user
      + "&status="    + this.status
      + "&manager="   + this.manager) 
      .then(res => {
          this.loopData = res.data.data;
        })
    },



 }
}
</script>
   