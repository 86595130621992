<script>
  
import axios from 'axios'

export default {

  name: "MenuRight",
  data() {
      return {
        user_id     : localStorage.getItem("user_id"),
        status_auth : localStorage.getItem('status_auth'),
        acount_type : localStorage.getItem('acount_type'),
        notifications: [],
        startInterval: 0,
        count_notification: null,
        permissions: '',
        sp : 0,
      }
    },  
  mounted () {


    if (localStorage.getItem("status_auth")) {
      //this.getCount();
      this.getPermissions();
      
      setInterval(() => {
        //return this.getCount();
      }, 10000);
    } // status_auth
  

  }, // mounted

  methods : {
    /*
    getCount(){
      axios.get(`/notifications.count`)
        .then(res => {
            this.count_notification  = res.data.count
       })
    },
*/
    getPermissions(){
      axios.get(`/permissions`)
        .then(res => {
            this.permissions  = res.data.permissions
       })
    },

    logout () {
      localStorage.removeItem("user_id");
      localStorage.removeItem("status_auth");
      localStorage.removeItem("token");
      localStorage.removeItem("acount_type");
      localStorage.removeItem("reload");
      window.location.href = '/';
    },


  }

  // filter Payment
  // Dashbord



}


</script>



<template>

        <aside id="layout-menu" v-if="$route.name != 'home' && $route.name != 'login' && $route.name != 'signup' && $route.name != 'pdf'" style="display: block;" class="layout-menu menu-right menu-vertical menu bg-menu-theme">
          <div class="app-brand demo">
            <a href="index.html" class="app-brand-link">
              <span class="app-brand-logo demo me-1">
                <span style="color: var(--bs-primary)">
                
                </span>
              </span>
              <span class="app-brand-text demo menu-text fw-semibold ms-2">Materio</span>
            </a>
            
            <a href="javascript:void(0);" class="layout-menu-toggle menu-link text-large ms-auto">
              <i class="mdi menu-toggle-icon d-xl-block align-middle mdi-20px"></i>
            </a>
          </div>

          <div class="menu-inner-shadow"></div>

          <ul class="menu-inner py-1">

          <!-- Dashboard 
            <li class="menu-item" v-if="acount_type == 'general_director' || acount_type == 'hr'">
              <router-link to="/dashbord" class="menu-link">
                <i class="far fa-chart-line"></i>
                <div> لوحة الاحصائيات  </div>
              </router-link>
            </li>
-->
            <li class="menu-item">
              <router-link to="/profile" class="menu-link">
                <i class="far fa-user-cog"></i>
                <div> الملف الشخصي </div>
              </router-link>
            </li>
            <li class="menu-item">
              <router-link to="/notifications" class="menu-link">
                <i class="far fa-comments-alt"></i>
                <div> الاشعارات</div>
                <div class="badge bg-danger rounded-pill ms-auto">{{count_notification}}</div>
              </router-link>
            </li>
            <li class="menu-item" v-if="permissions.employee == 1">
              <router-link to="/member" class="menu-link">
                <i class="far fa-users"></i>
                <div> ملف الموظفين </div>
              </router-link>
            </li>  
            <li class="menu-item" v-if="permissions.absence == 1">
              <router-link to="/absence" class="menu-link">
                <i class="far fa-user-plus"></i>
                <div> الحضور والغياب </div>
              </router-link>
            </li> 
            <li class="menu-item" v-if="permissions.absence_filter == 1">
              <router-link to="/absence.filter" class="menu-link">
                <i class="far fa-user-plus"></i>
                <div>فلتر الحضور والغياب </div>
              </router-link>
            </li> 
            <li class="menu-item" v-if="permissions.absence_without_warning == 1">
              <router-link to="/absence.without.warning" class="menu-link">
                <i class="fal fa-user-lock"></i>
                <div>غیاب بدون إذن مسبق</div>
              </router-link>
            </li> 
            <li class="menu-item" v-if="permissions.employee_late == 1">
              <router-link to="/employee.late" class="menu-link">
                <i class="fal fa-user-clock"></i>
                <div>تاخير الموظفين</div>
              </router-link>
            </li> 
            <li class="menu-item" v-if="permissions.potential_customers == 1">
              <router-link to="/potential.customers" class="menu-link">
                <i class="fal fa-users-medical"></i>
                <div>العملاء المحتملين </div>
              </router-link>
            </li> 
            <!--
            <li class="menu-item">
              <router-link to="/clints.cases" class="menu-link">
                <i class="fal fa-users-medical"></i>
                <div> حالات العملاء المحتمالين</div>
              </router-link>
            </li> 
            -->
            <li class="menu-item" v-if="permissions.punishment == 1">
              <router-link to="/punishment" class="menu-link">
                <i class="far fa-user-slash"></i>
                <div>الجزاءات</div>
              </router-link>
            </li> 
            <li class="menu-item" v-if="permissions.companies == 1">
              <router-link to="/companies" class="menu-link">
                <i class="far fa-building"></i>
                <div>حسابات العملاء</div>
              </router-link>
            </li> 
            <li class="menu-item" v-if="permissions.certificate_type == 1">
              <router-link to="/certificate.type" class="menu-link">
                <i class="far fa-stamp"></i>
                <div>انواع الشهادات</div>
              </router-link>
            </li> 
            <li class="menu-item" v-if="permissions.certificate == 1">
              <router-link to="/certificate" class="menu-link">
                <i class="fal fa-file-certificate"></i>
                <div>الشهادات</div>
              </router-link>
            </li> 
            <li class="menu-item" v-if="permissions.checklist == 1">
              <router-link to="/checklist" class="menu-link">
                <i class="fal fa-clipboard-list-check"></i>
                <div>قائمة التفتيش</div>
              </router-link>
            </li> 
            <!--
            <li class="menu-item" v-if="acount_type == 'general_director'">
              <router-link to="/questions" class="menu-link">
                <i class="far fa-clipboard-list"></i>
                <div>الاسئلـــــة</div>
              </router-link>
            </li> 
            -->
            <li class="menu-item">
              <router-link to="/tasks" class="menu-link">
                <i class="fal fa-file-signature"></i>
                <div>المهمـــــات</div>
              </router-link>
            </li> 
            <li class="menu-item" v-if="permissions.services_form == 1">
              <router-link to="/services.form" class="menu-link">
                <i class="fal fa-file-search"></i>
                <div>استمارة خدمات</div>
              </router-link>
            </li> 
            <li class="menu-item" v-if="permissions.services_form == 1">
              <router-link to="/training.form" class="menu-link">
                <i class="fal fa-file-search"></i>
                <div>طلبات التدريب</div>
              </router-link>
            </li> 
            <li class="menu-item" v-if="permissions.services_form == 1">
              <router-link to="/training.time" class="menu-link">
                <i class="fal fa-file-search"></i>
                <div>مواعيد التدريب </div>
              </router-link>
            </li> 
            <!--
            <li class="menu-item" v-if="permissions.file_time == 1">
              <router-link to="/file.time" class="menu-link">
                <i class="far fa-file-pdf"></i>
                <div>ملفات بتوقيت</div>
              </router-link>
            </li>
            -->
            <li class="menu-item" @click="logout()">
              <a href="#" class="menu-link">
                <i class="fal fa-sign-out-alt"></i>
                <div  >تسجيل الخروج</div>
              </a>
            </li>

          </ul>
        </aside>
        <!-- / Menu -->
</template>

<style>
.menu-right i {
    font-weight: 400;
    margin-left: 15px;
    font-size: 21px;
    width: 30px;
    text-align: center;
}

#layout-menu {
  overflow: scroll;
}
</style>



file_time
services_form
checklist
certificate
certificate_type
companies
punishment
potential_customers
employee_late
absence_without_warning
absence_filter
absence
