
<template>
  <div class="container">

    <div class="card">
      <div class="card-datatable table-responsive pt-0">
        <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
          <div class="card-header flex-column flex-md-row">

          <div class="dt-action-buttons row text-end pt-3 pt-md-0">
            <div class="head-label col-md-7">
              <h5 style="text-align: right;" class="card-title mb-0">العمــــــــلاء</h5>
            </div>
            <div class="head-label col-md-3">
              <div class="form-floating form-floating-outline mb-4">
                <input  class="form-control" @keyup="querySearch" type="text" id="html5-date-input" placeholder="">
                <label  for="html5-date-input">اخبرنا عن ماذا تبحث</label>
              </div>
            </div>
            <div class="dt-buttons  col-md-2"> 
              <button class="dt-button create-new btn btn-primary" 
                      tabindex="0" 
                      data-toggle="modal" 
                      data-target="#newModal" 
                      aria-controls="DataTables_Table_0" 
                      type="button">
                      <span>
                        <i class="mdi mdi-plus me-sm-1"></i> 
                        <span class="d-none d-sm-inline-block">اضافة</span>
                      </span>
              </button>
            </div>
          </div>
        </div>
        <table class="datatables-basic table table-bordered dataTable no-footer dtr-column table-hover">
          <thead class="table-light">
              <tr>
                <th>#</th>
                <th>الاسم</th>
                <th>البريد الالكتروني</th>
                <th>رقم الهاتف</th>
                <th>المدينة</th>
                <th>العنوان</th>
                <th>الحالـــــة</th>
                <th>ملاحظــــات</th>
                <th>تغيير الحالــة</th>
                <th>تحكم</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :v-if="loopData.length > 0" :key="item.name">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.name  }}  </td>
                <td> {{ item.email  }}  </td>
                <td> {{ item.phone }}  </td>
                <td> {{ item.city }} </td>
                <td> {{ item.address }}  </td>
                <td> {{ item.name_cases }}  </td>
                <td> {{ item.note }}  </td>
                <td> 
                  <select :data-id="item.id" style="width: 100px;" class="form-select form-control" @change="updateStatus">
                    <option ></option>
                    <option v-for="cases in loopCases" :key="cases" :value="cases.id">{{ cases.name }}</option>
                  </select>
                </td>
                <td>
                  <a class="btn-action" @click="onDelete(item.id)"> 
                    <i class="far fa-trash-alt"></i> 
                  </a>
                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#EditModal" 
                      @click="openEditModel(item.id, item.name, item.email, item.phone, item.city, item.address, item.note)"> 
                      <i class="fas fa-pencil-alt"></i> 
                  </a>
                </td>
              </tr>
              <tr v-if="loopData.length == 0" >
                <td class="text-center">ناسف لا توجد نتائج</td>
              </tr>
            </tbody>
          </table>
          </div>
        </div>
    </div>



    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content"  enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">

              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">اسم العميل</label>
                  <div class="col-sm-9">
                    <input type="text" v-model="name" class="form-control" placeholder="">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">البريد الالكتروني</label>
                  <div class="col-sm-9">
                    <input type="text" v-model="email" class="form-control" placeholder="">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">رقم الهاتف</label>
                  <div class="col-sm-9">
                    <input type="text" v-model="phone" class="form-control" placeholder="">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">المدينة</label>
                  <div class="col-sm-9">
                    <input type="text" v-model="city" class="form-control" placeholder="">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">العنوان</label>
                  <div class="col-sm-9">
                    <input type="text" v-model="address" class="form-control" placeholder="">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> ملاحظــــات </label>
                  <div class="col-sm-9">
                    <textarea v-model="note" class="form-control" cols="3" rows="3"></textarea>
                  </div>
              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Create Row -->



    <!-- Create Row -->
    <div class="modal fade" id="EditModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content" enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> تحديث البيانات </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">

              <input type="hidden"  v-model="editID" >

              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">اسم العميل</label>
                  <div class="col-sm-9">
                    <input type="text" v-model="edit_name" class="form-control" placeholder="">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">البريد الالكتروني</label>
                  <div class="col-sm-9">
                    <input type="text" v-model="edit_email" class="form-control" placeholder="">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">رقم الهاتف</label>
                  <div class="col-sm-9">
                    <input type="text" v-model="edit_phone" class="form-control" placeholder="">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">المدينة</label>
                  <div class="col-sm-9">
                    <input type="text" v-model="edit_city" class="form-control" placeholder="">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">العنوان</label>
                  <div class="col-sm-9">
                    <input type="text" v-model="edit_address" class="form-control" placeholder="">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> ملاحظــــات </label>
                  <div class="col-sm-9">
                    <textarea v-model="edit_note" class="form-control" cols="3" rows="3"></textarea>
                  </div>
              </div>


            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Create Row -->

  </div> <!-- container -->   
</template>


<script>
import axios from 'axios'

 export default {
  name: "certificate",

  data() {
    return {
      //Get Data
      loopData        : [],
      loopCases       : [],
      // Add Data
      name            : "",
      email           : "",
      phone           : "",
      city            : "",
      address         : "",
      note            : "",
      // Add Data
      edit_id         : "",
      edit_name       : "",
      edit_email      : "",
      edit_phone      : "",
      edit_city       : "",
      edit_address    : "",
      edit_note       : "",
      // localStorage
      acount_type   : localStorage.getItem('acount_type'),
      user_id       : localStorage.getItem('user_id'),

    }
  },

  mounted () {
      window.document.title = "العمــــــــلاء";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
  }, // mounted

  methods : {


    querySearch (event) {
      axios.get(`potential.customers?search=${event.target.value}`).then(res => { 
        this.loopData = res.data.data; 
      })
    }, // End querySearch

    getData(){
      axios.get(`potential.customers`)
        .then(res => {
          this.loopData  = res.data.data
          this.loopCases = res.data.cases
        })
    
    },

    async updateStatus () {
      const formData = {
        row     : event.target.getAttribute('data-id'),
        status  : event.target.value,
        //column : "delivery_cases",
        //table  : "order",
      }
      const response = await axios.post("status.potential.customers", formData)
      if(response) {
        this.getData();
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'تم تحديث البيانات بنجاح',
          showConfirmButton: false,
          timer: 2000
        });
      }
    },

    store  () {
      axios.post(`store.potential.customers`, {
        name        : this.name,
        email       : this.email,
        phone       : this.phone,
        city        : this.city,
        address     : this.address,
        note        : this.note,
      }).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });
    },


    // Get Data For Edit
    openEditModel (id, name, email, phone, city, address, note) {
      this.edit_id        = id
      this.edit_name      = name
      this.edit_email     = email
      this.edit_phone     = phone
      this.edit_city      = city
      this.edit_address   = address
      this.edit_note      = note
    }, // openEditModel
    
    update  () {
      axios.post(`update.potential.customers`, {
        id          : this.edit_id,
        name        : this.edit_name,
        email       : this.edit_email,
        phone       : this.edit_phone,
        city        : this.edit_city,
        address     : this.edit_address,
        note        : this.edit_note,
      }).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });
    },

    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post('destroy', {
          id   : id, 
          table: "potential_customers", 
        }).then(res => {
          this.getData();
        });
        Swal.fire(
          'حــــذف !',
          'تم حذف البيانات بنجاح',
          'success'
        )
      }
    })

    }, // onDelete







 }
}
</script>
   