
<template>
  <div class="container">
    <div class="card">
                    <!-- Notifications -->
                    <h5 class="card-header">الاشعـــــــارات</h5>

                    <div class="table-responsive">
                      <table class="table table-striped table-borderless border-bottom">
                        <thead>
                          <tr>
                            <th class="text-nowrap">#</th>
                            <th class="text-nowrap">الاجراء</th>
                            <th class="text-nowrap">حالة الطلب</th>
                            <th class="text-nowrap">الموظف</th>
                            <th class="text-nowrap">رقم المستند</th>
                            <th class="text-nowrap">الرسالة</th>
                            <th class="text-nowrap">التوقيت</th>
                          </tr>
                        </thead>
                        <tbody>

                          <tr v-for="(item, index) in loopData" :key="index">
                            <td class="text-nowrap">{{ index + 1 }}</td>
                            <td class="text-nowrap">{{ item.category }}</td>
                            <td class="text-nowrap">
                              <span v-if="item.status == 'قيد المراجعة'" class="badge bg-label-secondary" style="color: #000 !important">{{ item.status }}</span>
                              <span v-if="item.status == 'جديد'" class="badge bg-label-secondary" style="color: #000 !important">{{ item.status }}</span>
                              <span v-if="item.status == 'مرفوض'" class="badge bg-label-danger" style="color: #000 !important">{{ item.status }}</span>
                              <span v-if="item.status == 'تم الاعتماد'" class="badge bg-label-success" style="color: #000 !important">{{ item.status }}</span>
                            </td>
                            <td class="text-nowrap">{{ item.username }}</td>
                            <td class="text-nowrap">{{ item.document_number }}</td>
                            <td class="text-nowrap">{{ item.message }}</td>
                            <td class="text-nowrap">{{ item.time }}</td>

                          </tr>

                        </tbody>
                      </table>
                    </div>

                    <!-- /Notifications -->
                  </div>
  </div> <!-- container -->   
</template>


<script>
import axios from 'axios'

 export default {
  name: "Member",

  data() {
    return {
      loopData: [],
      // Create Acount
      name: "",
      email: "",
      password: "",
      // Edit Acount
      editID: "",
      editName: "",
      editEmail: "",
      editPassword: "",
    }
  },

  mounted () {
      window.document.title = "الاشعـــــــارات";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/login';
      }
      // Refresh the page

      
      this.getData();

      
      if (!localStorage.getItem("reload")) {
        
        setInterval(() => {
          location.reload();
          localStorage.setItem("reload", true)
        }, 100);
        
      }
      
  }, // mounted

  methods : {
    getData(){
      axios.get(`notifications`)
        .then(res => {
            this.loopData = res.data.data
        })
                
    },







    onDelete(id) {
      swal({

        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            axios.post("http://127.0.0.1:8000/back-end/api/store.food", {
              id   : id, 
              table: "users", 
            }).then(res => {
                this.getData();
            });
              this.$swal.fire('حــــذف !','تم حذف البيانات بنجاح','success')}
        })
    }, // onDelete






 }
}
</script>
   