
<template>

  <div class="container">
    <div class="card">
      <div class="card-datatable table-responsive pt-0">
        <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
          <div class="card-header flex-column flex-md-row">

          <div class="dt-action-buttons row text-end pt-3 pt-md-0">
            <div class="head-label col-md-6">
              <h5 style="text-align: right;" class="card-title mb-0">الاسئلـــــة</h5>
            </div>
            <div class="head-label col-md-4">
              <div class="form-floating form-floating-outline mb-4">
                <input @keyup="onSearch()" class="form-control" type="text" placeholder="Materio" id="html5-text-input">
                <label for="html5-text-input">اخبرنا عن ماذا تبحث</label>
              </div>
            </div>
            <div class="dt-buttons  col-md-2"> 
              <button class="dt-button create-new btn btn-primary" 
                      tabindex="0" 
                      data-toggle="modal" 
                      data-target="#newModal" 
                      aria-controls="DataTables_Table_0" 
                      type="button">
                      <span>
                        <i class="mdi mdi-plus me-sm-1"></i> 
                        <span class="d-none d-sm-inline-block">اضافـــــة</span>
                      </span>
              </button>
            </div>
          </div>
        </div>
        <table class="datatables-basic table table-bordered dataTable no-footer dtr-column table-hover">
          <thead class="table-light">
              <tr>
                <th>#</th>
                <th>الســـؤال</th>
                <th>تحكم</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :key="item.name">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.name  }}  </td>
                <td>
                  <a class="btn-action" @click="onDelete(item.id)"> 
                    <i class="far fa-trash-alt"></i> 
                  </a>
                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#EditModal" 
                      @click="openEditModel(item.id, item.name)"> 
                      <i class="fas fa-pencil-alt"></i> 
                  </a>
                </td>
              </tr>
              <tr v-if="loopData.length == 0" >
                <td class="text-center">ناسف لا توجد نتائج</td>
              </tr>
            </tbody>
          </table>
          </div>
        </div>
    </div>



    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content"  enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">الســـؤال </label>
                  <div class="col-sm-9">
                    <input type="text" v-model="name" class="form-control">
                  </div>
              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Create Row -->



    <!-- Update Row -->
    <div class="modal fade" id="EditModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content" enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> تحديث البيانات </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">
              <input type="hidden"  v-model="edit_id" >
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">الســـؤال </label>
                  <div class="col-sm-9">
                    <input type="text" v-model="edit_name" class="form-control">
                  </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Create Row -->






  </div> <!-- container -->   
</template>





<script>
import axios from 'axios'
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://furncn.com/back-end/api";
}
 export default {
  name: "foods-s",

  data() {
    return {
      // Validation
      loopData                : [],
      acount_type             : localStorage.getItem('acount_type'),
      user_id                 : localStorage.getItem('user_id'),

      //Add Row
      name         : "",

      // Edit Row
      edit_id: null,
      edit_name    : "",
      
    }
  },

  mounted () {
      window.document.title = "الاسئلــــــــة";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
  }, // mounted

  methods : {

    onSearch (value) {
      axios.get(`/questions` + "?search=" + event.target.value)
      .then(res => { this.loopData = res.data })
    }, // End onSearch

    getData(){ 
      axios.get(`/questions`).then(res => {this.loopData = res.data}) 
    },


    store  () {
      axios.post(`/store.questions`, {
        name   : this.name,
      }).then(res => {
        this.name = " "
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اضافة الســـؤال بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });

    },


    // Get Data For Edit
    openEditModel (id, name) {
      this.edit_id     = id,
      this.edit_name   = name
    }, // openEditModel


     

    update () {
      axios.post(`update.questions`, {
        id     : this.edit_id, 
        name   : this.edit_name,
      }).then(res => {
        this.getData();
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 2000
          })
      });
    },



    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`destroy`, {
          id   : id, 
          table: "questions", 
        }).then(res => {
          this.getData();
        });
        Swal.fire(
          'حــــذف !',
          'تم حذف البيانات بنجاح',
          'success'
        )
      }
    })

    }, // onDelete







 }
}
</script>
   