<template>
          <div class="mb-4">
            <div class="card">
              <div class="card-body">
                <small class="card-text text-uppercase badge bg-label-success">مســــار الطلب</small>
                <br><br><br>
                <ul class="timeline card-timeline mb-0">
                  <li v-for="item in loopNotes" :key="item"  class="timeline-item timeline-item-transparent">
                    <span class="timeline-point timeline-point-primary"></span>
                    <div class="timeline-header mb-1">
                      <h6 style="margin-top: -9px;" class="mb-3">{{ item.status }}</h6>
                    </div>
                    <p>{{ item.text }}</p>
   
                  </li>
                </ul>
              </div>
            </div><br>
            <div class="card">
              <form class="card-body"  @submit.prevent="" @submit="storeNote()" method="POST"  enctype="multipart/form-data">
                <small class="card-text text-uppercase badge bg-label-success"> اضافة مســــار جديدة</small>
                <br><br>
                <div class="form-floating form-floating-outline">
                  <input type="text" v-model="status" class="form-control" id="floatingInput" placeholder="مسار التحديث" aria-describedby="floatingInputHelp">
                  <label for="floatingInput">نوع التحديث</label>
                </div>
                <br>
                <div class="form-floating form-floating-outline mb-4">
                    <textarea v-model="note" class="form-control h-px-100" id="exampleFormControlTextarea1" placeholder=""></textarea>
                    <label for="exampleFormControlTextarea1">ملاحظات</label>
                </div>

                <br>
                <div v-if="TaskMessageSuccess == true">
                  <div class="bs-toast toast fade show" style="width: auto !important;" role="alert" aria-live="assertive" aria-atomic="true">
                    <div class="toast-header">
                      <i class="mdi mdi-check-circle text-success me-2"></i>
                      <div class="me-auto fw-medium">رسالة تاكيد</div>
                      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                    </div>
                    <div class="toast-body">تم اضافة المهمة بنجاح</div>
                  </div> <br>
                </div> <!--end Task Message-->
                <button type="submit" class="btn rounded-pill btn-primary waves-effect waves-light" style="width: 100%;">
                  <span class="tf-icons mdi mdi-checkbox-marked-circle-outline me-1"></span>
                  اضافة ملاحظـــــــة 
                </button>
              </form>
            </div>
          </div>
</template>


<script>



import axios from 'axios'

export default {
    data () {
        return {
            TaskMessageSuccess : false,
            updateTaskMessageSuccess : false,

            loopNotes : [],

            note    : '',
            status  : '',

            edit_note    : '',
            edit_status  : '',

        }
    },

    mounted () {
        this.getData();
    }, // mounted

    
  methods : {

    async getData(){
      const response = await axios.get(`services.form.details/${this.$route.params.id}`)
        .then(res => {
          this.loopNotes      = res.data.notes;
        })      
    },


    storeNote  () {
      axios.post(`store.note`, {
        order        : this.$route.params.id,
        note         : this.note,
        status       : this.status,
      }).then(res => {
          this.TaskMessageSuccess = true,
          this.getData();
      }); 
    },

    updateTaskModel(id, note) {
      this.edit_id         = id
      this.edit_note       = note
      this.edit_status     = status
    },

    updateNote  () {
      axios.post(`update.note`, {
        id           : this.edit_id,
        note         : this.note,
        status       : this.status,
      }).then(res => {
          this.updateTaskMessageSuccess = true,
          this.getData();
      }); 
    },











onDelete(id) {
  Swal.fire({
  title: 'رسالة تحذيرية',
  text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: 'قم بالحذف'
}).then((result) => {
  if (result.isConfirmed) {
    axios.post("/destroy", { id : id, table: "notes", 
  }).then(res => {
      this.getData();
  });
  Swal.fire(
      'حــــذف !',
      'تم حذف البيانات بنجاح',
      'success'
  )
  }
})

}, // onDelete





}

} // export default

</script>
