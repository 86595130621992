
<template>
  <div class="container">

    <form class="card card-filter" @submit.prevent="" @submit="filters()">
      <div class="row justify-content-center filter">
        <div class="col-md-2">
          <div class="form-floating form-floating-outline mb-4">
              <input class="form-control" type="date" placeholder="Materio" v-model="filter.start" id="html5-text-input">
              <label for="html5-text-input">بداية</label>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-floating form-floating-outline mb-4">
              <input class="form-control" type="date" placeholder="Materio" v-model="filter.end" id="html5-text-input">
              <label for="html5-text-input">نهايـــــة</label>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-floating form-floating-outline mb-4">
            <select  class="form-control" v-model="filter.month">
              <option value="01">يناير</option>
              <option value="02">فبراير</option>
              <option value="03">مارس</option>
              <option value="04">أبريل</option>
              <option value="05">مايو</option>
              <option value="06">يونيو</option>
              <option value="07">يوليو</option>
              <option value="08">أغسطس</option>
              <option value="09">سبتمبر</option>
              <option value="10">أكتوبر</option>
              <option value="11">نوفمبر</option>
              <option value="12">ديسمبر</option>
            </select>
            <label for="html5-text-input">الشهـــــر</label>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-floating form-floating-outline">
            <select class="form-control" v-model="filter.status">
              <option value=""></option>
              <option value="قيد الانتظار">قيد الانتظار</option>
              <option value="قيد التنفيذ">قيد التنفيذ</option>
              <option value="تم الالغاء">تم الالغاء</option>
              <option value="تم الانتهاء">تم الانتهاء</option>
            </select>
            <label for="floatingInput">الحالـــة</label>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-floating form-floating-outline">
            <select  class="form-control" v-model="filter.employee">
                <option value=""></option>
                <option v-for="item in loopEmployee" :key="item" :value="item.id">{{ item.name }}</option>
            </select>
            <label for="html5-text-input">الموظـــف</label>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-floating form-floating-outline mb-4">
              <input class="form-control" type="text" placeholder="Materio" v-model="filter.search" id="html5-text-input">
              <label for="html5-text-input">اخبرنا عن ماذا تبحث</label>
          </div>
        </div>
        <div class="col-md-2">
          <button class="dt-button create-new btn btn-primary" 
                        style="width: 100%; padding: 12px;"
                        type="submit">
                        <span>
                          <span class="d-none d-sm-inline-block"> بحــــــث </span>
                        </span>
                </button>
        </div>
      </div>
    </form>
  <br>

    <div class="card">
      <div class="card-datatable table-responsive pt-0">
        <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
          <div class="card-header flex-column flex-md-row">

          <div class="dt-action-buttons row text-end pt-3 pt-md-0">
            <div class="head-label col-md-7">
              <h5 style="text-align: right;" class="card-title mb-0">المهمـــــات</h5>
            </div>
            <div class="dt-buttons  col-md-5"> 
              <button class="dt-button create-new btn btn-primary" 
                      tabindex="0" 
                      data-toggle="modal" 
                      data-target="#newModal" 
                      aria-controls="DataTables_Table_0" 
                      type="button">
                      <span>
                        <i class="mdi mdi-plus me-sm-1"></i> 
                        <span class="d-none d-sm-inline-block">اضافة</span>
                      </span>
              </button>
            </div>
          </div>
        </div>
        <table class="datatables-basic table table-bordered dataTable no-footer dtr-column table-hover">
          <thead class="table-light">
              <tr>
                <th>#</th>
                <th>رقم المستند</th>
                <th>المهمة</th>
                <th>الوصف</th>
                <th>تاريخ البدء</th>
                <th>تاريخ الانتهاء</th>
                <th>الموظـــف</th>
                <th>الحالة</th>
                <th>تحكم</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :key="item.name">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.id  }}  </td>
                <td> {{ item.title }}  </td>
                <td> {{ item.description }}  </td>
                <td> {{ item.start }}  </td>
                <td> {{ item.end }}  </td>
                <td> {{ item.author }}  </td>
                <td> 
                  <select class="form-control" @change="updateStatus(item.id)">
                    <option value="">{{ item.status }}</option>
                      <option value="قيد التنفيذ">قيد التنفيذ</option>
                      <option value="تم الالغاء">تم الالغاء</option>
                      <option value="تم الانتهاء">تم الانتهاء</option>
                  </select>
                  <span v-if="item.hr == 'old_administration'" class="badge bg-label-success">>تم ارسال الطلب للادارة الحالية</span>
                  <span v-if="item.hr == 'تم الاعتماد'" class="badge bg-label-success">{{ item.hr }} </span>
                  <span v-if="item.hr == 'رفض'" class="badge bg-label-danger">{{ item.hr }} </span>
                
                </td>
                <td>
                  <a class="btn-action" @click="onDelete(item.id)"> 
                    <i class="far fa-trash-alt"></i> 
                  </a>
                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#EditModal" 
                      @click="openEditModel(item.id, item.title, item.description, item.start, item.end, item.employee)"> 
                      <i class="fas fa-pencil-alt"></i> 
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          </div>
        </div>
    </div>


    
    <!-- اضافة البيانات -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content" enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة مهمــــــة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">
                <input type="hidden"  v-model="edit_task_id" >
                <div class="form-floating form-floating-outline">
                    <input type="text" v-model="title" class="form-control" id="floatingInput" placeholder="عنوان المهمة" aria-describedby="floatingInputHelp">
                    <label for="floatingInput">العنــــــــوان</label>
                </div>
                <br>
                <div class="form-floating form-floating-outline mb-4">
                    <textarea v-model="description" class="form-control h-px-100" id="exampleFormControlTextarea1" placeholder="وصف المهمــــة"></textarea>
                    <label for="exampleFormControlTextarea1">وصف المهمــــة</label>
                </div>
                <div class="row">
                  <div class="form-floating form-floating-outline col-md-6">
                      <input type="date" v-model="start" class="form-control" id="floatingInput" placeholder="عنوان المهمة" aria-describedby="floatingInputHelp">
                      <label for="floatingInput">تاريخ المهمة</label>
                  </div>
                  <div class="form-floating form-floating-outline col-md-6">
                      <input type="date" v-model="end" class="form-control" id="floatingInput" placeholder="عنوان المهمة" aria-describedby="floatingInputHelp">
                      <label for="floatingInput">انتهاء المهمة</label>
                  </div>
                </div>
                <br> 
                <div class="form-floating form-floating-outline">
                    <select class="form-control" v-model="employee">
                      <option v-for="item in loopEmployee" :key="item" :value="item.id">{{ item.name }}</option>
                    </select>
                    <label for="floatingInput">الموظـــف</label>
                </div>
                
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>
        </form>
      </div>
    </div>
    <!-- تحديث البيانات -->


    
    <!-- تحديث البيانات -->
    <div class="modal fade" id="EditModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content" enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> تحديث البيانات </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">
              <input type="hidden"  v-model="edit_id" >
              <div class="form-floating form-floating-outline">
                    <input type="text" v-model="edit_title" class="form-control" id="floatingInput" placeholder="عنوان المهمة" aria-describedby="floatingInputHelp">
                    <label for="floatingInput">العنــــــــوان</label>
                </div>
                <br>
                <div class="form-floating form-floating-outline mb-4">
                    <textarea v-model="edit_description" class="form-control h-px-100" id="exampleFormControlTextarea1" placeholder="وصف المهمــــة"></textarea>
                    <label for="exampleFormControlTextarea1">وصف المهمــــة</label>
                </div>
                <div class="row">
                  <div class="form-floating form-floating-outline col-md-6">
                      <input type="date" v-model="edit_start" class="form-control" id="floatingInput" placeholder="عنوان المهمة" aria-describedby="floatingInputHelp">
                      <label for="floatingInput">تاريخ المهمة</label>
                  </div>
                  <div class="form-floating form-floating-outline col-md-6">
                      <input type="date" v-model="edit_end" class="form-control" id="floatingInput" placeholder="عنوان المهمة" aria-describedby="floatingInputHelp">
                      <label for="floatingInput">انتهاء المهمة</label>
                  </div>
                </div>
                <br>
                  <div class="form-floating form-floating-outline">
                      <select class="form-control" v-model="edit_employee">
                        <option v-for="item in loopEmployee" :key="item" :value="item.id">{{ item.name }}</option>
                      </select>
                      <label for="floatingInput">الموظـــف</label>
                  </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>
        </form>
      </div>
    </div>
    <!-- تحديث البيانات -->

    
  </div> <!-- container -->   
</template>



<style>
tr .text-nowrap {
    white-space: normal !important;
    max-width: 300px;
}
</style>


<script>
import axios from 'axios'

 export default {
  name: "foods-s",

  data() {
    return {
      // Validation

      loopData: [],
      loopEmployee: [],
      user_id : localStorage.getItem("user_id"),
      acount_type : localStorage.getItem('acount_type'),

      user         : "",
      title        : "",
      description  : "",
      start        : "",
      end          : "",
      employee     : "",

      edit_id           : "",
      edit_user         : "",
      edit_title        : "",
      edit_description  : "",
      edit_start        : "",
      edit_end          : "",
      edit_employee     : "",


      filter : {
        start: '',
        end : '',
        month : '',
        employee : '',
        search : '',
      }


    }
  },

  mounted () {
      window.document.title = "المهمـــــات";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
  }, // mounted

  methods : {

    filters () {
      axios.get(`tasks`
      + "?employee=" + this.filter.employee 
      + "&start="  +   this.filter.start
      + "&end="    +   this.filter.end
      + "&month="  +   this.filter.month
      + "&status="  +  this.filter.status
      + "&search=" +   this.filter.search
      ) .then(res => { this.loopData = res.data.data; })
    }, // End filter

    updateStatus(id) {
      axios.post(`update.status.task`, {id: id, status : event.target.value}).then(res => {
        this.getData();
        Swal.fire({
          icon: 'success',
          title: 'تم اتمام العملية بنجاح',
          showConfirmButton: false,
          timer: 3000
        })
      });
    },

    getData(){
      axios.get(`tasks`)
        .then(res => {
          this.loopEmployee     = res.data.employee
          this.loopDirectManager   = res.data.direct_manager
          this.loopData         = res.data.data
        })
    
    },



    
    store () {
      axios.post(`store.tasks`, {
        user         : this.user,
        title        : this.title,
        description  : this.description,
        start        : this.start,
        end          : this.end,
        employee     : this.employee,
      }).then(res => {
        this.getData();
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'تم اضافة المهمـــــة بنجاح',
          showConfirmButton: false,
          timer: 2000
        })
      }); 
    },

    openEditModel (id, title, description, start, end, employee) {
      this.edit_id          = id
      this.edit_title       = title
      this.edit_description = description
      this.edit_start       = start
      this.edit_end         = end
      this.edit_employee    = employee
    },

    update() {
      axios.post(`update.tasks`, {
        id           : this.edit_id,
        title        : this.edit_title,
        description  : this.edit_description,
        start        : this.edit_start,
        end          : this.edit_end,
        employee     : this.edit_employee,
      }).then(res => {
          this.getData();
          Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'تم تحديث البيانات بنجاح',
          showConfirmButton: false,
          timer: 2000
        })
      }); 
    },


    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`destroy`, {id : id, table: "tasks"}).then(res => {
          this.getData();
        });
        Swal.fire(
          'حــــذف !',
          'تم حذف البيانات بنجاح',
          'success'
        )
      }
    })

    }, // onDelete







 }
}
</script>
   




