
<template>
<!-- K9)X^27Brxpn6%*@3CdM(_)Fu!#SG&5yf -->
  <div class="container">
    <div class="card mb-4">
      <div class="user-profile-header-banner">
        <img width="100%" src="../../assets/img/pages/header-dark.png" alt="Banner image" class="rounded-top">
      </div>
      <div class="user-profile-header d-flex flex-column flex-lg-row text-sm-start text-center mb-4">
        <!--
        <div class="flex-shrink-0 mt-n2 mx-lg-0 mx-auto">
          <img style="margin-top:-70px" src="../../assets/img/avatars/1.png" alt="user image" class="d-block h-auto ms-0 ms-sm-4 rounded user-profile-img">
        </div>
        -->

        <div class="flex-grow-1 mt-3 mt-lg-5">
          <div class="d-flex align-items-md-end align-items-sm-start align-items-center justify-content-md-between justify-content-start mx-4 flex-md-row flex-column gap-4">
            <div class="row">
              <div class="user-profile-info col-md-6">
              <h4>{{ company.name }}</h4>
              <ul class="list-inline mb-0 d-flex align-items-center flex-wrap justify-content-sm-start justify-content-center gap-2">
                <li class="list-inline-item">
                  <i class="mdi mdi-invert-colors me-1 mdi-20px"></i>
                  <span class="fw-medium">{{ company.email }}</span>
                </li>
                <li class="list-inline-item">
                  <i class="mdi mdi-map-marker-outline me-1 mdi-20px"></i>
                  <span class="fw-medium">{{ company.address }}</span>
                </li>
                <li class="list-inline-item">
                  <i class="mdi mdi-calendar-blank-outline me-1 mdi-20px"></i>
                  <span class="fw-medium">{{ company.branch }}</span>
                </li>
                <li class="list-inline-item">
                  <i class="mdi mdi-calendar-blank-outline me-1 mdi-20px"></i>
                  <span class="fw-medium">{{ company.specialization }}</span>
                </li>
                </ul>
              </div>

              <div class="col-md-6">
              <ul class="row">
                <li class="col-md-6 ">
                  <i class="mdi mdi-invert-colors me-1 mdi-20px"></i>
                  <span class="fw-medium"> أسم المنشأه : {{ singleCompany.company_name }}</span>
                </li>
                <li class="col-md-6">
                  <i class="mdi mdi-map-marker-outline me-1 mdi-20px"></i>
                  <span class="fw-medium">كود العميل : {{ singleCompany.client_code }}</span>
                </li>
                <li class="col-md-6">
                  <i class="mdi mdi-calendar-blank-outline me-1 mdi-20px"></i>
                  <span class="fw-medium">تاريخ  التفتيش	 : {{ singleCompany.date_report_issue }}</span>
                </li>
                <li class="col-md-6">
                  <i class="mdi mdi-calendar-blank-outline me-1 mdi-20px"></i>
                  <span class="fw-medium">اسم المفتش : {{ singleCompany.auditor_name }}</span>
                </li>
                <!--
                <li class="col-md-6">
                  <i class="mdi mdi-invert-colors me-1 mdi-20px"></i>
                  <span class="fw-medium">كود الادوات : {{ singleCompany.email }}</span>
                </li>
                -->
                <li class="col-md-6">
                  <i class="mdi mdi-map-marker-outline me-1 mdi-20px"></i>
                  <span class="fw-medium">عنوان الموقع : {{ singleCompany.site_name }}</span>
                </li>
                <li class="col-md-6">
                  <i class="mdi mdi-calendar-blank-outline me-1 mdi-20px"></i>
                  <span class="fw-medium">مدير الموقع	 : {{ singleCompany.site_manager }}</span>
                </li>
              </ul>
            </div>

            </div><!-- row-->



            </div>
          </div>
        </div>
    </div>

    <div class="demo-inline-spacing">
        <!--
        <button class="btn rounded-pill btn-primary waves-effect waves-light" tabindex="0" data-toggle="modal" data-target="#createTask" aria-controls="DataTables_Table_0" type="button">
          <i class="mdi mdi-plus me-sm-1"></i>
          <span class="d-none d-sm-inline-block">
            اضافة مهمــــــة
          </span>
        </button>
        -->

        <button class="btn rounded-pill btn-primary waves-effect waves-light" tabindex="0" data-toggle="modal" data-target="#uploadModals" aria-controls="DataTables_Table_0" type="button">
          <i class="mdi mdi-plus me-sm-1"></i>
          <span class="d-none d-sm-inline-block">
            اضافة اجراء
          </span>
        </button>

        <br><br>
    </div>


    <div class="card mb-4">
                    <div class="card-header p-0">
                      <div class="nav-align-top">
                        <ul class="nav nav-tabs nav-fill" role="tablist">
                          <!--
                          <li class="nav-item" role="presentation">
                            <button type="button" class="nav-link waves-effect active" role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-tasks" aria-controls="navs-justified-tasks" aria-selected="false" tabindex="-1">
                              <i class="tf-icons mdi mdi-message-text-outline me-1"></i> المهمـــــات                               
                            </button>
                          </li>
                          -->
                          <li class="nav-item" role="presentation">
                            <button type="button" class="nav-link waves-effect" role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-home" aria-controls="navs-justified-home" aria-selected="true">
                              <i class="tf-icons mdi mdi-message-text-outline me-1"></i> الاسئلـــــة
                            </button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button type="button" class="nav-link waves-effect" role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-profile" aria-controls="navs-justified-profile" aria-selected="false" tabindex="-1">
                              <i class="tf-icons mdi mdi-message-text-outline me-1"></i> التقارير
                            </button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button type="button" class="nav-link waves-effect" role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-messages" aria-controls="navs-justified-messages" aria-selected="false" tabindex="-1">
                              <i class="tf-icons mdi mdi-message-text-outline me-1"></i> حالات عدم المطابقة
                            </button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button type="button" class="nav-link waves-effect" role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-messages2" aria-controls="navs-justified-messages" aria-selected="false" tabindex="-1">
                              <i class="tf-icons mdi mdi-message-text-outline me-1"></i> الإجراءات التصحيحية 
                            </button>
                          </li>
                        <span class="tab-slider" style="left: 0px; width: 226.523px; bottom: 0px;"></span></ul>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="tab-content p-0">

                        <!------------- Tasks --------------
                        <div class="tab-pane fade active show" id="navs-justified-tasks" role="tabpanel">
                          <div class="card-datatable table-responsive pt-0">
                            <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
                              <Tasks />
                            </div>
                          </div>
                        </div>
                        ------------- Tasks ---------------->

                        <div class="tab-pane fade" id="navs-justified-home" role="tabpanel">
                          <div class="card-datatable table-responsive pt-0">
                            <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
                              <AnswersQuestions />
                            </div>
                          </div>
                        </div><!------------- ---------------->
                        <div class="tab-pane fade" id="navs-justified-profile" role="tabpanel">
                          <iframe :src="`https://erp.criteria.com.sa/#/pdf/${this.$route.params.id}`" width="100%" height="1000px"></iframe>
                        </div><!------------- ---------------->
                        <div class="tab-pane fade" id="navs-justified-messages" role="tabpanel">
                          <table class="datatables-basic table table-bordered dataTable no-footer dtr-column table-hover">
                            <thead class="table-light">
                                <tr>
                                  <th>#</th>
                                  <th>شرح الحالــــة</th>
                                  <th>التاريخ</th>
                                  <th>الملف</th>
                                </tr>
                              </thead>
                              <tbody class="table-border-bottom-0">
                                <tr v-for="(item, index) in loopConformities" :key="item.name">
                                  <td> {{ index + 1  }}  </td>
                                  <td> {{ item.note  }}  </td>
                                  <!--
                                  <td> 
                                    <span v-if="item.status == 'تاكيد'" class="badge rounded-pill badge-center  bg-label-success ">تم التاكيد</span>
                                    <span v-if="item.status == 'رفض'" class="badge rounded-pill badge-center  bg-label-danger ">مرفوض</span>
                                  </td>
                                -->
                                  <td> {{ item.time  }}  </td>
                                  <td>
                                    <a class="btn-action" target="_blank" :href="'https://erp.criteria.com.sa/back-end/public/checklist_file/'+item.file">
                                        <i class="fas fa-arrow-alt-to-bottom"></i>
                                    </a>
                                  </td>
                                  <!--
                                  <td>
                                    <a class="btn-action" @click="onDelete(item.id)"> 
                                      <i class="far fa-trash-alt"></i> 
                                    </a>
                                    <a class="btn-action" :href="'https://furncn.com/back-end/public/file_time/'+item.file">
                                        <i class="fas fa-arrow-alt-to-bottom"></i>
                                    </a>
                                    <a  class="btn-action" 
                                        data-toggle="modal" 
                                        data-target="#editUploadFile" 
                                        @click="openEditFile(item.id, item.note)"> 
                                        <i class="fas fa-pencil-alt"></i> 
                                    </a>
                                    <a  class="btn-action" 
                                        data-toggle="modal" 
                                        data-target="#updateStatus" 
                                        @click="editUpdateStatus(item.id, item.status, item.reason_refuse, 'checklist_files')"> 
                                        <i class="far fa-shield-check"></i>
                                    </a>
                                  </td>
                                  -->
                                </tr>
                              </tbody>
                            </table>
                        </div>
                        <div class="tab-pane fade" id="navs-justified-messages2" role="tabpanel">
                          <table class="datatables-basic table table-bordered dataTable no-footer dtr-column table-hover">
                            <thead class="table-light">
                                <tr>
                                  <th>#</th>
                                  <!--<th>ملاحظات</th>-->
                                  <th>شرح الحالــــة</th>
                                  <th>التاريخ</th>
                                  <th>الملف</th>
                                </tr>
                              </thead>
                              <tbody class="table-border-bottom-0">
                                <tr v-for="(item, index) in loopCorrective" :key="item.name">
                                  <td> {{ index + 1  }}  </td>
                                  <td> {{ item.note  }}  </td>
                                  <!--
                                  <td> 
                                    <span v-if="item.status == 'تاكيد'" class="badge rounded-pill badge-center  bg-label-success ">تم التاكيد</span>
                                    <span v-if="item.status == 'رفض'" class="badge rounded-pill badge-center  bg-label-danger ">مرفوض</span>
                                  </td>
                                -->
                                  <td> {{ item.time  }}  </td>
                                  <td>
                                    <a class="btn-action" target="_blank" :href="'https://erp.criteria.com.sa/back-end/public/checklist_file/'+item.file">
                                        <i class="fas fa-arrow-alt-to-bottom"></i>
                                    </a>
                                  </td>
                                  <!--
                                  <td>
                                    <a class="btn-action" @click="onDelete(item.id)"> 
                                      <i class="far fa-trash-alt"></i> 
                                    </a>
                                    <a class="btn-action" :href="'https://furncn.com/back-end/public/file_time/'+item.file">
                                        <i class="fas fa-arrow-alt-to-bottom"></i>
                                    </a>
                                    <a  class="btn-action" 
                                        data-toggle="modal" 
                                        data-target="#editUploadFile" 
                                        @click="openEditFile(item.id, item.note)"> 
                                        <i class="fas fa-pencil-alt"></i> 
                                    </a>                           
                                    <a  class="btn-action" 
                                        data-toggle="modal" 
                                        data-target="#updateStatus" 
                                        @click="editUpdateStatus(item.id, item.status, item.reason_refuse, 'checklist_files')"> 
                                        <i class="far fa-shield-check"></i>
                                    </a>
                                  </td>
                                  -->
                                </tr>
                              </tbody>
                            </table>
                        </div>
                      </div>
                    </div>
                  </div>





    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content"  enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">الســـؤال </label>
                  <div class="col-sm-9">
                    <select v-model="question" class="form-control">
                      <option value=""></option>
                      <option v-for="item in loopQuestions" :key="item" :value="item.id">{{ item.name }}</option>
                    </select>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">الاجابة</label>
                  <div class="col-sm-9">
                    <textarea type="text" v-model="answer" class="form-control" placeholder="برجاء الاجابة علي  السؤال" cols="3" rows="3"></textarea>
                  </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Create Row -->



    <!-- Update Row -->
    <div class="modal fade" id="EditModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content" enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> تحديث البيانات </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">
              <input type="hidden" v-model="edit_id">
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">الســـؤال </label>
                  <div class="col-sm-9">
                    <select v-model="edit_question" class="form-control">
                      <option value=""></option>
                      <option v-for="item in loopQuestions" :key="item" :value="item.id">{{ item.name }}</option>
                    </select>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">الاجابة</label>
                  <div class="col-sm-9">
                    <textarea type="text" v-model="edit_answer" class="form-control" placeholder="برجاء الاجابة علي  السؤال" cols="3" rows="3"></textarea>
                  </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Create Row -->



    <!-- اضافة التقارير -->
    <div class="modal fade" id="uploadModals" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="storeFile()" method="POST" class="modal-content"  enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">
              <div class="form-floating form-floating-outline mb-4">
                <select v-model="procedure" class="form-control" >
                  <!--<option value="report">تقرير</option>-->
                  <option value="non_conformities">حالات عدم المطابقة</option>
                  <option value="corrective_actions">لإجراءات التصحيحية</option>
                </select>
                  <label for="html5-date-input">الاجــــــــراء</label>
              </div>
              <div class="form-floating form-floating-outline mb-4">
                  <input type="file" @change="uploadFiles" accept=".png,.jpg,.jpeg.doc,.docx,.pdf,.txt,.xlsx,.rar,.zip" class="form-control" placeholder="" />
                  <label for="html5-date-input">الملف المرفق</label>
              </div>
              <div class="form-floating form-floating-outline mb-4">
                  <textarea class="form-control" placeholder="" cols="30" rows="10" v-model="note"></textarea>
                  <label for="html5-date-input">شرح الحالــــة</label>
              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>
        </form>
      </div>
    </div>
    <!-- اضافة التقارير -->

    <!-- تعديل التقارير  -->
    <div class="modal fade" id="editUploadFile" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="updateFile()" method="POST" class="modal-content"  enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> تحديث البيانات </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">

              <input type="hidden" v-model="edit_file_id" />
              <div class="form-floating form-floating-outline mb-4">
                  <input type="file" @change="uploadFiles" accept=".png,.jpg,.jpeg.doc,.docx,.pdf,.txt,.xlsx,.rar,.zip" class="form-control" placeholder="" />
                  <label for="html5-date-input">الملف المرفق</label>
              </div>
              <div class="form-floating form-floating-outline mb-4">
                  <textarea class="form-control" placeholder="" cols="30" rows="10" v-model="edit_note"></textarea>
                  <label for="html5-date-input">شرح الحالــــة</label>
              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>
        </form>
      </div>
    </div>
    <!-- تعديل التقارير  -->


    <!-- تعديل التقارير  -->
    <div class="modal fade" id="updateStatus" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="updateStatus()" method="POST" class="modal-content"  enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> تحديث البيانات </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">

              <input type="hidden" v-model="edit_file_id" />
              <div class="form-floating form-floating-outline mb-4">
                  <select class="form-control" v-model="status.status">
                    <option value="تاكيد">تاكيد</option>
                    <option value="رفض">رفض</option>
                  </select>
                  <label for="html5-date-input">شرح الحالــــة</label>
              </div>
              <div class="form-floating form-floating-outline mb-4">
                  <textarea class="form-control" placeholder="" cols="30" rows="10" v-model="status.reason_refuse"></textarea>
                  <label for="html5-date-input">ملاحظــــــــات</label>
              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>
        </form>
      </div>
    </div>
    <!-- تعديل التقارير  -->


    

    
  </div> <!-- container -->   
</template>



<style>
ul li {
    padding: 5px;
    list-style: none;
}

ul {
  padding-right: 0px;
  padding: 0px;
}
</style>

<script>
import jQuery from "jquery";

import axios from 'axios'
import AnswersQuestions from './AnswersQuestions.vue'

 export default {
  name: "Answers",
  components: {
    AnswersQuestions,
},
  data() {
    return {
      // Validation
      singleCompany           : [],

      loopQuestions           : [],
      loopQuestionsCategory   : [],

      loopData                : [],
      company                 : [],

      loopReport              : [],
      loopConformities        : [],
      loopCorrective          : [],

      statisticsTasks: 0,

      acount_type             : localStorage.getItem('acount_type'),
      user_id                 : localStorage.getItem('user_id'),

      //Add Row
      procedure: "",
      question : "",
      answer   : "",

      uploadFile : {
        file: null,
        imageUrl: null,
        getPicture: null
      }, 

      // Edit Row
      edit_id       : null,
      edit_question : "",
      edit_answer   : "",

      edit_file_id : "",
      edit_note    : "",

      status : {
        id            : '', 
        status        : '', 
        reason_refuse : '',
        table         : ''
      }
      
    }
  },

  mounted () {
      window.document.title = "الاسئلــــــــة";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
  }, // mounted

  methods : {

    getData(){ 
      axios.get(`/answers.questions/${this.$route.params.id}`).then(res => {
        this.loopData      = res.data.answers
        this.singleCompany = res.data.singleCompany

        this.loopQuestions = res.data.questions
        this.loopQuestionsCategory = res.data.QuestionsCategory

        this.company       = res.data.company

        this.loopReport          = res.data.report
        this.loopConformities    = res.data.conformities
        this.loopCorrective      = res.data.corrective

        this.statisticsTasks     = res.data.statisticsTasks
      }) 
    },

    uploadFiles(e){
      this.uploadFile.file = e.target.files[0];
      //this.uploadFilePicture.imageUrl = URL.createObjectURL(e.target.files[0])
      //this.uploadFilePicture.getPicture = e.target.files[0].name;
    },

    storeFile () {
      const formData = new FormData();
      formData.append("procedure"             , this.procedure);
      formData.append("file"                  , this.uploadFile.file);
      formData.append("note"                  , this.note);
      formData.append("checklist_id"          , this.$route.params.id);
      axios.post(`upload.file.checklist`, formData).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });
    },
    
    updateFile () {
      const formData = new FormData();
      formData.append("id"          , this.edit_file_id);
      formData.append("file"        , this.uploadFile.file);
      formData.append("note"        , this.edit_note);
      axios.post(`update.file.checklist`, formData).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });
    },

    store  () {
      axios.post(`/store.answers.questions`, {
        question      : this.question,
        answer        : this.answer,
        checklist_id  : this.$route.params.id,
      }).then(res => {
        this.name = " "
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اضافة الســـؤال بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });

    },


    // Get Data For Edit
    openEditModel (id, question, answer) {
      this.edit_id             = id,
      this.edit_question       = question
      this.edit_answer         = answer
    }, // openEditModel


    openEditFile(id, note) {
      this.edit_file_id         = id
      this.edit_note            = note
    },

    editUpdateStatus(id,  status, reason_refuse, table) {
      this.status.id            = id
      this.status.status        = status
      this.status.reason_refuse = reason_refuse
      this.status.table         = table
    },
     

    updateStatus () {
      axios.post(`update.checklist.files.status`, {
        id                : this.status.id, 
        status            : this.status.status,
        table             : this.status.table,
        reason_refuse     : this.status.reason_refuse,
      }).then(res => {
        this.getData();
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 2000
          })
      });
    },

    update () {
      axios.post(`update.questions`, {
        id            : this.edit_id, 
        question      : this.edit_question,
        answer        : this.edit_answer,
      }).then(res => {
        this.getData();
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 2000
          })
      });
    },



    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`destroy`, {
          id   : id, 
          table: "answer_questions", 
        }).then(res => {
          this.getData();
        });
        Swal.fire(
          'حــــذف !',
          'تم حذف البيانات بنجاح',
          'success'
        )
      }
    })

    }, // onDelete







 }
}
</script>
   