
<template>
  <div class="container">
    <br><br>
    <!-- Card Table -->
    <div class="card">
      <h5 class="card-header">تقييم الموظفين</h5>
        <button type="button" 
                class="btn btn-primary btn-new btn-xs" 
                data-toggle="modal" 
                data-target="#newModal" 
                style="float: left;"
                >اضافة
        </button>
        <div class="table-responsive text-nowrap">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>رقم المستند</th>
                <th>الموظف</th>
                <th>تقييم الاداء</th>
                <th>تقييم الحضور والانصراف</th>
                <th>التعاون مع زملائه بالعمل </th>
                <th>تقييمات اخرى</th>
                <th>التوصيات</th>
                <th>التوقيت</th>
                <th> الموارد البشريه </th>
                <th>المدير العام </th>
                <th>تحكم</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :key="item.name">
                <td> {{ index + 1  }} </td>
                <td> {{ item.id  }}  </td>
                <td> 
                  <span v-for="employee in loopEmployee" :key="employee">
                    <span v-if="employee.id == item.muazaf">{{ employee.name }}</span>
                  </span>   
                </td>
                <td> 
                  <span v-for="scores in loopScores" :key="scores">
                    <span v-if="scores.id == item.employee_evaluation">{{ scores.name }}</span>
                  </span>   
                </td>
                <td> 
                  <span v-for="scores in loopScores" :key="scores">
                    <span v-if="scores.id == item.absence">{{ scores.name }}</span>
                  </span>   
                </td>
                <td> 
                  <span v-for="scores in loopScores" :key="scores">
                    <span v-if="scores.id == item.cooperating_workers">{{ scores.name }}</span>
                  </span>   
                </td>
                <td> {{ item.other_reviews }}  </td>
                <td> {{ item.recommendations }}  </td>
                <td> {{ item.time }}  </td>
                <td> 
                  <select v-if="acount_type == 'hr'" class="form-control form-select" @change="acceptRow(item.id, item.muazaf, 'hr')">
                    <option value=""></option>
                    <option value="تم الاعتماد">تم الاعتماد</option>
                    <option value="رفض">رفض</option>
                  </select>
                  <span v-if="item.hr == 'تم المراجعه'" class="badge bg-label-success">{{ item.hr }} </span>
                  <span v-if="item.hr == 'تم الاعتماد'" class="badge bg-label-success">{{ item.hr }} </span>
                  <span v-if="item.hr == 'رفض'" class="badge bg-label-danger">{{ item.hr }} </span>                 
                </td>
                <td> 
                  <select v-if="acount_type == 'general_director'" class="form-control form-select" @change="acceptRow(item.id, item.muazaf, 'general_director')">
                    <option value=""></option>
                    <option value="تم الاعتماد">تم الاعتماد</option>
                    <option value="رفض">رفض</option>
                  </select>
                  <span v-if="item.general_director == 'تم الاعتماد'" class="badge bg-label-success">{{ item.general_director }} </span>
                  <span v-if="item.general_director == 'رفض'" class="badge bg-label-danger">{{ item.general_director }} </span>                  
                </td>
                <td>
                  <a class="btn-action" @click="onDelete(item.id)"> 
                    <i class="far fa-trash-alt"></i> 
                  </a>
                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#EditModal" 
                      @click="openEditModel(item.id, author, item.muazaf, item.employee_evaluation, item.absence, item.cooperating_workers, item.other_reviews, item.recommendations, item.employee_department_manager, item.hr, item.general_director)"> 
                      <i class="fas fa-pencil-alt"></i> 
                  </a>
                </td>
              </tr>
            </tbody>

          </table>
        </div>
    </div>
    <!-- Card Table -->



    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content"  enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">


              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> الموظف </label>
                  <div class="col-sm-10">
                    <select v-model="muazaf" class="form-control form-select">
                      <option 
                              v-for="item in loopEmployee" 
                              :key="item" 
                              :value="item.id">
                              {{ item.name }}
                            </option>
                    </select>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> تقييم الاداء </label>
                  <div class="col-sm-10">
                    <div class="form-check" v-for="item in loopScores" :key="item">
                        <input  name="default-radio-1" 
                                class="form-check-input" 
                                type="radio" 
                                v-model="employee_evaluation"
                                :value="item.id" 
                                id="defaultRadio_11">
                        <label class="form-check-label" for="defaultRadio2ف"> {{ item.name }} </label>
                    </div>                 
                  </div>
              </div>



              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> الحضور والانصراف </label>
                  <div class="col-sm-10">
                      <div class="form-check" v-for="item in loopScores" :key="item">
                          <input  name="default-radio-12" 
                                  class="form-check-input" 
                                  type="radio" 
                                  v-model="absence"
                                  :value="item.id" 
                                  id="defaultRadio_1">
                          <label class="form-check-label" for="defaultRadi"> {{ item.name }} </label>
                      </div>                 
                  </div>
              </div>

              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> التعاون مع زملائه بالعمل  </label>
                  <div class="col-sm-10">
                    <div class="form-check" v-for="item in loopScores" :key="item">
                        <input  name="default-radio-13" 
                                class="form-check-input" 
                                type="radio" 
                                v-model="cooperating_workers"
                                :value="item.id" 
                                id="defaultRadio_1">
                        <label class="form-check-label" for="defaultRa"> {{ item.name }} </label>
                    </div>                 
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> تقييمات اخرى  </label>
                  <div class="col-sm-10">
                    <textarea v-model="other_reviews" class="form-control" cols="3" rows="3"></textarea>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> التوصيات  </label>
                  <div class="col-sm-10">
                    <textarea v-model="recommendations" class="form-control" cols="3" rows="3"></textarea>
                  </div>
              </div>

              <li v-for="error in formValdatorCreatePlanss" :key="error"> {{ error  }} </li>


            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->



    <!-- Edit Row -->
    <div class="modal fade" id="EditModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content" enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> تحديث البيانات </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          
          <div class="modal-body">
            <div class="card-body">

              <input type="hidden" v-model="edit_id">
              <div class="row mb-3" v-if="author == user_id">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> الموظف </label>
                  <div class="col-sm-10">
                    <select v-model="edit_muazaf" class="form-control form-select">
                      <option 
                              v-for="item in loopEmployee" 
                              :key="item" 
                              :value="item.id">
                              {{ item.name }}
                            </option>
                    </select>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> تقييم الاداء </label>
                  <div class="col-sm-10">
                    <div class="form-check" v-for="item in loopScores" :key="item">
                        <input  name="default-radio-1" 
                                class="form-check-input" 
                                type="radio" 
                                v-model="edit_employee_evaluation"
                                :value="item.id" 
                                id="defaultRadio_11">
                        <label class="form-check-label" for="defaultRadio2ف"> {{ item.name }} </label>
                    </div>                 
                  </div>
              </div>



              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> الحضور والانصراف </label>
                  <div class="col-sm-10">
                      <div class="form-check" v-for="item in loopScores" :key="item">
                          <input  name="default-radio-12" 
                                  class="form-check-input" 
                                  type="radio" 
                                  v-model="edit_absence"
                                  :value="item.id" 
                                  id="defaultRadio_1">
                          <label class="form-check-label" for="defaultRadi"> {{ item.name }} </label>
                      </div>                 
                  </div>
              </div>

              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> التعاون مع زملائه بالعمل  </label>
                  <div class="col-sm-10">
                    <div class="form-check" v-for="item in loopScores" :key="item">
                        <input  name="default-radio-13" 
                                class="form-check-input" 
                                type="radio" 
                                v-model="edit_cooperating_workers"
                                :value="item.id" 
                                id="defaultRadio_1">
                        <label class="form-check-label" for="defaultRa"> {{ item.name }} </label>
                    </div>                 
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> تقييمات اخرى  </label>
                  <div class="col-sm-10">
                    <textarea v-model="edit_other_reviews" class="form-control" cols="3" rows="3"></textarea>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> التوصيات  </label>
                  <div class="col-sm-10">
                    <textarea v-model="edit_recommendations" class="form-control" cols="3" rows="3"></textarea>
                  </div>
              </div>



            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->

    {{ this.countNotifications }}

  </div> <!-- container -->   
</template>


<style scoped>

.form-check {
    padding-left: 0;
    padding-right: 1.95em;
    display: inline-block;
    padding: 21px 20px;
}</style>


<script>
import axios from 'axios'
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://furncn.com/back-end/api";
}
 export default {
  name: "foods-s",

  data() {
    return {
      // Validation
      formValdatorCreatePlanss : [],
      loopData: [],
      loopEmployee: [],
      loopScores:[],
      acount_type : localStorage.getItem('acount_type'),
      user_id : localStorage.getItem('user_id'),
      
      author: "",
      muazaf:"",
      employee_evaluation: "",
      absence:"",
      cooperating_workers:"",
      other_reviews:"",
      recommendations:"",
      employee_department_manager	:"",
      hr:"",
      general_director:"",


      
      // Edit Row
      edit_id: "",
      edit_muazaf:"",
      edit_employee_evaluation: "",
      edit_absence:"",
      edit_cooperating_workers:"",
      edit_other_reviews:"",
      edit_recommendations:"",
      edit_employee_department_manager	:"",
      edit_hr:"",
      edit_general_director:"",
      

      loop_notifications: [],
      countNotifications: null


    }
  },

  mounted () {
      window.document.title = "تقييم الموظفين";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
      
  }, // mounted

  methods : {

    getNotifications(){
      //setInterval(function() {
        axios.get(`${urlSite}/notifications`)
        .then(res => {
          this.loop_notifications = res.data.data
          this.countNotifications = res.data.count
        })
            
      //}, 1000);
    },

    getData(){
      axios.get(`${urlSite}/staff.evaluation`)
        .then(res => {
          this.loopEmployee = res.data.employee
          this.loopScores = res.data.scores
          this.loopData = res.data.data
        })
    },

    
    acceptRow(id, muazaf, column) {
      axios.post(`${urlSite}/update.apprpve.statement`, {
        id: id,
        table : 'staff_evaluation',
        column: column,
        muazaf: muazaf,
        action : event.target.value
      }).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });
    },

    store  () {
      axios.post(`${urlSite}/store.staff.evaluation`, {
        author        : localStorage.getItem("user_id"),
      	muazaf: this.muazaf,
        employee_evaluation: this.employee_evaluation,
        absence: this.absence,
        cooperating_workers: this.cooperating_workers,
        other_reviews: this.other_reviews,
        recommendations:  this.recommendations,
        employee_department_manager	: this.employee_department_manager,
        hr: this.hr,
        general_director: this.general_director
      })
      .then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });

    },


    // Get Data For Edit
    openEditModel (id, author, muazaf, employee_evaluation, absence, cooperating_workers, other_reviews, recommendations, employee_department_manager, hr, general_director) {
      this.edit_id                  = id
      this.author                   = author,
      this.edit_muazaf              = muazaf
      this.edit_employee_evaluation = employee_evaluation
      this.edit_absence             = absence
      this.edit_cooperating_workers = cooperating_workers
      this.edit_other_reviews       = other_reviews
      this.edit_recommendations     = recommendations
      this.edit_employee_department_manager = employee_department_manager
      this.edit_hr = hr
      this.edit_general_director    = general_director
    }, // openEditModel


    update () {
      axios.post(`${urlSite}/update.staff.evaluation`, {
        id                          : this.edit_id, 
        muazaf                      : this.edit_muazaf,
        employee_evaluation         : this.edit_employee_evaluation,
        absence                     : this.edit_absence,     
        cooperating_workers         : this.edit_cooperating_workers,
        other_reviews               : this.edit_other_reviews,
        recommendations             : this.edit_recommendations,
        employee_department_manager : this.edit_employee_department_manager,
        hr    : this.edit_hr,
        general_director            : this.edit_general_director
      }).then(res => {
        this.getData();
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 2000
          })
      });
    },



    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`${urlSite}/destroy`, {
          id   : id, 
          table: "staff_evaluation", 
        }).then(res => {
          this.getData();
        });
        Swal.fire(
          'حــــذف !',
          'تم حذف البيانات بنجاح',
          'success'
        )
      }
    })

    }, // onDelete







 }
}
</script>
   