
<template>
  <div class="container">

    <form class="card card-filter" @submit.prevent="" @submit="filters()">
      <div class="row justify-content-center filter">

        <div class="col-md-6">
          <div class="form-floating form-floating-outline mb-8">
              <input class="form-control" type="text" placeholder="Materio" v-model="filter.filter_search" id="html5-text-input">
              <label for="html5-text-input">اخبرنا عن ماذا تبحث</label>
          </div>
        </div>

        <div class="col-md-2">
          <button class="dt-button create-new btn btn-primary" 
                  style="padding: 13px 25px;"
                  type="submit">
                  <span class="d-none d-sm-inline-block"> بحــــــث </span>
          </button>
        </div>
        
      </div>
      <br>
    </form>
  <br>

    
    <div class="card">
      <div class="card-datatable table-responsive pt-0">
        <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
          <div class="card-header flex-column flex-md-row">

          <div class="dt-action-buttons row text-end pt-3 pt-md-0">
            <div class="head-label col-md-6">
              <h5 style="text-align: right;" class="card-title mb-0">استمارة خدمات</h5>
            </div>

          </div>
        </div>
        <table class="datatables-basic table table-bordered dataTable no-footer dtr-column table-hover">
          <thead class="table-light">
              <tr>
                <th>#</th>
                <th>رقم المستند</th>
                <th>المنصة</th>
                <th>الشركة</th>
                <th>مجال العمل</th>
                <th>الموقع الإلكتروني</th>
                <th>البريد الالكتروني</th>
                <th>الهاتف</th>
                <th>التوقيت</th>
                <th>تحكم</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :key="item.name">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.id   }}  </td>
                <td> {{ item.platform   }}  </td>
                <td>
                  <router-link :to="'services.form.details/'+item.id">
                    {{ item.company_name }}  
                  </router-link>
                </td>
                <td> {{ item.scope }}  </td>
                <td> {{ item.website }} </td>
                <td> {{ item.email }}  </td>
                <td> {{ item.mobile }} </td>
                <td> {{ item.time }} </td>
                <td>              

                  <a class="btn-action" @click="onDelete(item.id)"> 
                    <i class="far fa-trash-alt"></i> 
                  </a>
                  <!--
                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#EditModal"	
                      @click="openEditModel(item.id, item.muazaf_id, item.late_time, item.reason, item.action, item.note, item.muazaf, item.general_director)">
                      <i class="fas fa-pencil-alt"></i> 
                  </a>
                  -->
                </td>
              </tr>
            </tbody>
          </table>
          </div>
        </div>
    </div>



    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content"  enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">

              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">الموظف</label>
                  <div class="col-sm-9">
                    <select v-model="muazaf_id" class="form-control">
                      <option v-for="item in loopEmployee" :key="item" :value="item.id">{{ item.name }}</option>
                    </select>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">التاريخ</label>
                  <div class="col-sm-9">
                    <input type="day" v-model="late_time" class="form-control" placeholder="">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> سبب التأخیر </label>
                  <div class="col-sm-9">
                    <textarea v-model="reason" class="form-control" cols="2" rows="2" placeholder="سبب التأخیر"></textarea>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> الإجــــــراء </label>
                  <div class="col-sm-9">
                    <textarea v-model="action" class="form-control" cols="2" rows="2" placeholder="الإجراء المتخذ من قبل الإدارة العلیا"></textarea>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> ملاحظــــات </label>
                  <div class="col-sm-9">
                    <textarea v-model="note" class="form-control" cols="2" rows="2" placeholder="ملاحظــــات"></textarea>
                  </div>
              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Create Row -->



    <!-- Create Row -->
    <div class="modal fade" id="EditModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content" enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> تحديث البيانات </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">

              <input type="hidden"  v-model="edit_id" >

              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">الموظف</label>
                  <div class="col-sm-9">
                    <select v-model="edit_muazaf_id" class="form-control">
                      <option v-for="item in loopEmployee" :key="item" :value="item.id">{{ item.name }}</option>
                    </select>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">التاريخ</label>
                  <div class="col-sm-9">
                    <input type="day" v-model="edit_late_time" class="form-control" placeholder="">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> سبب التأخیر </label>
                  <div class="col-sm-9">
                    <textarea v-model="edit_reason" class="form-control" cols="2" rows="2" placeholder="سبب التأخیر"></textarea>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> الإجــــــراء </label>
                  <div class="col-sm-9">
                    <textarea v-model="edit_action" class="form-control" cols="2" rows="2" placeholder="الإجراء المتخذ من قبل الإدارة العلیا"></textarea>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> ملاحظــــات </label>
                  <div class="col-sm-9">
                    <textarea v-model="edit_note" class="form-control" cols="2" rows="2" placeholder="ملاحظــــات"></textarea>
                  </div>
              </div>


            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Create Row -->

 

  </div> <!-- container -->   
</template>

<style>
.filter i {
    padding: 8px;
}


.card-filter {
    padding: 24px 24px 0px 24px;
}
</style>

<script>
import axios from 'axios'

 export default {
  name: "certificate",

  data() {
    return {
      //Get Data
      loopData                 : [],
      // Add Data

      muazaf_id	          : "",
      late_time	          : "",
      time	              : "",
      reason	            : "",
      action	            : "",
      muazaf              : "",
      general_director    : "",
      note                : "",

      // Add Data
      edit_id                 : "",
      edit_muazaf_id	        : "",
      edit_late_time	        : "",
      edit_time	              : "",
      edit_reason	            : "",
      edit_action	            : "",
      edit_muazaf             : "",
      edit_general_director   : "",
      edit_note               : "",

      filter : {
        filter_muazaf         : "",
        filter_start          : "",
        filter_end            : "",
        filter_search         : "",
      }, 


      // localStorage
      acount_type   : localStorage.getItem('acount_type'),
      user_id       : localStorage.getItem('user_id'),

    }
  },

  mounted () {
      window.document.title = "استمارة خدمات";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
  }, // mounted

  methods : {

    getData(){
      axios.get(`services.form`).then(res => { 
        this.loopData = res.data.data
        this.loopEmployee = res.data.employee
       })
    },


    filters () {
      axios.get(`services.form?search=` + this.filter.filter_search
      ) .then(res => { this.loopData = res.data.data })
    }, // End filter

  
    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post('destroy', {
          id   : id, 
          table: "services_form", 
        }).then(res => {
          this.getData();
        });
        Swal.fire(
          'حــــذف !',
          'تم حذف البيانات بنجاح',
          'success'
        )
      }
    })

    }, // onDelete







 }
}
</script>
   