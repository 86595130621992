import { createRouter, createWebHistory, createWebHashHistory,  RouteRecordRaw } from "vue-router";
/*
import Index    from "../views/Website/Index.vue";
import About    from "../views/Website/About.vue";
import Contact  from "../views/Website/Contact.vue";
import ServicesFormPage from "../views/Website/ServicesFormPage.vue";
*/







import HomeView from "../views/HomeView.vue";
import StaffEvaluation from "../views/StaffEvaluation.vue";

import Absence from "../views/Absence/Absence.vue";
import AbsenceFilter from "../views/Absence/Filter.vue";

import Covenant from "../views/Checklist/Checklist.vue";
import MaintenanceRequest from "../views/MaintenanceRequest.vue";
import Insurance from "../views/Insurance.vue";
import Overtime from "../views/Overtime.vue";
import Interview from "../views/Interview.vue";
import MoveToBranch from "../views/Tasks.vue";
import Training from "../views/Training.vue";
import ProcessMuazaf from "../views/ProcessMuazaf.vue";
import Vacation from "../views/Vacation.vue";
import Resignation from "../views/Resignation.vue";
import Dismissal from "../views/Dismissal.vue";
import Permissions from "../views/Permissions.vue";
import Incentive from "../views/Incentive.vue";
import Dashbord from "../views/Dashbord.vue";
import WorkAssignment from "../views/WorkAssignment.vue";

import TrainingForm from "../views/TrainingForm.vue";
import TrainingTime from "../views/TrainingTime.vue";


import details from "../views/details.vue";


// Upload Files
import Files from "../views/Files.vue";
import FileTime from "../views/FileTime.vue";


import Certificate           from "../views/Certificate/Certificate.vue";
import CertificateType       from "../views/Certificate/CertificateType.vue";
import PotentialCustomer     from "../views/PotentialCustomer.vue";
import ClintsCases           from "../views/ClintsCases.vue";
import EmployeeLate          from "../views/EmployeeLate.vue";
import AbsenceWithoutWarning from "../views/AbsenceWithoutWarning.vue";
import Punishment            from "../views/Punishment.vue";
import Tasks                 from "../views/Tasks.vue";
import ServicesForm          from "../views/ServicesForm/ServicesForm.vue";
import ServicesFormDetails   from "../views/ServicesForm/Details.vue";

import Checklist        from "../views/Checklist/Checklist.vue";
import Questions        from "../views/Checklist/Questions.vue";
import Answers from "../views/Checklist/Answers.vue";

import AnswersQuestions from "../views/Checklist/AnswersQuestions.vue";

//Contact
import Chat from "../views/Contact/Chat.vue";
import Message from "../views/Contact/Message.vue";

//import Dashbord from "../views/Dashbord.vue";


// Member
import SignUp from "../views/Member/SignUp.vue";
import Login from "../views/Member/Login.vue";
import ForgotPassword from "../views/Member/ForgotPassword.vue";
import Profile from "../views/Member/Profile.vue";
import Notifications from "../views/Member/Notifications.vue";
import Member from "../views/Member/Member.vue";
import Companies from "../views/Member/Companies.vue";

// Orders
import DeliveryCases from "../views/Orders/DeliveryCases.vue";


import pdf from "../views/PDF.vue";

const routes: Array<RouteRecordRaw> = [

/*
  {path: "/", name: "Index", component: Index },
  {path: "/about", name: "About", component: About },
  {path: "/contact", name: "Contact", component: Contact },
  {path: "/services.form.page", name: "home", component: ServicesFormPage },
*/

  {path: "/", name: "home", component: HomeView },
  {path: "/process.muazaf/:id", name: "ProcessMuazaf", component: ProcessMuazaf },
  
  { path: "/absence", name: "absence", component: Absence}, 
  { path: "/absence.filter", name: "AbsenceFilter", component: AbsenceFilter}, 
  
  { path: "/staff.evaluation", name: "staff_evaluation", component: StaffEvaluation, }, 
  { path: "/covenant", name: "covenant", component: Covenant, }, 
  { path: "/maintenance.request", name: "MaintenanceRequest", component: MaintenanceRequest}, 
  { path: "/insurance", name: "Insurance", component: Insurance}, 
  { path: "/overtime", name: "overtime", component: Overtime}, 
  { path: "/interview", name: "Interview", component: Interview}, 
  { path: "/move.to.branch", name: "MoveToBranch", component: MoveToBranch}, 
  { path: "/training", name: "Training", component: Training}, 
  { path: "/punishment", name: "punishment", component: Punishment}, 
  { path: "/vacation", name: "vacation", component: Vacation}, 
  { path: "/resignation", name: "resignation", component: Resignation}, 
  { path: "/details", name: "details", component: details}, 
  { path: "/permissions", name: "Permissions", component: Permissions}, 
  { path: "/incentive", name: "Incentive", component: Incentive}, 
  { path: "/dismissal", name: "Dismissal", component: Dismissal}, 
  { path: "/dashbord", name: "dashbord", component: Dashbord}, 
  { path: "/work.assignment", name: "WorkAssignment", component: WorkAssignment}, 

  { path: "/training.form", name: "TrainingForm", component: TrainingForm}, 
  { path: "/training.time", name: "TrainingTime", component: TrainingTime}, 
  

  { path: "/certificate", name: "Certificate", component: Certificate}, 
  { path: "/certificate.type", name: "CertificateType", component: CertificateType}, 
  
  { path: "/potential.customers", name: "PotentialCustomer", component: PotentialCustomer}, 
  { path: "/clints.cases", name: "ClintsCases", component: ClintsCases}, 
  { path: "/employee.late", name: "EmployeeLate", component: EmployeeLate}, 
  { path: "/absence.without.warning", name: "AbsenceWithoutWarning", component: AbsenceWithoutWarning}, 
  { path: "/punishment", name: "Punishment", component: Punishment}, 
  { path: "/tasks", name: "Tasks", component: Tasks}, 
  { path: "/services.form", name: "ServicesForm", component: ServicesForm}, 
  { path: "/services.form.details/:id", name: "ServicesFormDetails", component: ServicesFormDetails}, 
  
  { path: "/checklist", name: "Checklist", component: Checklist}, 
  { path: "/answer.questions/:id", name: "Answers", component: Answers}, 
  { path: "/questions", name: "questions", component: Questions}, 
  



  
  { path: "/chat", name: "chat", component: Chat}, 
  { path: "/message", name: "Message", component: Message}, 

  // Member
  { path: "/signUp", name: "SignUp", component: SignUp}, 
  { path: "/login", name: "login", component: Login}, 
  { path: "/forgotPassword", name: "ForgotPassword", component: ForgotPassword}, 
  { path: "/profile", name: "Profile", component: Profile}, 
  { path: "/notifications", name: "Notifications", component: Notifications}, 
  { path: "/member", name: "Member", component: Member}, 
  
  { path: "/companies", name: "Companies", component: Companies}, 
  

  { path: "/files/:table/:row", name: "files", component: Files}, 
  { path: "/file.time", name: "FileTime", component: FileTime}, 


  { path: "/pdf/:id", name: "pdf", component: pdf}, 

];


const router = createRouter({
  history: createWebHashHistory(),
  //mode: 'history',
  routes,

});


export default router;



