<template>
 <div>
    <button class="btn btn-primary" @click="PrintDiv('myDiv')">طباعة التقرير</button>
    
    
    <div id="myDiv">
        <br><br><br><br><br><br><br><br><br><br><br>
<div style="display:inline-block;">
    <img    style="width: 134px;
                border-radius: 100%;
                height: 132px;
                display:inline-block;
                margin-top: -193px;"
            src="https://criteria.com.sa/public/asset/img/logo-2.PNG" 
            alt=""
    >
    <h2 style="margin-top: -141px; margin-right: 164px;">
        <strong style="font-size: 22px; display: block;"> مطابقــــــة المعاييـــــر لخدمـــــــات الاعمــــــــال</strong>
        <span style="display: block; font-size: 19px;font-weight: 400; margin-top: 12px;">شركة مطابقة المعايير معتمدة فى التفتيش على المنتجات ومطابقة بطاقات البيانات</span>
    </h2>
    
</div>


<div style="display:inline-block; width: 100%; padding: 5px;">
    <div style="display:inline-block; width: 64%; padding: 5px;">
        أسم المنشأه : {{ singleCompany.company_name }}
    </div>
    <div style="display:inline-block; width: 32%; padding: 5px;">
        كود العميل : {{ singleCompany.client_code }}
    </div>
    <div style="display:inline-block; width: 32%; padding: 5px;">
        تاريخ  التفتيش  : {{ singleCompany.date_report_issue }}
    </div>
    <div style="display:inline-block; width: 32%; padding: 5px;">
        اسم المفتش  : {{ singleCompany.auditor_name }}
    </div>
    <div style="display:inline-block; width: 32%; padding: 5px;">
        عنوان الموقع : {{ singleCompany.site_name }}
    </div>
    <div style="display:inline-block; width: 32%; padding: 5px;">
        مدير الموقع : {{ singleCompany.site_manager }}
    </div>
    <div style="display:inline-block; width: 32%; padding: 5px;">
        تقييم الزياره : {{ singleCompany.evaluation_visit }}
    </div>
    <div style="display:inline-block; width: 32%; padding: 5px;">
        نتيجة التدقيق النهائية : {{ singleCompany.audit_score }}
    </div>
    <div style="display:inline-block; width: 32%; padding: 5px;">
        تقييم العميل    : {{ singleCompany.customer_evaluation }}
    </div>
    <br><br>
</div>

        <ul>
            <li v-for="category in loopQuestionsCategory" :key="category" style="    padding: 5px; background: aliceblue; margin-bottom: 20px; padding: 5px; border: 1px solid #9055fd33; list-style: arabic-indic;">
                {{category.name}}
                <div v-for="answer in loopChecklistAnswer" :key="answer" >
                    <strong v-if="category.id == answer.category" style="">
                        <span v-if="answer.status_answer == 'yes'" style="list-style: arabic-indic; padding: 5px 0px; display: inline-block;">{{ answer.name }}</span>
                        <p v-if="answer.status_answer == 'yes' ">الاجــــــــراء : {{ answer.approve }}</p>
                    <p v-if="answer.status_answer == 'yes'">الاجابة : {{ answer.reason_refuse }}</p>
                    </strong>

                </div>
            </li>
        </ul>
    </div>
 </div>

</template>

<style lang="scss" scoped>

ul li {
    padding: 5px;
    background: aliceblue;
    margin-bottom: 20px;
    padding: 5px;
    border: 1px solid #9055fd33;
    list-style: arabic-indic;

}

ul li div span{
    list-style: arabic-indic;
}

.layout-menu {
    display: none;
}

@media (min-width: 1200px) {
[dir=rtl].layout-menu-fixed:not(.layout-menu-collapsed) .layout-page, [dir=rtl].layout-menu-fixed-offcanvas:not(.layout-menu-collapsed) .layout-page {
    padding-right: 0rem !important;
    padding-left: 0;
}
}
</style>

<script>
   import axios from 'axios'

   //importing bootstrap 5 and pdf maker Modules
   //import "bootstrap/dist/css/bootstrap.min.css";
   import pdfMake from 'pdfmake';
   import pdfFonts from 'pdfmake/build/vfs_fonts';
   import htmlToPdfmake from 'html-to-pdfmake';
   export default {
     
    data() {
        return {
        // Validation

        loopQuestions           : [],
        loopQuestionsCategory   : [],
        loopChecklistAnswer     : [],

        singleCompany : [],

        acount_type             : localStorage.getItem('acount_type'),
        user_id                 : localStorage.getItem('user_id'),

        // Edit Row
        id       : null,
        reason_refuse : "",
        file          : "",
        
        }
    },

    mounted () {
        window.document.title = "تقرير";
        this.getData();
    }, // mounted
          
     
       methods: {

    getData(){ 
      axios.get(`/answers.questions/${this.$route.params.id}`).then(res => {
        this.loopChecklistAnswer = res.data.checklist_answer
        this.loopQuestions = res.data.questions
        this.loopQuestionsCategory = res.data.QuestionsCategory
        this.singleCompany = res.data.singleCompany

      }) 
    },
    // 
     PrintDiv(id) {

        var data=document.getElementById(id).innerHTML;
            var myWindow = window.open('', 'my div', 'height=400,width=600');
            myWindow.document.write('<html dir="rtl"><head><title>my div</title>');
            /*optional stylesheet*/ //myWindow.document.write('<link rel="stylesheet" href="main.css" type="text/css" />');
            myWindow.document.write('</head><body >');
            myWindow.document.write(data);
            myWindow.document.write('</body></html>');
            myWindow.document.close(); // necessary for IE >= 10

            myWindow.onload=function(){ // necessary if the div contain images

                //myWindow.focus(); // necessary for IE >= 10
                myWindow.print();
                //myWindow.close();
            };
        }


   }  
   }
   </script>
