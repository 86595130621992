
<template>
  <!--
  https://www.pornhub.com/pornstar/violet-starr/videos
  https://www.pornhub.com/model/bananacoko
  https://www.pornhub.com/pornstars?page=4
  https://xn--mgbkt9eckr.net/threads/%D8%A3%D8%AE%D8%AA%D9%8A-%D8%A7%D9%84%D9%85%D8%B7%D9%84%D9%82%D8%A9-%D8%AA%D8%B3%D8%AA%D8%BA%D9%84-%D9%87%D9%8A%D8%AC%D8%A7%D9%86%D9%8A-%D8%A7%D9%84%D8%B3%D9%84%D8%B3%D9%84%D8%A9-%D8%A7%D9%84%D8%A3%D9%88%D9%84%D9%8A-%D9%80-%D8%B9%D8%B4%D8%B1%D8%A9-%D8%A3%D8%AC%D8%B2%D8%A7%D8%A1-10-6-2022.79139/
  https://www.tubev.pro/categories/1673/girls-kissing?hl=ar
-->
  <div class="container">
    <br><br>


    <div class="card">
      <div class="card-datatable table-responsive pt-0">
        <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
          <div class="card-header flex-column flex-md-row">

          <div class="dt-action-buttons row text-end pt-3 pt-md-0">
            <div class="head-label col-md-6">
              <h5 style="text-align: right;" class="card-title mb-0">قائمة التفتيش</h5>
            </div>
            <div class="dt-buttons  col-md-6"> 
              <button class="dt-button create-new btn btn-primary" 
                      tabindex="0" 
                      data-toggle="modal" 
                      data-target="#newModal" 
                      aria-controls="DataTables_Table_0" 
                      type="button">
                      <span>
                        <i class="mdi mdi-plus me-sm-1"></i> 
                        <span class="d-none d-sm-inline-block">اضافة</span>
                      </span>
              </button>
            </div>
          </div>
        </div>
        <table class="datatables-basic table table-bordered dataTable">
              <thead class="table-light">
                <tr>
                  <th>#</th>
                  <th>أسم المنشأه</th>
                  <th>كود العميل</th>
                  <th>تاريخ  التفتيش</th>
                  <th>اسم المفتش</th>
                  <!--
                  <th>كود الادوات المستخدمة</th>
                  -->
                  <th>عنوان الموقع</th>
                  <th>مدير الموقع</th>
                  <th>تقييم الزياره</th>
                  <th>تقييم العميل</th>
                  <th>تدقيق النهائية</th>
                  
                  <th style="width: 160px;">تحكم</th>
                </tr>
              </thead>
              <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopChecklist" :key="item.name">
                <td> {{ index + 1  }}  </td>
                <td> 
                  <router-link :to="'/answer.questions/' + item.id">
                    {{ item.company_name }}
                  </router-link>
                </td>
                <td> {{ item.client_code }}  </td>
                <td> {{ item.date_report_issue }}  </td>
                <td> {{ item.auditor_name }}  </td>
                <!--
                <td> {{ item.equipment_id }}  </td>
                -->
                <td> {{ item.site_name }}  </td>
                <td> {{ item.site_manager }}  </td>
                <td> {{ item.evaluation_visit }}  </td>
                <td> {{ item.customer_evaluation }}  </td>
                <td> {{ item.audit_score }}  </td>
                <td  style="width: 130px;">
                  <a class="btn-action" @click="onDelete(item.id)"> 
                    <i class="far fa-trash-alt"></i> 
                  </a>

                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#EditModal" 
                      @click="openEditModel(item.id, item.company_id, item.company_name, item.responsible_person, item.client_code, item.audit_date, item.date_report_issue, item.auditor_name, item.equipment_id, item.site_name, item.site_manager, item.evaluation_visit, item.customer_evaluation, item.audit_score)"> 
                      <i class="fas fa-pencil-alt"></i> 
                  </a>
                </td>
              </tr>
            </tbody>
            </table>
          </div>
        </div>
      </div>


    

    <!-- Upload File -->
    <div class="modal fade" id="uploadModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="uploadFile()" method="POST" class="modal-content"  enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">

              <div class="uploadFileBox">
                <label for="upload-file">
                  <i class="fal fa-cloud-upload"></i>
                  <strong>رفــــــــع ملـــف</strong>
                  <strong>PDF, ZIP, RAR, PNG, JPG, PNG, DOCX, XLSX, CSV</strong>
                  <input  type="file"  
                          class="form-control" 
                          id="upload-file" 
                          @change="uploadPicture" 
                          accept="pdf, zip, rar, png, jpg, gpeg, png, docx, xlsx, csv"
                  />
                </label>
              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Upload File -->



    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content"  enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">


              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">حساب الشركة</label>
                  <div class="col-sm-9">
                    <select v-model="company_id" class="form-control form-select">
                      <option v-for="item in loopCompayies" 
                              :key="item" 
                              :value="item.id">
                              {{ item.name }}
                            </option>
                    </select>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">أسم المنشأه</label>
                  <div class="col-sm-9">
                    <input type="text" v-model="company_name" class="form-control">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">كود العميل</label>
                  <div class="col-sm-9">
                    <input type="text" v-model="client_code" class="form-control">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">تاريخ  التفتيش</label>
                  <div class="col-sm-9">
                    <input type="date" v-model="date_report_issue" class="form-control">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">اسم المفتش</label>
                  <div class="col-sm-9">
                    <input type="text" v-model="auditor_name" class="form-control">
                  </div>
              </div>
              <!--
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">كود الادوات المستخدمة:</label>
                  <div class="col-sm-9">
                    <input type="text" v-model="equipment_id" class="form-control">
                  </div>
              </div>
              -->
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">عنوان الموقع</label>
                  <div class="col-sm-9">
                    <input type="text" v-model="site_name" class="form-control">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">مدير الموقع</label>
                  <div class="col-sm-9">
                    <input type="text" v-model="site_manager" class="form-control">
                  </div>
              </div>

              <!--
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">مدييييييييييير الموقع</label>
                  <div class="col-sm-6">
                    <input type="text" v-model="site_manager" class="form-control">
                  </div>
                  <div class="col-sm-6">
                    <input type="text" v-model="site_manager" class="form-control">
                  </div>
              </div>
-->
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">تقييم الزياره</label>
                  <div class="col-sm-9">
                    <input type="text" v-model="evaluation_visit" class="form-control">
                  </div>
              </div>




              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">تقييم العميل</label>
                  <div class="col-sm-9">
                    <select v-model="customer_evaluation" class="form-control">
                      <option value=""></option>
                      <option value="Passed">Passed</option>
                      <option value="Failed">Failed</option>
                    </select>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">تدقيق النهائية</label>
                  <div class="col-sm-9">
                    <select v-model="audit_score" class="form-control">
                      <option value=""></option>
                      <option value="راسب">راسب</option>
                      <option value="اعادة نظر">اعادة نظر</option>
                      <option value="جيد">جيد </option>
                      <option value="جيد جدا">جيد جدا</option>
                      <option value="ممتاز">ممتاز</option>
                    </select>
                  </div>
              </div>

            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Create Row -->



    <!-- Create Row -->
    <div class="modal fade" id="EditModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content" enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> تحديث البيانات </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">
              <input type="hidden"  v-model="edit_id" >

              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">حساب الشركة</label>
                  <div class="col-sm-9">
                    <select v-model="edit_company_id" class="form-control form-select">
                      <option v-for="item in loopCompayies" 
                              :key="item" 
                              :value="item.id">
                              {{ item.name }}
                            </option>
                    </select>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">أسم المنشأه</label>
                  <div class="col-sm-9">
                    <input type="text" v-model="edit_company_name" class="form-control">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">كود العميل</label>
                  <div class="col-sm-9">
                    <input type="text" v-model="edit_client_code" class="form-control">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">تاريخ  التفتيش</label>
                  <div class="col-sm-9">
                    <input type="date" v-model="edit_date_report_issue" class="form-control">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">اسم المفتش</label>
                  <div class="col-sm-9">
                    <input type="text" v-model="edit_auditor_name" class="form-control">
                  </div>
              </div>
              <!--
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">كود الادوات المستخدمة:</label>
                  <div class="col-sm-9">
                    <input type="text" v-model="edit_equipment_id" class="form-control">
                  </div>
              </div>
              -->
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">عنوان الموقع</label>
                  <div class="col-sm-9">
                    <input type="text" v-model="edit_site_name" class="form-control">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">مدير الموقع</label>
                  <div class="col-sm-9">
                    <input type="text" v-model="edit_site_manager" class="form-control">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">تقييم الزياره</label>
                  <div class="col-sm-9">
                    <input type="text" v-model="edit_evaluation_visit" class="form-control">
                  </div>
              </div>

              
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">تقييم العميل</label>
                  <div class="col-sm-9">
                    <select v-model="edit_customer_evaluation" class="form-control">
                      <option value=""></option>
                      <option value="Passed">Passed</option>
                      <option value="Failed">Failed</option>
                    </select>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">تدقيق النهائية</label>
                  <div class="col-sm-9">
                    <select v-model="edit_audit_score" class="form-control">
                      <option value=""></option>
                      <option value="راسب">راسب</option>
                      <option value="اعادة نظر">اعادة نظر</option>
                      <option value="جيد">جيد </option>
                      <option value="جيد جدا">جيد جدا</option>
                      <option value="ممتاز">ممتاز</option>
                    </select>
                  </div>
              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Create Row -->

  </div> <!-- container -->   
</template>


<style  scoped>
.card {
  padding: 25px;
}

.card thead {
  border-width: var(--bs-border-width) 0;
}

.red {
  background: red;
}
</style>



<script>

import jQuery from "jquery";

import axios from 'axios'
 export default {
  name: "foods-s",
  data() {
    return {
      // Validation
      loopChecklist   : [],
      loopCompayies   : [],
      loopQuestions   : [],

      loopFiles   : [],
      acount_type : localStorage.getItem('acount_type'),
      user_id     : localStorage.getItem("user_id"),

      // Add Row
      company_id    : "",
      company_name  : "",
      client_code   : "",
      audit_date    : "",
      date_report_issue : "",
      responsible_person: "",
      auditor_name  : "",
      equipment_id  : "",
      site_name     : "",
      site_manager  : "",
      evaluation_visit    : "",

      customer_evaluation  : "",
      audit_score  : "",

      // Edit Row
      edit_id            : "",
      edit_company_id    : "",
      edit_company_name  : "",
      edit_client_code   : "",
      edit_audit_date    : "",
      edit_date_report_issue : "",
      edit_responsible_person: "",
      edit_auditor_name  : "",
      edit_equipment_id  : "",
      edit_site_name     : "",
      edit_site_manager  : "",
      edit_evaluation_visit    : "",

      edit_customer_evaluation  : "",
      edit_audit_score  : "",    
    }
  },

  mounted () {
      window.document.title = "قائمة التفتيش";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
  }, // mounted

  methods : {

    
 myFunction() {
  document.getElementById("demo").style.color = "red";
  //$(this).css('color', 'black')
},

    acceptRow(id, muazaf, column) {
      axios.post(`update.apprpve.statement`, {
        id: id,
        table : 'checklist',
        muazaf: muazaf,
        column: column,
        action : event.target.value
      }).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });
    },

    getData(){
      axios.get(`checklist`)
        .then(res => {
          this.loopChecklist  = res.data.checklist
          this.loopCompayies  = res.data.compayies
          this.loopQuestions  = res.data.questions
        })
    },

    uploadPicture(e){
      this.uploadFilePicture.picture = e.target.files[0];
      this.uploadFilePicture.imageUrl = URL.createObjectURL(e.target.files[0])
      this.uploadFilePicture.getPicture = e.target.files[0].name;
    },

    
    uploadFile  () {
      const formData = new FormData();
      formData.append("table",        "checklist");
      formData.append("row",          this.row_id);
      formData.append("author",       localStorage.getItem("user_id"));
      formData.append("acount_type",  localStorage.getItem("acount_type"));
      formData.append("file",         this.uploadFilePicture.picture); 

      axios.post(`upload.files`, formData).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });
    },

    store  () {
      axios.post(`/store.checklist`, {
        company_id    : this.company_id,
        company_name  : this.company_name,
        client_code   : this.client_code,
        audit_date    : this.audit_date,
        date_report_issue : this.date_report_issue,
        responsible_person: this.responsible_person,
        auditor_name  : this.auditor_name,
        equipment_id  : this.equipment_id,
        site_name     : this.site_name,
        site_manager  : this.site_manager,
        evaluation_visit  : this.evaluation_visit,    

        customer_evaluation  : this.customer_evaluation,        
        audit_score  : this.audit_score,        

      }).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });

    },




    // Get Data For Edit
    openEditModel (id, company_id, company_name, responsible_person, client_code, audit_date, date_report_issue, auditor_name, equipment_id, site_name, site_manager, evaluation_visit, customer_evaluation, audit_score) {
        this.edit_id            = id
        this.edit_company_id    = company_id
        this.edit_company_name  = company_name
        this.responsible_person = responsible_person
        this.edit_client_code   = client_code
        this.edit_audit_date    = audit_date
        this.edit_date_report_issue = date_report_issue
        this.edit_auditor_name  = auditor_name
        this.edit_equipment_id  = equipment_id
        this.edit_site_name     = site_name
        this.edit_site_manager  = site_manager
        this.edit_evaluation_visit   = evaluation_visit
        
        this.edit_customer_evaluation = customer_evaluation
        this.edit_audit_score = audit_score

      }, // openEditModel

    


    update () {
      axios.post(`/update.checklist`, {
        id            : this.edit_id,
        company_id    : this.edit_company_id,
        company_name  : this.edit_company_name,
        client_code   : this.edit_client_code,
        audit_date    : this.edit_audit_date,
        date_report_issue : this.edit_date_report_issue,
        responsible_person: this.responsible_person,
        auditor_name  : this.edit_auditor_name,
        equipment_id  : this.edit_equipment_id,
        site_name     : this.edit_site_name,
        site_manager  : this.edit_site_manager,
        evaluation_visit  : this.edit_evaluation_visit,        
        evaluating_success  : this.edit_evaluating_success,

                    
        customer_evaluation  : this.edit_customer_evaluation,        
        audit_score          : this.edit_audit_score,   
      }).then(res => {
        this.getData();
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 2000
          })
      });
    },



    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`/destroy`, {
          id   : id, 
          table: "checklist", 
        }).then(res => {
          this.getData();
        });
        Swal.fire(
          'حــــذف !',
          'تم حذف البيانات بنجاح',
          'success'
        )
      }
    })

    }, // onDelete







 }
}
</script>
   