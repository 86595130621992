
<template>

  <div class="container">
    <br><br>
    <!-- Card Table -->
    <div class="card">
      <h5 class="card-header">التامين الطبـــــــــى</h5>
        <button type="button" 
                class="btn btn-primary btn-new btn-xs" 
                data-toggle="modal" 
                data-target="#newModal" 
                style="float: left;"
                >اضافة
        </button>
        <div class="table-responsive text-nowrap">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>رقم المستند</th>
                <th>الموظف</th>
                <th>التاريخ المباشر</th>
                <th>شركة التامين</th>
                <th>فئة التامين</th>
                <td>المدير العام</td>
                <th>تحكم</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :key="item">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.id  }}  </td>
                <td> 
                  <span v-for="employee in loopEmployee" :key="employee">
                    <span v-if="employee.id == item.muazaf">{{ employee.name }}</span>
                  </span>   
                </td>
                <td> {{ item.tarikh_almubashirih }}  </td>
                <td> {{ item.insurance_company }}  </td>
                <td> {{ item.insurance_category }}  </td>
                <td> 
                  <span v-if="item.general_director == 'تم الاعتماد'" class="badge bg-label-success">{{ item.general_director }} </span>
                  <span v-if="item.general_director == 'رفض'" class="badge bg-label-danger">{{ item.general_director }} </span> <br>
                  {{ item.general_director_reason }}
                </td>
                <td> {{ item.note }}  </td>
                <td>
                  <a class="btn-action" @click="onDelete(item.id)"> 
                    <i class="far fa-trash-alt"></i> 
                  </a>
                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#EditModal" 
                      @click="openEditModel(item.id, item.author, item.muazaf, item.tarikh_almubashirih, item.insurance_company, item.insurance_category, item.note, item.general_director, item.general_director_reason)"> 
                      <i class="fas fa-pencil-alt"></i> 
                  </a>
                </td>
              </tr>
            </tbody>

          </table>
        </div>
    </div>
    <!-- Card Table -->



    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content"  enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">


              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> الموظف  </label>
                  <div class="col-sm-9">
                    <select v-model="muazaf" class="form-control form-select">
                      <option v-for="item in loopEmployee" 
                              :key="item" 
                              :value="item.id">
                              {{ item.name }}
                            </option>
                    </select>
                  </div>
              </div>


              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> التاريخ المباشر </label>
                  <div class="col-sm-9">
                    <input type="date" v-model="tarikh_almubashirih" class="form-control">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">شركة التامين</label>
                  <div class="col-sm-9">
                    <input type="text" v-model="insurance_company" class="form-control">
                  </div>
              </div>

              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> فئة التامين  </label>
                  <div class="col-sm-9">
                    <input type="text" v-model="insurance_category" class="form-control">
                  </div>
              </div>



            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->



    <!-- Create Row -->
    <div class="modal fade" id="EditModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content" enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> تحديث البيانات </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">
              <input type="hidden"  v-model="editID" >

              <div v-if="author == user_id">
                <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> الموظف  </label>
                  <div class="col-sm-9">
                    <select v-model="edit_muazaf" class="form-control form-select">
                      <option v-for="item in loopEmployee" 
                              :key="item" 
                              :value="item.id">
                              {{ item.name }}
                            </option>
                    </select>
                  </div>
                </div>
                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name"> التاريخ المباشر </label>
                    <div class="col-sm-9">
                      <input type="date" v-model="edit_tarikh_almubashirih" class="form-control">
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">شركة التامين</label>
                    <div class="col-sm-9">
                      <input type="text" v-model="edit_insurance_company" class="form-control">
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name"> فئة التامين  </label>
                    <div class="col-sm-9">
                      <input type="text" v-model="edit_insurance_category" class="form-control">
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name"> ملاحظــــات </label>
                    <div class="col-sm-9">
                      <textarea v-model="edit_note" class="form-control" cols="3" rows="3"></textarea>
                    </div>
                </div>
              </div>
  
              <!--
              <div v-if="acount_type == 'hr'">
                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">اعتماد الموارد البشرية</label>
                    <div class="col-sm-9">
                        <select v-model="hr"  class="form-control form-select">
                            <option value=""></option>
                            <option value="تم الاعتماد">تم الاعتماد</option>
                            <option value="رفض">رفض</option>
                        </select>                  
                      </div>
                </div>
                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">وذلك للاسباب التاليه</label>
                    <div class="col-sm-9">
                      <textarea v-model="hr_reason" class="form-control" cols="3" rows="3"></textarea>
                    </div>
                </div>
              </div>
              -->

              <div v-if="acount_type == 'general_director'">
                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">اعتماد المدير العام</label>
                    <div class="col-sm-9">
                        <select v-model="general_director"  class="form-control form-select">
                            <option value=""></option>
                            <option value="تم الاعتماد">تم الاعتماد</option>
                            <option value="رفض">رفض</option>
                        </select>                  
                      </div>
                </div>
                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">وذلك للاسباب التاليه</label>
                    <div class="col-sm-9">
                      <textarea v-model="general_director_reason" class="form-control" cols="3" rows="3"></textarea>
                    </div>
                </div>
              </div>

            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->

  </div> <!-- container -->   
</template>


<style  scoped>
.form-check {
    display: inline-block;
    min-height: 1.434375rem;
    padding-left: 1.7em;
 
}

.modal-body input {
    padding: 5px;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 20px;
}
</style>


<script>
import axios from 'axios'
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://furncn.com/back-end/api";
}
 export default {
  name: "foods-s",

  data() {
    return {
      // Validation
      formValdatorCreatePlanss : [],
      loopData: [],
      loopEmployee: [],
      loopScores:[],
      acount_type : localStorage.getItem('acount_type'),
      user_id     : localStorage.getItem('user_id'),

      muazaf:"",
      tarikh_almubashirih: "",
      insurance_company:"",
      insurance_category:"",
      note:"",


      edit_muazaf:"",
      edit_tarikh_almubashirih: "",
      edit_insurance_company:"",
      edit_insurance_category:"",
      edit_note:"",

      edit_general_director : "",
      edit_general_director_reason : "",
      
      // Edit Row
      editID: "",
      author: "",

      
    }
  },

  mounted () {
      window.document.title = "التامين الطبـــــــــى";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
  }, // mounted

  methods : {


    acceptRow(id, muazaf, column) {
      axios.post(`${urlSite}/update.apprpve.statement`, {
        id: id,
        table : 'insurance',
        column: column,
        muazaf: muazaf,
        action : event.target.value
      }).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });
    },

    getData(){
      axios.get(`${urlSite}/insurance`)
        .then(res => {
          this.loopEmployee = res.data.employee
          this.loopData = res.data.data
        })
    
    },




    store  () {
      axios.post(`${urlSite}/store.insurance`, {
        author        : localStorage.getItem("user_id"),
        muazaf                : this.muazaf,
        tarikh_almubashirih   : this.tarikh_almubashirih,
        insurance_company     : this.insurance_company,
        insurance_category    : this.insurance_category,
        note                  : this.note,
      }).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });

    },


    

    // Get Data For Edit
    openEditModel (getID, author, muazaf, tarikh_almubashirih, insurance_company, insurance_category, note, general_director, general_director_reason) {
      this.editID = getID,
      this.author = author
      this.edit_muazaf = muazaf,
      this.edit_tarikh_almubashirih = tarikh_almubashirih,
      this.edit_insurance_company = insurance_company,
      this.edit_insurance_category = insurance_category,
      this.edit_note = note
      this.edit_general_director = general_director,
      this.edit_general_director_reason = general_director_reason
    }, // openEditModel


    update () {
      axios.post(`${urlSite}/update.insurance`, {
        id:this.editID, 
        muazaf                : this.edit_muazaf,
        tarikh_almubashirih   : this.edit_tarikh_almubashirih,
        insurance_company     : this.edit_insurance_company,
        insurance_category    : this.edit_insurance_category,
        note                  : this.edit_note,
        general_director      : this.general_director,
        general_director_reason : this.general_director_reason,
        acount_type : localStorage.getItem('acount_type'),

      }).then(res => {
        this.getData();
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 2000
          })
      });
    },



    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`${urlSite}/destroy`, {
          id   : id, 
          table: "insurance", 
        }).then(res => {
          this.getData();
        });
        Swal.fire(
          'حــــذف !',
          'تم حذف البيانات بنجاح',
          'success'
        )
      }
    })

    }, // onDelete







 }
}
</script>
   