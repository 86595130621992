
<template>
  <div class="container">


    <div class="col-12">
                  <div class="card mb-4">

                    <div class="user-profile-header d-flex flex-column flex-lg-row text-sm-start text-center mb-4">

                      <div class="flex-grow-1 mt-3 mt-lg-5">
                        <div class="d-flex align-items-md-end align-items-sm-start align-items-center justify-content-md-between justify-content-start mx-4 flex-md-row flex-column gap-4">
                          <div class="user-profile-info">
                            <h4>{{ getOrder.company_name }}</h4>
                            <ul class="list-inline mb-0 d-flex align-items-center flex-wrap justify-content-sm-start justify-content-center gap-2">
                              <li class="list-inline-item">
                                <i class="mdi mdi-account-outline mdi-24px"></i>
                                <span class="fw-medium">{{ getOrder.company_name }}</span>
                              </li>
                              <li class="list-inline-item">
                                <i class="mdi mdi-phone-outline mdi-24px"></i>
                                <span class="fw-medium">مجال العمل : {{ getOrder.scope }}</span>
                              </li>
                              <li class="list-inline-item">
                                <i class="mdi mdi-calendar-blank-outline me-1 mdi-20px"></i>
                                <span class="fw-medium"> عدد الافرع : {{ getOrder.branches }}</span>
                              </li>
                            </ul>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>    
    
    <div class="row">
      <div class="col-xl-4 col-lg-5 col-md-5">
        <!-- About User -->
          <div class="card mb-4">
            <div class="card-body">
              <span class="badge bg-label-secondary">بيانات الشركة</span>
              <ul class="list-unstyled my-3 py-1">
                <li class="d-flex align-items-center mb-3">
                    <i class="far fa-phone-plus"></i>
                    <span class="fw-medium mx-2">  الموقع الإلكتروني : </span>
                    <span>{{ getOrder.website }}</span>
                </li>
                <li class="d-flex align-items-center mb-3">
                    <i class="far fa-phone-plus"></i>
                    <span class="fw-medium mx-2"> عـــدد الادارات	:</span>
                    <span>{{ getOrder.departments }}</span>
                </li>
                <li class="d-flex align-items-center mb-3">
                    <i class="far fa-briefcase  mdi-24px"></i>
                    <span class="fw-medium mx-2"> عدد العاملين	:</span>
                    <span>{{ getOrder.employees }}</span>
                </li>
                <li class="d-flex align-items-center mb-3">
                    <i class="far fa-briefcase  mdi-24px"></i>
                    <span class="fw-medium mx-2"> جنسيات العاملين	:</span>
                    <span>{{ getOrder.nationalities }}</span>
                </li>
                <li class="d-flex align-items-center mb-3">
                    <i class="far fa-briefcase  mdi-24px"></i>
                    <span class="fw-medium mx-2"> HACCP 	:</span>
                    <span>{{ getOrder.haccp }}</span>
                </li>
                </ul>
            </div>
            </div>
            <!--/ About User -->
            <!-- Profile Overview -->
                  <div class="card mb-4">
                    <div class="card-body">
                      <span class="badge bg-label-primary">بيانـــات العميــــل</span>
                      <ul class="list-unstyled mb-0 mt-3 pt-1">

                        <li class="d-flex align-items-center mb-3">
                            <i class="far fa-landmark"></i>
                            <span class="fw-medium mx-2"> الاســـــم	:</span>
                            <span>{{ getOrder.name }}</span>
                        </li>
                        <li class="d-flex align-items-center mb-3">
                            <i class="far fa-tasks"></i>
                            <span class="fw-medium mx-2"> المنصـــــب :</span>
                            <span>{{ getOrder.position }}</span>
                        </li>
                        <li class="d-flex align-items-center mb-3">
                            <i class="far fa-money-check-edit-alt"></i>
                            <span class="fw-medium mx-2"> البريد الالكتروني:</span>
                            <span>{{ getOrder.price }}</span>
                        </li>
                        <li class="d-flex align-items-center mb-3">
                            <i class="far fa-user-circle"></i>
                            <span class="fw-medium mx-2"> رقم الجوال	:</span>
                            <span>{{ getOrder.email }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!--/ Profile Overview -->

                  <!-- Profile Overview -->
                  <div class="card mb-4">
                    <div class="card-body">
                      <span class="badge bg-label-primary">معلومــــات أخـــــــرى</span>
                      <ul class="list-unstyled mb-0 mt-3 pt-1">
                        <li class="d-flex align-items-center mb-3">
                            <i class="far fa-landmark"></i>
                            <span class="fw-medium mx-2"> الخبرة السابقة بالأيزو	:</span>
                            <span>{{ getOrder.iso }}</span>
                        </li>
                        <li class="d-flex align-items-center mb-3">
                            <i class="far fa-tasks"></i>
                            <span class="fw-medium mx-2"> اهتمام الشركة بالجودة :</span>
                            <span>{{ getOrder.quality }}</span>
                        </li>
                        <li class="d-flex align-items-center mb-3">
                            <i class="far fa-money-check-edit-alt"></i>
                            <span class="fw-medium mx-2"> اهتمام الإدارة العليا بالتطوير :</span>
                            <span>{{ getOrder.development }}</span>
                        </li>
                        <li class="d-flex align-items-center mb-3">
                            <i class="far fa-user-circle"></i>
                            <span class="fw-medium mx-2"> عمل المقابلة مع صاحب القرار	:</span>
                            <span>{{ getOrder.decision_maker }}</span>
                        </li>
                        <li class="d-flex align-items-center mb-3">
                            <i class="far fa-user-circle"></i>
                            <span class="fw-medium mx-2"> مستوى الشركة :</span>
                            <span>{{ getOrder.company_level }}</span>
                        </li>
                        <li class="d-flex align-items-center mb-3">
                            <i class="far fa-user-circle"></i>
                            <span class="fw-medium mx-2"> مستوى المشاريع :</span>
                            <span>{{ getOrder.project_level }}</span>
                        </li>
                        <li class="d-flex align-items-center mb-3">
                            <i class="far fa-user-circle"></i>
                            <span class="fw-medium mx-2"> مدى خبرة الموظفين :</span>
                            <span>{{ getOrder.staff_experience }}</span>
                        </li>
                        <li class="d-flex align-items-center mb-3">
                            <i class="far fa-user-circle"></i>
                            <span class="fw-medium mx-2"> ملاحظات المندوب :</span>
                            <span>{{ getOrder.delegate_notes }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!--/ Profile Overview -->


                  <!-- Profile Overview -->
                  <div class="card mb-4" v-if="getOrder.platform == 'شركة المعرفة'">
                    <div class="card-body">
                      <span class="badge bg-label-primary">الخدمات المطلوبة</span>
                      <ul class="list-unstyled mb-0 mt-3 pt-1">
                        <li class="d-flex align-items-center mb-3" v-if="getOrder.required_services_1 != null">
                            <span>{{ getOrder.required_services_1 }}</span>
                        </li>
                        <li class="d-flex align-items-center mb-3" v-if="getOrder.required_services_2 != null">
                            <span>{{ getOrder.required_services_2 }}</span>
                        </li>
                        <li class="d-flex align-items-center mb-3" v-if="getOrder.required_services_3 != null">
                            <span>{{ getOrder.required_services_3 }}</span>
                        </li>
                        <li class="d-flex align-items-center mb-3" v-if="getOrder.required_services_4 != null">
                            <span>{{ getOrder.required_services_4 }}</span>
                        </li>
                        <li class="d-flex align-items-center mb-3" v-if="getOrder.required_services_5 != null">
                            <span>{{ getOrder.required_services_5 }}</span>
                        </li>
                        <li class="d-flex align-items-center mb-3" v-if="getOrder.required_services_6 != null">
                            <span>{{ getOrder.required_services_6 }}</span>
                        </li>
                        <li class="d-flex align-items-center mb-3" v-if="getOrder.required_services_7 != null">
                            <span>{{ getOrder.required_services_7 }}</span>
                        </li>
                        <li class="d-flex align-items-center mb-3" v-if="getOrder.required_services_8 != null">
                            <span>{{ getOrder.required_services_8 }}</span>
                        </li>
                        <li class="d-flex align-items-center mb-3" v-if="getOrder.required_services_9 != null">
                            <span>{{ getOrder.required_services_9 }}</span>
                        </li>
                        <li class="d-flex align-items-center mb-3" v-if="getOrder.required_services_10 != null">
                            <span>{{ getOrder.required_services_10 }}</span>
                        </li>
                        <li class="d-flex align-items-center mb-3">
                            <i class="far fa-user-circle"></i>
                            <span class="fw-medium mx-2"> أخرى   :</span>
                            <span>{{ getOrder.other }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!--/ Profile Overview -->
      


                  <!-- Profile Overview -->
                  <div class="card mb-4" v-if="getOrder.platform == 'مطابقة المعايير'">
                    <div class="card-body">
                      <span class="badge bg-label-primary">الخدمات المطلوبة</span>
                      <ul class="list-unstyled mb-0 mt-3 pt-1">

                        <li class="d-flex align-items-center mb-3">
                            <i class="far fa-tasks"></i>
                            <span class="fw-medium mx-2">A - أ‌. التفتيش على سلامة الغذاء Food Safety Inspection للمصانع / للفنادق / للمطاعم............</span>
                            <span>عدد المنتجات  {{ getOrder.a_yes }} </span> - <span> عدد الزيارات  {{ getOrder.a_visit }} </span> - <span>  {{ getOrder.a_certificate }} </span> 
                        </li>

                        <li class="d-flex align-items-center mb-3">
                            <i class="far fa-tasks"></i>
                            <span class="fw-medium mx-2">B- التفتيش على سلامة وسائل نقل الأغذية</span>
                            <span>عدد المنتجات  {{ getOrder.b_yes }} </span> - <span> عدد السيارات  {{ getOrder.a_car }} </span> - <span>  {{ getOrder.b_certificate }} </span> 
                        </li>

                        <li class="d-flex align-items-center mb-3">
                            <i class="far fa-tasks"></i>
                            <span class="fw-medium mx-2">1- مطابقة بطاقة بيانات المواد الغذائية :</span>
                            <span>عدد المنتجات  {{ getOrder.count_1 }} </span> - <span>أنواع المنتجات {{ getOrder.type_1 }} </span>
                        </li>

                        <li class="d-flex align-items-center mb-3">
                            <i class="far fa-tasks"></i>
                            <span class="fw-medium mx-2">2- مطابقة بطاقة بيانات الاغذية المحفوظة ( سابقة الاعداد ):</span>
                            <span>عدد المنتجات  {{ getOrder.count_2 }} </span> - <span>أنواع المنتجات {{ getOrder.type_2 }} </span>
                        </li>
                        <li class="d-flex align-items-center mb-3">
                            <i class="far fa-tasks"></i>
                            <span class="fw-medium mx-2">3- مطابقة بطاقة بيانات الزيوت والدهون:</span>
                            <span>عدد المنتجات  {{ getOrder.count_3 }} </span> - <span>أنواع المنتجات {{ getOrder.type_3 }} </span>
                        </li>
                        <li class="d-flex align-items-center mb-3">
                            <i class="far fa-tasks"></i>
                            <span class="fw-medium mx-2">4- مطابقة بيانات منتجات الشوكولاتة والكاكاو:</span>
                            <span>عدد المنتجات  {{ getOrder.count_4 }} </span> - <span>أنواع المنتجات {{ getOrder.type_4 }} </span>
                        </li>
                        <li class="d-flex align-items-center mb-3">
                            <i class="far fa-tasks"></i>
                            <span class="fw-medium mx-2">5 - مطابقة بيانات الحلويات السكرية:</span>
                            <span>عدد المنتجات  {{ getOrder.count_5 }} </span> - <span>أنواع المنتجات {{ getOrder.type_5 }} </span>
                        </li>
                        <li class="d-flex align-items-center mb-3">
                            <i class="far fa-tasks"></i>
                            <span class="fw-medium mx-2">6- مطابقة بيانات الباستا والاطعمة الجاهزة من الحبوب:</span>
                            <span>عدد المنتجات  {{ getOrder.count_6 }} </span> - <span>أنواع المنتجات {{ getOrder.type_6 }} </span>
                        </li>
                        <li class="d-flex align-items-center mb-3">
                            <i class="far fa-tasks"></i>
                            <span class="fw-medium mx-2">7- مطابقة بيانات عصائر الفواكه:</span>
                            <span>عدد المنتجات  {{ getOrder.count_7 }} </span> - <span>أنواع المنتجات {{ getOrder.type_7 }} </span>
                        </li>
                        <li class="d-flex align-items-center mb-3">
                            <i class="far fa-tasks"></i>
                            <span class="fw-medium mx-2">8 –مطابقة بيانات المياه الطبيعية والغازية والمعدنية :</span>
                            <span>عدد المنتجات  {{ getOrder.count_8 }} </span> - <span>أنواع المنتجات {{ getOrder.type_8 }} </span>
                        </li>

                      </ul>
                    </div>
                  </div>
                  <!--/ Profile Overview -->
      
      
          </div>
      <div class="col-xl-4 col-lg-5 col-md-5">
        <!-- About User -->
          <div class="mb-4">
            <Tasks />
          </div>
          <!--/ About User -->
      </div>

      <div class="col-xl-4 col-lg-5 col-md-5">
        <Notes />
      </div>
      
    </div> <!-- row -->  
    
 

 










  </div> <!-- container -->   
</template>

<style>

.card-filter {
    padding: 24px 24px 0px 24px;
}

.FlexBox {
  display: flex;
}

.FlexBox img {
  border-radius: 100%;
}

a {
  cursor: pointer;
}

.far, .fab  {
    font-weight: 400;
    font-size: 19px;
    width: 28px;
}

.download-files .box {
    border: 1px solid #ccc;
    padding: 11px;
    border-radius: 8px;
    text-align: center;
    display: block;
    margin-bottom: 30px;
}

.download-files .box i {
    font-family: "Font Awesome 5 Pro";
    font-size: 21px;
    color: #000000b5;
    padding: 0px 15px;
}

.download-files .box h5 {
    margin-bottom: 0px;
}
</style>
<script>


import Tasks from './Tasks.vue'
import Notes from './Notes.vue'

import axios from 'axios'
export default {
  name: "Details",
  components: {
    Tasks,
    Notes,
  },
  data() {
    return {

      TaskMessageSuccess : false,
      updateTaskMessageSuccess : false,
      loopNotes : [],
      getOrder : '',


    }
  },

  mounted () {
    window.document.title = " الطلبات ";
    this.getData();
  }, // mounted







  methods : {

    async getData(){
      const response = await axios.get(`services.form.details/${this.$route.params.id}`)
        .then(res => {
          this.getOrder       = res.data.order;
          this.loopTasks      = res.data.tasks;
          this.loopNotes      = res.data.notes;

        })      
    },


    async updateStatus () {
      const formData = {
        id     : event.target.getAttribute('data-id'),
        status : event.target.value,
        column : "delivery_cases",
        table  : "order",
      }
      const response = await axios.post("https://milddiets.com/back-end/api/update.status", formData)
      if(response) {
        this.getData();
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'تم تحديث البيانات بنجاح',
          showConfirmButton: false,
          timer: 2000
        });
      }
    },






    storeTask  () {
      axios.post(`store.task`, {
        order        : this.$route.params.id,
        title        : this.title_task,
        description  : this.description_task,
        start        : this.start_task,
        end          : this.end_task,
      }).then(res => {
          this.TaskMessageSuccess = true,
          this.getData();
      }); 
    },

    updateTaskModel(id, title, description, start, end) {
      this.edit_task_id          = id
      this.edit_title_task       = title
      this.edit_description_task = description
      this.edit_start_task       = start
      this.edit_end_task         = end
    },

    updateTask  () {
      axios.post(`update.task`, {
        id           : this.edit_task_id,
        title        : this.edit_title_task,
        description  : this.edit_description_task,
        start        : this.edit_start_task,
        end          : this.edit_end_task,
      }).then(res => {
          this.updateTaskMessageSuccess = true,
          this.getData();
      }); 
    },






 




    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post("/destroy", {
          id   : id, 
          table: "order", 
        }).then(res => {
          this.getData();
        });
        Swal.fire(
          'حــــذف !',
          'تم حذف البيانات بنجاح',
          'success'
        )
      }
    })

    }, // onDelete





 }
}
</script>
   