
<template>
 
  <div class="container">
    <br><br><br>
    <div class="card">
      <div class="card-datatable table-responsive pt-0">
        <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
          <div class="card-header flex-column flex-md-row">
          <div class="dt-action-buttons row text-end pt-3 pt-md-0">
            <div class="head-label col-md-6">
              <h5 style="text-align: right;" class="card-title mb-0">الملفات المرفقة</h5>
            </div>
          </div>
        </div>

        <table class="datatables-basic table table-bordered dataTable" id="DataTables_Table_0" aria-describedby="DataTables_Table_0_info">
          <thead class="table-light">
            <tr>
              <th>#</th>
              <th>صاحب المف</th>
              <th>التاريخ</th>
              <th>نوع الملف</th>
              <th>تنزيل الملف</th>
            </tr>
          </thead>
          <tbody class="table-border-bottom-0">
            <tr v-for="(item, index) in loopFiles" :key="item">
              <td> {{ index + 1  }}  </td>
              <td> {{ item.username }}  </td>
              <td> {{ item.time }}  </td>
              <td> {{ item.extintion }}  </td>
              <td> 
                <a class="btn-action" :href="'https://furncn.com/back-end/public/files/'+item.file">
                    <i class="fas fa-arrow-alt-to-bottom"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
          </div>
        </div>
      </div>


    


  </div> <!-- container -->   
</template>




<script>
import axios from 'axios'
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://furncn.com/back-end/api";
}
 export default {
  name: "foods-s",
 
  data() {
    return {
      // Validation
      loopFiles   : [],
      acount_type : localStorage.getItem('acount_type'),
      author      : localStorage.getItem("user_id"),

      table_name  : this.$route.params.table,
      row_id      : this.$route.params.row,

      
    }
  },

  mounted () {
      window.document.title = "الملفات المرفقة";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
  }, // mounted

  methods : {


    getData(){
      axios.get(`${urlSite}/files/${this.table_name}/${this.row_id}`)
        .then(res => {
          this.loopFiles    = res.data
        })
    
    },






 }
}
</script>
   