
<template>
<!-- 
  K9)X^27Brxpn6%*@3CdM(_)Fu!#SG&5yf
https://themeforest.net/item/luique-one-page-resume-wordpress-theme/41227879
-->
<div class="container">

    <div class="accordion mt-3" id="accordionExample">
      <div class="accordion-item" v-for="category in loopQuestionsCategory" :key="category" >
        <h2 class="accordion-header" id="headingTwo" @click="itemCatehoryForm()">
          <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" :data-bs-target="'#accordion_'+category.id" aria-expanded="false" aria-controls="accordionTwo">
              {{ category.name }}
          </button>
        </h2>
        <div :id="'accordion_'+category.id" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div class="row">
              <div class="col-md-8">
                <ul >
                  <div v-for="answer in loopChecklistAnswer" :key="answer">
                    <li class="accordion-answer single-accordion-answer" v-if="category.id == answer.category" @click="getID(answer.id)">
                      <strong>{{ answer.name }}</strong>
                      <div class="item-icon" style="display: none;">
                        <i class="far fa-exclamation-circle"></i>
                        <i class="far fa-file-edit"></i>
                      </div>
                    </li>
                  </div>
                </ul>
              </div>
              <div class="col-md-4" v-if="statusForm == true">
                <form @submit.prevent=""  @submit="updateAnswer()" method="POST"  enctype="multipart/form-data" class="updateData getData card">
                  <input type="hidden" v-model="id" />
                  <div class="form-floating form-floating-outline mb-4">
                    <select class="form-control" v-model="approve">
                      <option></option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                    <label for="html5-date-input">الاجــــــــراء</label>
                  </div>
                  <div class="form-floating form-floating-outline mb-4">
                      <input type="file" @change="uploadFiles" accept=".png,.jpg,.jpeg.doc,.docx,.pdf,.txt,.xlsx,.rar,.zip" class="form-control" placeholder="" />
                      <label for="html5-date-input">الملف المرفق</label>
                  </div>
                  <div class="form-floating form-floating-outline mb-4">
                      <textarea class="form-control" placeholder="" cols="30" rows="10" v-model="reason_refuse"></textarea>
                      <label for="html5-date-input">ملاحظــــــــات</label>
                  </div>
                  <button type="submit" class="btn rounded-pill btn-primary waves-effect waves-light" style="width: 100%;">
                    <span class="tf-icons mdi mdi-checkbox-marked-circle-outline me-1"></span>
                    اضافة اجابة 
                  </button>
                </form>
                <div class="getData">
                  <div>حالة القبول : {{ this.approve }}</div>
                  <div> ملاحظــــــــات : {{ this.reason_refuse }}</div>
                  <div> الملف :  
                    <a class="btn-action" v-if="this.file == null || this.file == ''" target="_blank" :href="'https://erp.criteria.com.sa/back-end/public/checklist/'+this.file">
                    </a>
                    <a class="btn-action" v-else target="_blank" :href="'https://erp.criteria.com.sa/back-end/public/checklist/'+this.file">
                      <i class="fas fa-arrow-alt-to-bottom"></i>
                    </a>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


   


    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content"  enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">الســـؤال </label>
                  <div class="col-sm-9">
                    <select v-model="question" class="form-control">
                      <option value=""></option>
                      <option v-for="item in loopQuestions" :key="item" :value="item.id">{{ item.name }}</option>
                    </select>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">الاجابة</label>
                  <div class="col-sm-9">
                    <textarea type="text" v-model="answer" class="form-control" placeholder="برجاء الاجابة علي  السؤال" cols="3" rows="3"></textarea>
                  </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>
        </form>
      </div>
    </div>
        <!-- Create Row -->


    <!--
                                  <table class="datatables-basic table table-bordered dataTable no-footer dtr-column table-hover">
                              <thead class="table-light">
                                  <tr>
                                    <th>#</th>
                                    <th>الســـؤال</th>
                                    <th>الاجابة</th>
                                    <th>الموظف</th>
                                    <th>الحالــــة</th>
                                    <th>ملاحظات</th>
                                    <th>تحكم</th>
                                  </tr>
                                </thead>
                                <tbody class="table-border-bottom-0">
                                  <tr v-for="(item, index) in loopQuestionsCategory" :key="item.name">
                                    <td> {{ index + 1  }}  </td>
                                    <td> {{ item.name  }}  </td>
                                    <td> 
                                      <span v-for="que in loopQuestions" :key="que">
                                        <span v-if="que.id == item.question">{{ que.name }}</span>
                                      </span>   
                                    </td>
                                    <td> {{ item.answer  }}  </td>
                                    <td>{{ item.username }}</td>
                                    <td> 
                                      <span v-if="item.status == 'تاكيد'" class="badge rounded-pill badge-center  bg-label-success ">تم التاكيد</span>
                                      <span v-if="item.status == 'رفض'" class="badge rounded-pill badge-center  bg-label-danger ">مرفوض</span>
                                    </td>
                                    <td>{{ item.reason_refuse  }}</td>

                                    <td>
                                      <a class="btn-action" @click="onDelete(item.id)"> 
                                        <i class="far fa-trash-alt"></i> 
                                      </a>
                                      <a  class="btn-action" 
                                          data-toggle="modal" 
                                          data-target="#EditModal" 
                                          @click="openEditModel(item.id, item.question, item.answer)"> 
                                          <i class="fas fa-pencil-alt"></i> 
                                      </a>
                                      <a  class="btn-action" 
                                          data-toggle="modal" 
                                          data-target="#updateStatus" 
                                          @click="editUpdateStatus(item.id, item.status, item.reason_refuse, 'answer_questions')"> 
                                          <i class="far fa-shield-check"></i>
                                      </a>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
    -->

    
  </div> <!-- container -->   
</template>


<style>
#accordionExample ul li {
    padding: 14px 0px;
    list-style: arabic-indic !important;
    font-size: 14px;
    cursor: pointer;
    border-bottom: 1px solid #cccc;
}




ul li .item-icon {
  float: left;
}

ul li .item-icon i {
    font-size: 22px;
    padding: 0px 10px;
    display: inline;
    cursor: pointer;
}

.accordion-button {
    background: aliceblue;
}

.getData {
    padding: 20px;
}

.white {
       background-color: white;
       width: 200px;
       height: 200px;  

   }
   .blue {
       width: 200px;
       height: 200px;
       background-color: blue;

   }

   .yelClass {
  background-color: rgb(255, 255, 136);
}

</style>

<script>
import axios from 'axios'

 export default {
  name: "AnswersQuestions",



  data() {
    return {
      statusForm: false,

      // Validation

      loopQuestions           : [],
      loopQuestionsCategory   : [],
      loopChecklistAnswer     : [],


      acount_type             : localStorage.getItem('acount_type'),
      user_id                 : localStorage.getItem('user_id'),

      // Edit Row
      id       : null,
      reason_refuse : "",
      file          : "",

      clicked: false,
      bgColor: false,

      
    }
  },

  mounted () {
      window.document.title = "الاسئلــــــــة";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
  }, // mounted

  methods : {

    getData(){ 
      axios.get(`/answers.questions/${this.$route.params.id}`).then(res => {

        this.loopChecklistAnswer = res.data.checklist_answer

        this.loopQuestions = res.data.questions
        this.loopQuestionsCategory = res.data.QuestionsCategory

      }) 
    },

    uploadFiles(e){
      this.file = e.target.files[0];
      //this.uploadFilePicture.imageUrl = URL.createObjectURL(e.target.files[0])
      //this.uploadFilePicture.getPicture = e.target.files[0].name;
    },

    storeFile () {
      const formData = new FormData();
      formData.append("procedure"             , this.procedure);
      formData.append("file"                  , this.uploadFile.file);
      formData.append("note"                  , this.note);
      formData.append("checklist_id"          , this.$route.params.id);
      axios.post(`upload.file.checklist`, formData).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });
      
    },
    
    updateFile () {
      const formData = new FormData();
      formData.append("id"          , this.edit_file_id);
      formData.append("file"        , this.uploadFile.file);
      formData.append("note"        , this.edit_note);
      axios.post(`update.file.checklist`, formData).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });
    },

    store  () {
      axios.post(`/store.answers.questions`, {
        question      : this.question,
        answer        : this.answer,
        checklist_id  : this.$route.params.id,
      }).then(res => {
        this.name = " "
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اضافة الســـؤال بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });

    },

    
fun(e) {
  this.bgColor = true
},


    itemCatehoryForm() {
      this.statusForm = false
    },

    // Get Data For Edit
    getID (id) {
      axios.get(`get.answer.question/${id}`).then(res => {
        this.id = res.data.id;
        this.approve = res.data.approve;
        this.reason_refuse = res.data.reason_refuse;
        this.file = res.data.file;
      });
      this.statusForm = true
    }, // openEditModel





    updateAnswer () {
      const formData = new FormData();
      formData.append("id"          , this.id);
      formData.append("approve"        , this.approve);
      formData.append("reason_refuse"        , this.reason_refuse);
      formData.append("file"        , this.file);

      axios.post(`update.answer.question`, formData).then(res => {
        //this.statusForm = false;

        axios.get(`get.answer.question/${this.id}`).then(res => {
          this.id = res.data.id;
          this.approve = res.data.approve;
          this.reason_refuse = res.data.reason_refuse;
          this.file = res.data.file;
        });

        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 2000
        })
      });
    },






 }
}
</script>
   