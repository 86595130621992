
<template>
  <div class="container">




    <div class="card">
      <div class="card-datatable table-responsive pt-0">
        <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
          <div class="card-header flex-column flex-md-row">

          <div class="dt-action-buttons row text-end pt-3 pt-md-0">
            <div class="head-label col-md-6">
              <h5 style="text-align: right;" class="card-title mb-0">الشهــــــادات</h5>
            </div>
            <div class="head-label col-md-4">
              <div class="form-floating form-floating-outline mb-4">
                <input @keyup="onSearch()" class="form-control" type="text" placeholder="Materio" id="html5-text-input">
                <label for="html5-text-input">اخبرنا عن ماذا تبحث</label>
              </div>
            </div>
            <div class="dt-buttons col-md-2"> 
              <button class="dt-button create-new btn btn-primary" 
                      tabindex="0" 
                      data-toggle="modal" 
                      data-target="#newModal" 
                      aria-controls="DataTables_Table_0" 
                      type="button">
                      <span>
                        <i class="mdi mdi-plus me-sm-1"></i> 
                        <span class="d-none d-sm-inline-block">اضافة</span>
                      </span>
              </button>
            </div>
          </div>
        </div>
        <table class="datatables-basic table table-bordered dataTable no-footer dtr-column table-hover">
          <thead class="table-light">
              <tr>
                <th>#</th>
                <th>الاســــم</th>
                <th>نوع الشهادة</th>
                <th>نوع السيريال</th>
                <th>رقم الشهادة</th>
                <th>المنصـــة</th>
                <th>ملاحظــــات</th>
                <th>تحكم</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :key="item">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.trainer_name  }} <br> {{ item.company_name  }} </td>
                <td> {{ item.certificate_type  }}  </td>
                <td> {{ item.serial_type  }}  </td>
                <td> {{ item.certificate_number }}  </td>
                <td>
                  <span v-if="item.platform == 'knowhow'">شركة المعرفة</span>
                  <span v-if="item.platform == 'criteria'">مطابقـة المعاييـر</span>
                </td>
                <td> {{ item.note }}  </td>  
                <td>
                  
                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#detailsModal" 
                      @click="openEditModel(item.id, item.trainer_name, item.type, item.certificate_number, item.start_course, item.end_course, item.serial_type, item.exam_date, item.certificate_date, item.training_days, item.training_hours, item.platform, item.note, item.company_name, item.address, item.scope, item.accreditation, item.date_issue, item.valid_until)"> 
                      <i class="far fa-info-circle"></i>
                  </a>
                  
                  <a class="btn-action"
                     data-toggle="modal" 
                     :data-target="'#qrModal_'+item.id"> 
                     <i class="fal fa-qrcode"></i>
                  </a>

                  <a class="btn-action" @click="onDelete(item.id)"> 
                    <i class="far fa-trash-alt"></i> 
                  </a>

                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#EditModal" 
                      @click="openEditModel(item.id, item.trainer_name, item.type, item.certificate_number, item.start_course, item.end_course, item.serial_type, item.exam_date, item.certificate_date, item.training_days, item.training_hours, item.platform, item.note, item.company_name, item.address, item.scope, item.accreditation, item.date_issue, item.valid_until)"> 
                      <i class="fas fa-pencil-alt"></i> 
                  </a>
                </td>


    <!-- Create Row -->
    <div class="modal fade" :id="'qrModal_'+item.id" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content" enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel">الباركـــــود</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">
              <div class="row">
                <QRCodeVue3 v-if="item.platform == 'knowhow'" 
                            :render-as="svg"   
                            :value="'https://certificate.knowhow.com.sa/search.certificate/'+ item.certificate_number" 
                            :width="512" 
                            :height="512" 
                />
                <QRCodeVue3 v-if="item.platform == 'criteria'" 
                            :render-as="svg"  
                            :value="'https://criteria.com.sa/certificate.search?search='+item.certificate_number" 
                            :width="512" 
                            :height="512" 
                />
              </div><!-- Class Row -->
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
          </div>
        </form>
      </div>
    </div>
    <!-- Create Row -->

              </tr>
              <tr v-if="loopData.length == 0" >
                <td class="text-center">ناسف لا توجد نتائج</td>
              </tr>
            </tbody>
          </table>
          </div>
        </div>
    </div>



    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content"  enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">


              <div class="row">
                <div class="row mb-3 col-md-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">اسم الشركة</label>
                    <div class="col-sm-9">
                      <input type="text" v-model="company_name" class="form-control"  >
                    </div>
                </div>
                <div class="row mb-3 col-md-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">اسم المتدرب</label>
                    <div class="col-sm-9">
                      <input type="text" v-model="trainer_name" class="form-control"  >
                    </div>
                </div>
                <div class="row mb-3 col-md-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">نوع الشهادة</label>
                    <div class="col-sm-9">
                      <select class="form-control" v-model="type" >
                        <option value=""></option>
                        <option v-for="item in loopType" :value="item.id" :key="item">{{ item.name }}</option>
                      </select>
                    </div>
                </div>
                <div class="row mb-3 col-md-6">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">نوع السيريال</label>
                  <div class="col-sm-9">
                    <select class="form-control" v-model="serial_type" >
                      <option value="manually">يدويا</option>
                      <option value="automatic">تلقائي</option>
                    </select>
                  </div>
                </div>
                <div class="row mb-3 col-md-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">سيريال</label>
                    <div class="col-sm-9">
                      <input type="text" v-model="certificate_number" class="form-control">
                    </div>
                </div>
                <div class="row mb-3 col-md-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">العنوان</label>
                    <div class="col-sm-9">
                      <input type="text" v-model="address" class="form-control">
                    </div>
                </div>

                <div class="row mb-3 col-md-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">مجال العمل</label>
                    <div class="col-sm-9">
                      <input type="text" v-model="scope" class="form-control">
                    </div>
                </div> <!-- row mb-3 col-md-6 -->
                <div class="row mb-3 col-md-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">الاعتماد</label>
                    <div class="col-sm-9">
                      <input type="text" v-model="accreditation" class="form-control" >
                    </div>
                </div> <!-- row mb-3 col-md-6 -->
                <div class="row mb-3 col-md-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">تاريخ اصدار الشهادة</label>
                    <div class="col-sm-9">
                      <input type="date" v-model="date_issue" class="form-control" data-date-format="DD MMMM YYYY" >
                    </div>
                </div> <!-- row mb-3 col-md-6 -->
                <div class="row mb-3 col-md-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">عدد الايام</label>
                    <div class="col-sm-9">
                      <input type="text" v-model="training_days" class="form-control" >
                    </div>
                </div> <!-- row mb-3 col-md-6 -->

                <div class="row mb-3 col-md-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">عدد الساعات</label>
                    <div class="col-sm-9">
                      <input type="text" v-model="training_hours" class="form-control" >
                    </div>
                </div> <!-- row mb-3 col-md-6 -->
                <div class="row mb-3 col-md-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">بدء الدورة</label>
                    <div class="col-sm-9">
                      <input type="date" v-model="start_course" class="form-control" >
                    </div>
                </div> <!-- row mb-3 col-md-6 -->
                <div class="row mb-3 col-md-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">انتهاء الدورة</label>
                    <div class="col-sm-9">
                      <input type="date" v-model="end_course" class="form-control" >
                    </div>
                </div> <!-- row mb-3 col-md-6 -->
                <div class="row mb-3 col-md-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">تاريخ الامتحان</label>
                    <div class="col-sm-9">
                      <input type="date" v-model="exam_date" class="form-control" >
                    </div>
                </div> <!-- row mb-3 col-md-6 -->
                <div class="row mb-3 col-md-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">تاريخ الشهادة</label>
                    <div class="col-sm-9">
                      <input type="date" v-model="certificate_date" class="form-control" >
                    </div>
                </div> <!-- row mb-3 col-md-6 -->
                <div class="row mb-3 col-md-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">المنصة</label>
                    <div class="col-sm-9">
                      <select v-model="platform" class="form-control">
                        <option value=""></option>
                        <option value="knowhow">شركة المعرفة</option>
                        <option value="criteria">مطابقـة المعاييـر</option>
                      </select>
                    </div>
                </div> <!-- row mb-3 col-md-6 -->
                <div class="row mb-3 col-md-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">صالحة حتى</label>
                    <div class="col-sm-9">
                      <input type="date" v-model="valid_until" class="form-control">
                    </div>
                </div> <!-- row mb-3 col-md-6 -->
                <div class="row mb-3 col-md-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">رفع الشهادة</label>
                    <div class="col-sm-9">
                      <input type="file" @change="uploadPicture" accept="jpg, jpeg, png" class="form-control">
                    </div>
                </div> <!-- row mb-3 col-md-6 -->
              </div><!-- Class Row -->


            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Create Row -->



    <!-- Create Row -->
    <div class="modal fade" id="EditModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content" enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> تحديث البيانات </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">

              <input type="hidden"  v-model="edit_id" >

              <div class="row">
                <div class="row mb-3 col-md-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">اسم الشركة</label>
                    <div class="col-sm-9">
                      <input type="text" v-model="edit_company_name" class="form-control"  >
                    </div>
                </div>
                <div class="row mb-3 col-md-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">اسم المتدرب</label>
                    <div class="col-sm-9">
                      <input type="text" v-model="edit_trainer_name" class="form-control"  >
                    </div>
                </div>
                <div class="row mb-3 col-md-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">نوع الشهادة</label>
                    <div class="col-sm-9">
                      <select class="form-control" v-model="edit_type" >
                        <option value=""></option>
                        <option v-for="item in loopType" :value="item.id" :key="item">{{ item.name }}</option>
                      </select>
                    </div>
                </div>
                <div class="row mb-3 col-md-6">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">نوع السيريال</label>
                  <div class="col-sm-9">
                    <select class="form-control" v-model="edit_serial_type" >
                      <option value="manually">يدويا</option>
                      <option value="automatic">تلقائي</option>
                    </select>
                  </div>
                </div>
                <div class="row mb-3 col-md-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">سيريال</label>
                    <div class="col-sm-9">
                      <input type="text" v-model="edit_certificate_number" class="form-control">
                    </div>
                </div>
                <div class="row mb-3 col-md-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">العنوان</label>
                    <div class="col-sm-9">
                      <input type="text" v-model="edit_address" class="form-control">
                    </div>
                </div>

                <div class="row mb-3 col-md-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">مجال العمل</label>
                    <div class="col-sm-9">
                      <input type="text" v-model="edit_scope" class="form-control">
                    </div>
                </div> <!-- row mb-3 col-md-6 -->
                <div class="row mb-3 col-md-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">الاعتماد</label>
                    <div class="col-sm-9">
                      <input type="text" v-model="edit_accreditation" class="form-control" >
                    </div>
                </div> <!-- row mb-3 col-md-6 -->
                <div class="row mb-3 col-md-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">تاريخ اصدار الشهادة</label>
                    <div class="col-sm-9">
                      <input type="text" v-model="edit_date_issue" class="form-control">
                    </div>
                </div> <!-- row mb-3 col-md-6 -->
                <div class="row mb-3 col-md-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">عدد الايام</label>
                    <div class="col-sm-9">
                      <input type="text" v-model="edit_training_days" class="form-control" >
                    </div>
                </div> <!-- row mb-3 col-md-6 -->

                <div class="row mb-3 col-md-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">عدد الساعات</label>
                    <div class="col-sm-9">
                      <input type="text" v-model="edit_training_hours" class="form-control" >
                    </div>
                </div> <!-- row mb-3 col-md-6 -->
                <div class="row mb-3 col-md-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">بدء الدورة</label>
                    <div class="col-sm-9">
                      <input type="date" v-model="edit_start_course" class="form-control" >
                    </div>
                </div> <!-- row mb-3 col-md-6 -->
                <div class="row mb-3 col-md-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">انتهاء الدورة</label>
                    <div class="col-sm-9">
                      <input type="date" v-model="edit_end_course" class="form-control" >
                    </div>
                </div> <!-- row mb-3 col-md-6 -->
                <div class="row mb-3 col-md-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">تاريخ الامتحان</label>
                    <div class="col-sm-9">
                      <input type="date" v-model="edit_exam_date" class="form-control" >
                    </div>
                </div> <!-- row mb-3 col-md-6 -->
                <div class="row mb-3 col-md-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">تاريخ الشهادة</label>
                    <div class="col-sm-9">
                      <input type="date" v-model="edit_certificate_date" class="form-control" >
                    </div>
                </div> <!-- row mb-3 col-md-6 -->
                <div class="row mb-3 col-md-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">المنصة</label>
                    <div class="col-sm-9">
                      <select v-model="edit_platform" class="form-control">
                        <option value=""></option>
                        <option value="knowhow">شركة المعرفة</option>
                        <option value="criteria">مطابقـة المعاييـر</option>
                      </select>
                    </div>
                </div> <!-- row mb-3 col-md-6 -->
                <div class="row mb-3 col-md-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">صالحة حتى</label>
                    <div class="col-sm-9">
                      <input type="date" v-model="edit_valid_until" class="form-control">
                    </div>
                </div> <!-- row mb-3 col-md-6 -->
                <div class="row mb-3 col-md-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">رفع الشهادة</label>
                    <div class="col-sm-9">
                      <input type="file" @change="uploadPicture" accept="jpg, jpeg, png" class="form-control">
                    </div>
                </div> <!-- row mb-3 col-md-6 -->
              </div><!-- Class Row -->

              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> ملاحظــــات </label>
                  <div class="col-sm-9">
                    <textarea v-model="edit_note" class="form-control" cols="3" rows="3"></textarea>
                  </div>
              </div>


            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Create Row -->


    
    

    <!-- Create Row -->
    <div class="modal fade" id="detailsModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content" enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> تحديث البيانات </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">

              <input type="hidden"  v-model="edit_id" >

              <div class="row">
                <div class="mb-1 col-md-6">
                  <label class="col-form-label" for="basic-default-name">اسم الشركة</label>
                  <label class="col-form-label" for="basic-default-name">{{ edit_company_name }}</label>
                </div>
                <div class="mb-1 col-md-6">
                  <label class="col-form-label" for="basic-default-name">اسم المتدرب</label>
                  <label class="col-form-label" for="basic-default-name">{{ edit_trainer_name }}</label>
                </div>
                <div class="col-md-6">
                  <label class="col-form-label" for="basic-default-name">نوع الشهادة</label>
                  <label class="col-form-label" >{{ edit_type }}</label>
                </div>
                <div class="col-md-6">
                  <label class="col-form-label" for="basic-default-name">نوع السيريال</label>
                  <label class="col-form-label" for="basic-default-name">{{edit_serial_type}}</label>
                </div>
                <div class="col-md-6">
                  <label class="col-form-label" for="basic-default-name">سيريال</label>
                  <label class="col-form-label" for="basic-default-name">{{edit_certificate_number}}</label>
                </div>
                <div class="col-md-6">
                  <label class="col-form-label" for="basic-default-name">العنوان</label>
                  <label class="col-form-label" for="basic-default-name">{{edit_address}}</label>
                </div>
                <div class="col-md-6">
                  <label class="col-form-label" for="basic-default-name">مجال العمل</label>
                  <label class="col-form-label" for="basic-default-name">{{edit_scope}}</label>
                </div> <!-- row mb-3 col-md-6 -->
                <div class="col-md-6">
                  <label class="col-form-label" for="basic-default-name">الاعتماد</label>
                  <label class="col-form-label" for="basic-default-name">{{edit_accreditation}}</label>
                </div> <!-- row mb-3 col-md-6 -->
                <div class="col-md-6">
                  <label class="col-form-label" for="basic-default-name">تاريخ اصدار الشهادة</label>
                  <label class="col-form-label" for="basic-default-name">{{edit_date_issue}}</label>
                </div> <!-- row mb-3 col-md-6 -->
                <div class="col-md-6">
                  <label class="col-form-label" for="basic-default-name">عدد الايام</label>
                  <label class="col-form-label" for="basic-default-name">{{ edit_training_days }}</label>
                </div> <!-- row mb-3 col-md-6 -->
                <div class="col-md-6">
                  <label class="col-form-label" for="basic-default-name">عدد الساعات</label>
                  <label class="col-form-label" for="basic-default-name">{{edit_training_hours}}</label>
                </div> <!-- row mb-3 col-md-6 -->
                <div class="col-md-6">
                  <label class="col-form-label" for="basic-default-name">بدء الدورة</label>
                  <label class="col-form-label" for="basic-default-name">{{edit_start_course}}</label>
                </div> <!-- row mb-3 col-md-6 -->
                <div class="col-md-6">
                  <label class="col-form-label" for="basic-default-name">انتهاء الدورة</label>
                  <label class="col-form-label" for="basic-default-name">{{edit_end_course}}</label>
                </div> <!-- row mb-3 col-md-6 -->
                <div class="col-md-6">
                  <label class="col-form-label" for="basic-default-name">تاريخ الامتحان</label>
                  <label class="col-form-label" for="basic-default-name">{{edit_exam_date}}</label>
                </div> <!-- row mb-3 col-md-6 -->
                <div class="col-md-6">
                  <label class="col-form-label" for="basic-default-name">تاريخ الشهادة</label>
                  <label class="col-form-label" for="basic-default-name">{{edit_certificate_date}}</label>
                </div> <!-- row mb-3 col-md-6 -->
                <div class="col-md-6">
                  <label class="col-form-label" for="basic-default-name">المنصة</label>
                  <label class="col-form-label" for="basic-default-name">{{edit_platform}}</label>
                </div> <!-- row mb-3 col-md-6 -->
                <div class="col-md-6">
                  <label class="col-form-label" for="basic-default-name">صالحة حتى</label>
                  <label class="col-form-label" for="basic-default-name">{{ edit_valid_until }}</label>
                </div> <!-- row mb-3 col-md-6 -->
              </div><!-- Class Row -->


            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Create Row -->





  </div> <!-- container -->   
</template>

<style>
.col-form-label {
    font-size: 14px;
    padding: 7px 2px;
}
</style>

<script>
import axios from 'axios'
//import QrcodeVue from './QR.vue'
import QrcodeVue from 'qrcode.vue'
import QRCodeVue3 from "qrcode-vue3";

 export default {
  name: "certificate",
  components: {
    QrcodeVue,
    QRCodeVue3

  },
  data() {
    return {
      value: 'https://example.com',
          size: 100,
      //Get Data
      loopData                 : [],
      loopType                 : [],
      // Add Data
      name                     : "",
      type                     : "",
      certificate_number       : "",
      start_course             : "",
      end_course               : "",
      serial_type          : "",
      exam_date                : "",
      certificate_date         : "",
      training_days            : "",
      training_hours           : "",
      platform                 : "",

      company_name   : "",
      address        : "",
      scope          : "",
      accreditation  : "",
      date_issue     : "",
      valid_until    : "",

      // Add Data
      edit_id                  : "",
      edit_trainer_name                : "",
      edit_type                : "",
      edit_certificate_number  : "",
      edit_start_course        : "",
      edit_end_course          : "",
      edit_serial_type         : "",
      edit_exam_date           : "",
      edit_certificate_date    : "",
      edit_training_days       : "",
      edit_training_hours      : "",
      edit_platform            : "",

      edit_company_name   : "",
      edit_address        : "",
      edit_scope          : "",
      edit_accreditation  : "",
      edit_date_issue     : "",
      edit_valid_until    : "",

      // localStorage
      acount_type   : localStorage.getItem('acount_type'),
      user_id       : localStorage.getItem('user_id'),

      files : {
        picture: '',
        pdf: '',
      },

      search: ''


    }
  },

  mounted () {
      window.document.title = "الشهــــــادات";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
  }, // mounted

  methods : {

    onSearch (value) {
      axios.get(`certificate` + "?search=" + event.target.value)
      .then(res => { this.loopData = res.data.data })
    }, // End onSearch

    getData(){
      axios.get(`certificate`)
        .then(res => {
          this.loopData = res.data.data
          this.loopType = res.data.type
        })
    },

    uploadPicture(e){
      this.files.picture = e.target.files[0];

    },

    uploadPDF(e){
      this.files.picture = e.target.files[0];
    },
    

    store  () {
      const formData = new FormData();
      formData.append("trainer_name",      this.trainer_name);
      formData.append("type",      this.type); 
      formData.append("certificate_number", this.certificate_number); 
      formData.append("start_course",  this.start_course); 
      formData.append("end_course",    this.end_course); 
      formData.append("serial_type",   this.serial_type); 
      formData.append("exam_date",     this.exam_date); 
      formData.append("certificate_date", this.certificate_date); 
      formData.append("serial_type",   this.serial_type); 
      formData.append("note",          this.note); 
      formData.append("training_days", this.training_days); 
      formData.append("training_hours",this.training_hours); 
      formData.append("platform",      this.platform); 
      formData.append("picture",      this.files.picture); 

      formData.append("company_name",      this.company_name); 
      formData.append("address",      this.address); 
      formData.append("scope",      this.scope); 
      formData.append("accreditation",      this.accreditation); 
      formData.append("date_issue",      this.date_issue); 
      formData.append("valid_until",      this.valid_until); 

      axios.post(`store.certificate`, formData).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اضافة البيانات بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });
    },
      

    

    // Get Data For Edit
    openEditModel (id, trainer_name, type, certificate_number, start_course, end_course, serial_type, exam_date, certificate_date, training_days, training_hours, platform, note, company_name, address, scope, accreditation, date_issue, valid_until) {
      this.edit_id                = id
      this.edit_trainer_name        = trainer_name == null ? ' ' : trainer_name
      this.edit_type                = type == null ? ' ' : type
      this.edit_certificate_number  = certificate_number == null ? ' ' : certificate_number
      this.edit_start_course        = start_course == null ? ' ' : start_course
      this.edit_end_course          = end_course == null ? ' ' : end_course
      this.edit_serial_type         = serial_type == null ? ' ' : serial_type
      this.edit_exam_date           = exam_date == null ? ' ' : exam_date
      this.edit_certificate_date    = certificate_date == null ? ' ' : certificate_date
      this.edit_note                = note == null ? ' ' : note
      this.edit_training_days       = training_days == null ? ' ' : training_days
      this.edit_training_hours      = training_hours == null ? ' ' : training_hours
      this.edit_platform            = platform == null ? ' ' : platform

      this.edit_company_name  = company_name == null ? ' ' : company_name
      this.edit_address       = address == null ? ' ' : address
      this.edit_scope         = scope == null ? ' ' : scope
      this.edit_accreditation = accreditation == null ? ' ' : accreditation
      this.edit_date_issue    = date_issue == null ? ' ' : date_issue
      this.edit_valid_until   = valid_until == null ? ' ' : valid_until 

    }, // openEditModel
    
    update  () {
      const formData = new FormData();
      formData.append("id",      this.edit_id); 
      formData.append("trainer_name",      this.edit_trainer_name); 
      formData.append("type",      this.edit_type); 
      formData.append("certificate_number", this.edit_certificate_number); 
      formData.append("start_course",  this.edit_start_course); 
      formData.append("end_course",    this.edit_end_course); 
      formData.append("serial_type",   this.edit_serial_type); 
      formData.append("exam_date",     this.edit_exam_date); 
      formData.append("certificate_date", this.edit_certificate_date); 
      formData.append("serial_type",   this.edit_serial_type); 
      formData.append("note",          this.edit_note); 
      formData.append("training_days", this.edit_training_days); 
      formData.append("training_hours",this.edit_training_hours);
      
      formData.append("company_name",  this.edit_company_name); 
      formData.append("address",       this.edit_address); 
      formData.append("scope",         this.edit_scope); 
      formData.append("accreditation", this.edit_accreditation); 
      formData.append("date_issue",    this.edit_date_issue); 
      formData.append("valid_until",   this.edit_valid_until);  
      formData.append("platform",      this.edit_platform); 
      formData.append("picture",       this.files.picture); 
      axios.post(`update.certificate`, formData).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });
    },

    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post('destroy', {
          id   : id, 
          table: "certificate", 
        }).then(res => {
          this.getData();
        });
        Swal.fire(
          'حــــذف !',
          'تم حذف البيانات بنجاح',
          'success'
        )
      }
    })

    }, // onDelete







 }
}
</script>
   