
<template>
 
  <div class="container">
    <div class="card">
      <div class="card-datatable table-responsive pt-0">
        <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
          <div class="card-header flex-column flex-md-row">

          <div class="dt-action-buttons row text-end pt-3 pt-md-0">
            <div class="head-label col-md-6">
              <h5 style="text-align: right;" class="card-title mb-0">حسابات العملاء</h5>
            </div>
            <div class="head-label col-md-4">
              <div class="form-floating form-floating-outline mb-4">
                <input  class="form-control" @keyup="querySearch" type="text" id="html5-date-input" placeholder="">
                <label  for="html5-date-input">اخبرنا عن ماذا تبحث</label>
              </div>
            </div>
            <div class="dt-buttons  col-md-2"> 
              <button class="dt-button create-new btn btn-primary" 
                      tabindex="0" 
                      data-toggle="modal" 
                      data-target="#newModal" 
                      aria-controls="DataTables_Table_0" 
                      type="button">
                      <span>
                        <i class="mdi mdi-plus me-sm-1"></i> 
                        <span class="d-none d-sm-inline-block">اضافة</span>
                      </span>
              </button>
            </div>
          </div>
        </div>

        <table class="datatables-basic table table-bordered dataTable no-footer dtr-column" id="DataTables_Table_0" aria-describedby="DataTables_Table_0_info" style="width: 1396px;">
              <thead class="table-light">
                <tr>
                  <th>#</th>
                  <th>اسم الشركة</th>
                  <th>البريد الالكتروني</th>
                  <th>كلمة المرور</th>
                  <th>العنوان</th>
                  <th>الفروع</th>
                  <td>المجال</td>
                  <th class="action">تحكم</th>
                </tr>
              </thead>
              <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :key="item.name" :v-if="loopData.length > 0">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.name }} </td>
                <td> {{ item.email }}  </td>
                <td> {{ item.text_password }}  </td>
                <td> {{ item.address }}  </td>
                <td> {{ item.branch }}  </td>
                <td> {{ item.specialization }}  </td>
                <td>
                  <a class="btn-action" @click="onDelete(item.id)"> 
                    <i class="far fa-trash-alt"></i> 
                  </a>
                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#EditModal" 
                      @click="openEditModel(item.id,	item.name,	item.email,	item.text_password,	item.address,	item.branch, item.specialization)"> 
                      <i class="fas fa-pencil-alt"></i> 
                  </a>
                </td>
              </tr>
              <tr v-if="loopData.length == 0" >
                <td class="text-center">ناسف لا توجد نتائج</td>
              </tr>
            </tbody>
            </table>
          </div>
        </div>
    </div>


    




    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content"  enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">

              <div class="form-floating form-floating-outline mb-4">
                  <input type="text" class="form-control" v-model="name" placeholder="اسم الشركة" />
                  <label for="html5-date-input"> اسم الشركة </label>
              </div>
              <div class="form-floating form-floating-outline mb-4">
                  <input type="text" class="form-control" v-model="email" placeholder="يرجي ادال البريد الالكتروني" />
                  <label for="html5-date-input"> البريد الالكتروني </label>
              </div>
              <div class="form-floating form-floating-outline mb-4">
                  <input type="text" class="form-control" v-model="password" placeholder="يرجي ادخال كلمة مرور صعبة" />
                  <label for="html5-date-input"> كلمة المرور </label>
              </div>
              <div class="form-floating form-floating-outline mb-4">
                  <input type="text" class="form-control" v-model="address" placeholder="عنوان مقر الشركة" />
                  <label for="html5-date-input"> العنوان </label>
              </div>
              <div class="form-floating form-floating-outline mb-4">
                  <input type="text" class="form-control" v-model="branch" placeholder="عدد الفروع" />
                  <label for="html5-date-input"> لفروع </label>
              </div>
              <div class="form-floating form-floating-outline mb-4">
                  <input type="text" class="form-control" v-model="specialization" placeholder="مجال الشركة" />
                  <label for="html5-date-input"> المجال </label>
              </div>

            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->



    <!-- Edit Row -->
    <div class="modal fade" id="EditModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content" enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> تحديث البيانات </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">

              <input type="hidden"  v-model="edit_id">
              <div class="form-floating form-floating-outline mb-4">
                  <input type="text" class="form-control" v-model="edit_name" placeholder="اسم الشركة" />
                  <label for="html5-date-input"> اسم الشركة </label>
              </div>
              <div class="form-floating form-floating-outline mb-4">
                  <input type="text" class="form-control" v-model="edit_email" placeholder="يرجي ادال البريد الالكتروني" />
                  <label for="html5-date-input"> البريد الالكتروني </label>
              </div>
              <div class="form-floating form-floating-outline mb-4">
                  <input type="text" class="form-control" v-model="edit_password" placeholder="يرجي ادخال كلمة مرور صعبة" />
                  <label for="html5-date-input"> كلمة المرور </label>
              </div>
              <div class="form-floating form-floating-outline mb-4">
                  <input type="text" class="form-control" v-model="edit_address" placeholder="عنوان مقر الشركة" />
                  <label for="html5-date-input"> العنوان </label>
              </div>
              <div class="form-floating form-floating-outline mb-4">
                  <input type="text" class="form-control" v-model="edit_branch" placeholder="عدد الفروع" />
                  <label for="html5-date-input"> لفروع </label>
              </div>
              <div class="form-floating form-floating-outline mb-4">
                  <input type="text" class="form-control" v-model="edit_specialization" placeholder="مجال الشركة" />
                  <label for="html5-date-input"> المجال </label>
              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Edit Row -->

  </div> <!-- container -->   
</template>


<style  scoped>
.card {
  padding: 25px;
}

.card thead {
  border-width: var(--bs-border-width) 0;
}

.card .action {
  width: 115px;
}
</style>



<script>
import axios from 'axios'

export default {
name: "foods-s",

  data() {
    return {
      loopData: [],
      acount_type : localStorage.getItem('acount_type'),
      user_id     : localStorage.getItem('user_id'),

      name          : "",
      email         : "",
      password : "",
      address       : "",
      branch        : "",
      specialization: "",

      edit_id            : "",
      edit_name          : "",
      edit_email         : "",
      edit_password : "",
      edit_address       : "",
      edit_branch        : "",
      edit_specialization: "",
      
    }
  },

  mounted () {
      window.document.title = "حسابات الشركات";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();  
  }, // mounted

  methods : {


    querySearch (event) {
      axios.get(`companies?search=${event.target.value}`).then(res => { 
        this.loopData = res.data; 
      })
    }, // End querySearch


    getData(){
      axios.get(`companies`)
        .then(res => {
          this.loopData = res.data
        })
    
    },


    store  () {
      axios.post(`store.companies`, {
        name          : this.name,
        email         : this.email,
        password : this.password,
        address       : this.address,
        branch        : this.branch,
        specialization: this.specialization,
      }).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اضافة الحساب  بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });

    },


    


    // Get Data For Edit
    openEditModel (id,	name,	email,	password,	address,	branch,	specialization) {
      this.edit_id             = id
      this.edit_name           = name
      this.edit_email	         = email
      this.edit_password	 = password
      this.edit_address	       = address
      this.edit_branch	       = branch
      this.edit_specialization = specialization
    }, // openEditModel


    update () {
      axios.post(`update.companies`, {
        id	          : this.edit_id,
        name          : this.edit_name,
        email         : this.edit_email,
        password :      this.edit_password,
        address       : this.edit_address,
        branch        : this.edit_branch,
        specialization: this.edit_specialization,
      }).then(res => {
        this.getData();
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 2000
          })
      });
    },



    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`/destroy`, {
          id   : id, 
          table: "users", 
        }).then(res => {
          this.getData();
        });
        Swal.fire(
          'حــــذف !',
          'تم حذف البيانات بنجاح',
          'success'
        )
      }
    })

    }, // onDelete







 }
}
</script>
   