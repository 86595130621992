import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
//const router = useRouter()

import store from "./store";

import Vue from 'vue'

import axios from 'axios'

if(process.env.NODE_ENV == "development") {
    axios.defaults.baseURL = "http://localhost:8000/api/";
} else {
    axios.defaults.baseURL = "/back-end/api/";
}

axios.defaults.headers.common = {
    'Authorization': "Bearer " + localStorage.getItem("token"),
    "x-access-token": localStorage.getItem("token"),
};

import  'jquery';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const app = createApp(App)
const ss = app.config.globalProperties.$hostname = "criteria.com.sa/";
console.log(app.config.globalProperties.$hostname)

app.use(VueSweetalert2);

createApp(App).use(store).use(router).mount("#app");
