
<template>
 
  <div class="container">

    <div class="card">
      <div class="card-datatable table-responsive pt-0">
        <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
          <div class="card-header flex-column flex-md-row">

          <div class="dt-action-buttons row text-end pt-3 pt-md-0">
            <div class="head-label col-md-6">
              <h5 style="text-align: right;" class="card-title mb-0">مواعيد التدريب</h5>
            </div>
            <div class="dt-buttons  col-md-6"> 
              <button class="dt-button create-new btn btn-primary" 
                      tabindex="0" 
                      data-toggle="modal" 
                      data-target="#newModal" 
                      aria-controls="DataTables_Table_0" 
                      type="button">
                      <span>
                        <i class="mdi mdi-plus me-sm-1"></i> 
                        <span class="d-none d-sm-inline-block">اضافة</span>
                      </span>
              </button>
            </div>
          </div>
        </div>


          <table class="datatables-basic table table-bordered dataTable no-footer dtr-column" >
              <thead class="table-light">
                <tr>
                  <th>#</th>
                  <th>الدورة</th>
                  <th>البدء</th>
                  <th>الانتهاء</th>
                  <th>الحالة</th>
                  <th class="action">تحكم</th>
                </tr>
              </thead>
              <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :key="item.name">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.name }}  </td>
                <td> {{ item.start }}  </td>
                <td> {{ item.end }}  </td>
                <td> {{ item.status }}  </td>
                <td>
                  <a class="btn-action" @click="onDelete(item.id)" v-if="author == user_id"> 
                    <i class="far fa-trash-alt"></i> 
                  </a>
                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#EditModal" 
                      @click="openEditModel(item.id,item.name,item.start,item.end, item.status)"> 
                      <i class="fas fa-pencil-alt"></i> 
                  </a>
                </td>
              </tr>
            </tbody>
            </table>
          </div>
                </div>
              </div>


    	







    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content"  enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">
              <div class="form-floating form-floating-outline mb-4">
                  <input class="form-control" type="text" id="html5-date-input" v-model="name" placeholder="">
                  <label for="html5-date-input">الدورة</label>
              </div>
              <div class="form-floating form-floating-outline mb-4">
                  <input class="form-control" type="date" id="html5-date-input" v-model="start" placeholder="">
                  <label for="html5-date-input">البدء</label>
              </div>
              <div class="form-floating form-floating-outline mb-4">
                  <input class="form-control" type="date" id="html5-date-input" v-model="end" placeholder="">
                  <label for="html5-date-input">الانتهاء</label>
              </div>
              <div class="form-floating form-floating-outline mb-4">
                  <select  class="form-control" v-model="status">
                    <option value=""></option>
                    <option value="تشغيل">تشغيل</option>
                    <option value="انهاء">انهاء</option>
                  </select>
                  <label for="html5-date-input">الحالة</label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Create Row -->



    <!-- Edit Row -->
    <div class="modal fade" id="EditModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content" enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> تحديث البيانات </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">
              <div class="form-floating form-floating-outline mb-4">
                  <input class="form-control" type="text" id="html5-date-input" v-model="edit_name" placeholder="">
                  <label for="html5-date-input">الدورة</label>
              </div>
              <div class="form-floating form-floating-outline mb-4">
                  <input class="form-control" type="date" id="html5-date-input" v-model="edit_start" placeholder="">
                  <label for="html5-date-input">البدء</label>
              </div>
              <div class="form-floating form-floating-outline mb-4">
                  <input class="form-control" type="date" id="html5-date-input" v-model="edit_end" placeholder="">
                  <label for="html5-date-input">الانتهاء</label>
              </div>
              <div class="form-floating form-floating-outline mb-4">
                  <select  class="form-control" v-model="edit_status">
                    <option value=""></option>
                    <option value="تشغيل">تشغيل</option>
                    <option value="انهاء">انهاء</option>
                  </select>
                  <label for="html5-date-input">الحالة</label>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->

  </div> <!-- container -->   
</template>


<style  scoped>
.card {
  padding: 25px;
}

.card thead {
  border-width: var(--bs-border-width) 0;
}

.card .action {
  width: 115px;
}
</style>



<script>
import axios from 'axios'
 export default {
  name: "foods-s",

  data() {
    return {
      // Validation
      loopData: [],

      name : "",
      start : "",
      end : "",
      status : "",

      edit_name : "",
      edit_start : "",
      edit_end : "",
      edit_status : "",


      
    }
  },

  mounted () {
      window.document.title = "طلبات التدريب";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
  }, // mounted

  methods : {


    getData(){
      axios.get(`/training.time`)
        .then(res => {
          this.loopData = res.data
        })
    
    },

    store  () {
      axios.post(`store.training.time`, {
        name	        : this.name,
        start	        : this.start,
        end	          : this.end,
        status	      : this.status,
      }).then(res => {
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 2000
          })
          this.getData();

      });

    },


  

    // Get Data For Edit
    openEditModel (id, name, start, end, status) {
      this.edit_id        = id
      this.edit_name      = name
      this.edit_start     = start
      this.edit_end       = end
      this.edit_status    = status
      
    }, // openEditModel


    update () {
      axios.post(`update.training.time`, {
        id	          : this.edit_id,
        name	        : this.edit_name,
        start	        : this.edit_start,
        end	          : this.edit_end,
        status	      : this.edit_status,
      }).then(res => {
        this.getData();
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 2000
          })
      });
    },



    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`destroy`, {
          id   : id, 
          table: "training_time", 
        }).then(res => {
          this.getData();
        });
        Swal.fire(
          'حــــذف !',
          'تم حذف البيانات بنجاح',
          'success'
        )
      }
    })

    }, // onDelete







 }
}
</script>
   